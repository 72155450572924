import type { FormEvent } from 'react';
import Button from '../../../../../common/features/Button';
import Spinner from '../../../../../common/features/Spinner';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../../../common/models/ContactsModals';
import { addToast } from '../../../../../redux/reducers/toastSlice';
import { SelectedContact } from '../../../common/models/Contact';
import { useDeleteContactsMutation } from '../../../redux/api/contactsApi';
import { useContactsDispatch } from '../../../redux/hooks';
import { setPageSelected } from '../../../redux/reducers/contactsGridSettingsSlice';

interface DeleteContactModalProps {
    contacts: SelectedContact[] | null;
    toggleModal: (prop: keyof ContactsModals, next?: boolean) => void;
}

export default function DeleteContactModal({ contacts, toggleModal }: DeleteContactModalProps) {
    const dispatch = useContactsDispatch();

    const translate = useTranslation();

    const handleClose = () => {
        toggleModal('remove');
    };

    const [deleteContacts, { isLoading }] = useDeleteContactsMutation();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!contacts) return;

        try {
            const data = await deleteContacts(contacts.map((item) => item.ExternalContactID!)).unwrap();

            if (data === 'SuccessfullRemoved') {
                dispatch(addToast({ message: translate('ContactsDeleted_Label', contacts.length), type: 'success' }));
                dispatch(setPageSelected(1));
            }
        } catch (error) {
            console.error('Error while removing contact:', error);
        }

        toggleModal('remove', false);
    };

    const isMultiple = contacts !== null && contacts.length > 1;

    return (
        <ModalWrapper onEscape={handleClose}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <form onSubmit={handleSubmit} className='modal-content'>
                    <div className='modal-body'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {isMultiple ? translate('DeleteContacts_Label') : translate('DeleteContact_Label')}
                        </h3>
                        <p className='note mb-0 notification-message w-100'>
                            {isMultiple ? translate('DeleteContactsMessage_Label') : translate('DeleteContactMessage_Label')}
                        </p>
                    </div>
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleClose}>
                            {translate('Cancel_Label')}
                        </Button>
                        <Button type='submit' variant='danger' size='medium' className='d-flex align-items-center' disabled={isLoading}>
                            {isLoading && <Spinner size='small' color='inherit' className='mr-2' />}
                            <span>
                                {isMultiple ? translate('YesDeleteContacts_Label', contacts.length) : translate('YesDeleteContact_Label')}
                            </span>
                        </Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    );
}
