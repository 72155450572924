import { createSlice } from '@reduxjs/toolkit';
import { ClearancesDownload } from '../../common/models/ReduxSlices';
import { DownloadClearancesData } from '../actions/clearancesDownload';

const initialState: ClearancesDownload = {
    downloadData: null,
    isLoading: false,
    error: undefined
};

const clearancesDownloadSlice = createSlice({
    name: 'clearancesDownload',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // DOWNLOAD CLEARANCES
            .addCase(DownloadClearancesData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(DownloadClearancesData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.downloadData = action.payload;
            })
            .addCase(DownloadClearancesData.rejected, (state, action) => {
                state.isLoading = false;

                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export default clearancesDownloadSlice.reducer;
