import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { REACT_APP_ASSETS_URL_CSS } from '../../common/constants/constants-portal';
import Toaster from '../../common/features/Toaster';
import App from './App';
import store from './redux/store';

const Portal = () => {
    return (
        <Provider store={store}>
            <HelmetProvider>
                <Helmet>
                    <link rel='stylesheet' href={REACT_APP_ASSETS_URL_CSS} />
                </Helmet>
                <Toaster />
                <App />
            </HelmetProvider>
        </Provider>
    );
};

export default Portal;
