import type { VariationPlacement } from '@popperjs/core';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { TabNavigationIds } from '../../../../common/constants/constants-portal';
import ActionButtonElipsis from '../../../../common/features/Grid/ActionButtonElipsis';
import useTranslation from '../../../../common/hooks/useTranslation';
import { setInitialEllipsisState } from '../../../../redux/reducers/ellipsisSlice';
import { addViewImagesData, hideMenuData, ViewDocumentsRedux } from '../../../../redux/reducers/viewImagesSlice';
import { ExportHeaderAndDetail } from '../../../clearances/redux/actions/clearanceDocuments';
import { useLazyCustomsInfoIdQuery } from '../../../clearances/redux/api/clearancesApi';
import { useDocumentsDispatch, useDocumentsSelector } from '../../redux/hooks';
import { setUpFilters } from '../../utils/imaging-utils';
import { DocumentsActionButtonOptions, DocumentsActionButtonOptionsCa } from '../constants/constants-documents';

interface ActionButtonProps extends GridCellProps {
    disabled: boolean;
    placement?: VariationPlacement;
    country?: string;
    setModalView: (data: null | string) => void;
    setEllipsisCompareView: () => void;
}

const ActionButton = memo(function ActionButton({
    disabled,
    dataItem,
    className,
    style,
    placement,
    country,
    setModalView,
    setEllipsisCompareView,
    ...rest
}: ActionButtonProps) {
    const { ellipsisData } = useDocumentsSelector((state) => state.ellipsis);
    const { model } = useDocumentsSelector((state) => state.imaging);
    const entryTransaction = dataItem?._transactionNumber ?? dataItem?._entryNumber;
    const translate = useTranslation();
    const dispatch = useDocumentsDispatch();
    const { push } = useHistory();

    const handleAction = (optionId: (typeof DocumentsActionButtonOptions)[number]['id']) => {
        switch (optionId) {
            case 'VIEW_DETAILS':
                localStorage.setItem('activeTab', TabNavigationIds.Clearances[0]);
                if (ellipsisData.customsInfoID) push(`/Clearances/${ellipsisData.customsInfoID}?origin=documents`);
                break;
            case 'HEADER_LINE': {
                return dispatch(
                    ExportHeaderAndDetail({
                        shipments: [dataItem],
                        filters: setUpFilters(model.Filters, entryTransaction),
                        imageGroupings: model.ImageGroupings
                    })
                );
            }
            case 'DOCUMENTS': {
                if (!dataItem?._entryNumber && !dataItem._transactionNumber) return;

                setModalView(entryTransaction);
                break;
            }
            case 'VIEW_DOCUMENT': {
                onViewDocumentOption();
                break;
            }
            case 'COMPARE_DOCUMENT': {
                setEllipsisCompareView();
                return dispatch(hideMenuData(true));
            }

            default:
                break;
        }
    };

    const onViewDocumentOption = () => {
        const docsFromRedux = model.SearchResults.filter((item) => item[entryTransaction]);

        const documentsForView: ViewDocumentsRedux = {
            entry: entryTransaction,

            documents: [...docsFromRedux[0][entryTransaction]].map((item) => {
                return {
                    _metadataFormat: item._metadataFormat!,
                    _fileS3Path: item._fileS3Path!,
                    _imageDate: item._imageDate || '',
                    secondaryDescription: item?.USDOCUMENTTYPE ?? item?.LIDOCUMENTID,
                    version: item.LIVERSION || ''
                };
            })
        };

        dispatch(addViewImagesData({ viewImages: [documentsForView], openedLocation: 'documentsGrid' }));
    };

    const [getCustomsInfoID, { isFetching: customsInfoIdIsFetching }] = useLazyCustomsInfoIdQuery();

    const handleDisplayOfEllipsisModal = (entryNo: string) => {
        if (entryNo) {
            const document = model.SearchResults.find((item) => item[entryNo]);
            const clientNo = country === 'ca' ? document![entryNo][0]._clientNumberAndCaption : document![entryNo][0].USBILLTO;

            getCustomsInfoID({ DocumentEntryNumber: entryNo, ClientNumbers: [String(clientNo)], IsForEllipsis: true });
        } else {
            dispatch(setInitialEllipsisState());
        }
    };

    const getDocsCount = () => {
        const searchResult = model.SearchResults?.find((item) => Object.keys(item)[0] === entryTransaction);

        return searchResult ? Object.values(searchResult)[0].length : 0;
    };

    return (
        <ActionButtonElipsis
            options={(country === 'ca' ? DocumentsActionButtonOptionsCa : DocumentsActionButtonOptions).map((option) => ({
                ...option,
                label: translate(option.label)
            }))}
            dangerOption=''
            onAction={(option) => handleAction(option as (typeof DocumentsActionButtonOptions)[number]['id'])}
            disabled={disabled}
            dataItem={dataItem}
            className={className}
            style={style}
            placement={placement}
            handleActionButtonForDocumentsGrid={handleDisplayOfEllipsisModal}
            currentRow={entryTransaction}
            isLoading={customsInfoIdIsFetching}
            ellipsisData={ellipsisData}
            docsCount={getDocsCount()}
            {...rest}
        />
    );
});

export default ActionButton;
