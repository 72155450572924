import { faBoxOpen, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { memo, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PortalRoutes, shipmentDataSourceConstants } from '../../../../common/constants/constants-portal';
import Button from '../../../../common/features/Button';
import ClientChooser from '../../../../common/features/ClientChooser/ClientChooser';
import DownloadAllLink from '../../../../common/features/DownloadAllLink';
import ColumnSelectionDropdown from '../../../../common/features/Grid/ColumnSelectionDropdown';
import GridHeader from '../../../../common/features/GridHeader';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import { ShipmentDataSource } from '../../../../common/features/ShipmentDataSource';
import useClientDimensions from '../../../../common/hooks/useClientDimensions';
import useMediaQuery from '../../../../common/hooks/useMediaQuery';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ColumnSettings } from '../../../../common/models/GridPreferences';
import { useGetClientSelectionSettingsQuery } from '../../../../redux/api/clientSelectionApi';
import { useGetShipmentDataSourceQuery, useSaveShipmentDataSourceMutation } from '../../../clearances/redux/api/clearancesApi';
import hasFilterBarFixedWidth from '../../../documents/utils/hasFilterBarFixedWidth';
import CADSearchBar from '../../common/components/Search/CADSearchBar';
import { gridSettingsConstants } from '../../common/constants/complete-entry-data-constants';
import { DownloadCASSShipment } from '../../redux/actions/completeEntryData';
import { SaveUserCADGridSetting } from '../../redux/actions/completeEntryDataGridPreferences';
import { useCompleteEntryDataDispatch, useCompleteEntryDataSelector } from '../../redux/hooks';
import { updateColumnOrder, updateCoulumnActivated } from '../../redux/reducers/completeEntryDataGridPreferenceSlice';
import { resetShipmentGridSettings } from '../../redux/reducers/completeEntryDataGridSettingsSlice';
import {
    updateDateFilter,
    updateLocationTypeFilter,
    updateMOTFilter,
    updateSelectLocationFilter,
    updateShouldSubmitSearch
} from '../../redux/reducers/completeEntryDataSlice';
import {
    resetDateFilters,
    setAppliedDate,
    setAppliedType,
    setDate,
    setDateDropdownLabel,
    setEndDateCa,
    setStartDateCa,
    setType,
    updateIsDateOpen
} from '../../redux/reducers/dateFilterSlice';
import { resetApplylocationFilter, resetLocationFilters, updatedLocationIsFiltered } from '../../redux/reducers/locationFilterSlice';
import { resetMotFilters } from '../../redux/reducers/motFilterSlice';
import DateDocumentsFilter from '../Filters/date/DateDocumentsFilter';
import LocationFilter from '../Filters/location/LocationFilter';
import MOTFilter from '../Filters/mot/MOTFilter';
import CompleteEntryDataGrid from './CompleteEntryDataGrid';

const cadDownloadConfigParams = {
    PAGE_SIZE: 100000,
    START_ROW: 0
};

const CompleteEntryDataPage = memo(function CompleteEntryDataPage() {
    const { allColumns, visibleColumns, areColumnsLoading } = useCompleteEntryDataSelector(
        (state) => state.completeEntryDataGridPreferences
    );
    const { model } = useCompleteEntryDataSelector((state) => state.completeEntryData);
    const { date, type, dateDropdownLabel, endDateCa, startDateCa, appliedDate, appliedType } = useCompleteEntryDataSelector(
        (state) => state.dateFilter
    );
    const { selectedStringArray } = useCompleteEntryDataSelector((state) => state.motFilter);
    const { locationTypeSelected, selectedLocationLabels } = useCompleteEntryDataSelector((state) => state.locationFilter);
    const { initialClientSelection, clientSelection } = useCompleteEntryDataSelector((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const dispatch = useCompleteEntryDataDispatch();

    const { isLoading: clientSelectionSettingsIsLoading } = useGetClientSelectionSettingsQuery();

    const translate = useTranslation();
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const scrollableDivItems = scrollableDivRef.current && Array.from(scrollableDivRef.current['children']);
    const direction = {
        RIGHT: scrollableDivItems ? scrollableDivItems.length - 1 : 0,
        LEFT: 0
    };
    const [scrollRight, setScrollRight] = useState(true);
    const resize = useClientDimensions();
    const mounted = useRef(false);

    // Fix filter bar width once it grows to a certain length
    const [filterBarHasFixedWidth, setFilterBarHasFixedWidth] = useState<boolean>(); //, setFilterBarHasFixedWidth
    const [filtersHaveSelected, setFiltersHaveSelected] = useState(false);

    const history = useHistory();
    const mobileView = useMediaQuery(0, 515);

    const gridHeaderRef = useRef<HTMLDivElement>(null);

    const { data: shipmentDataSource, isFetching: isShipmentDataSourceLoading } = useGetShipmentDataSourceQuery();
    const [saveShipmentDataSource] = useSaveShipmentDataSourceMutation();

    const dataSourceTranslations = {
        RealTimeData: translate('RealTimeData_Label'),
        MonitorClearanceStatuses: translate('MonitorClearanceStatuses_Label'),
        UpdatedRealTime: translate('UpdatedRealTime_Label'),
        CompleteEntryData: translate('CompleteEntryData_Label'),
        ComprehensiveClearanceData: translate('ComprehensiveClearanceData_Label'),
        UpdatedPeriodically: translate('UpdatedPeriodically_Label')
    };

    // Detect if mounted and prevent making changes on an unmounted component
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (mounted.current && scrollableDivRef.current) {
            setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
            appliedDate.length || appliedType.length || selectedLocationLabels.length || selectedStringArray.length
                ? setFiltersHaveSelected(true)
                : setFiltersHaveSelected(false);
        }
    }, [appliedDate, appliedType, selectedStringArray, selectedLocationLabels, resize, filtersHaveSelected]);

    // Redirect to Clearances
    useEffect(() => {
        if (isShipmentDataSourceLoading || clientSelectionSettingsIsLoading) return;

        if (shipmentDataSource === shipmentDataSourceConstants.us) {
            history.push(PortalRoutes.clearances);
        }
    }, [shipmentDataSource, clientSelection?.ClientTableData, history, clientSelectionSettingsIsLoading, isShipmentDataSourceLoading, dispatch]);

    const handleDataSourceSelection = async (index: number) => {
        if (!country?.Code) return;

        if (index === 0) {
            await saveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.us, Country: 'ca' });

            history.push(PortalRoutes.clearances);
        } else if (index === 1) {
            await saveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.ca, Country: 'ca' });

            history.push(PortalRoutes.completeEntryData);
        }
    };

    const applyDropdownChanges = (payload: ColumnSettings[]) => {
        dispatch(updateColumnOrder(payload));
        dispatch(
            SaveUserCADGridSetting({
                GridColumns: payload
                    .map((item, i) => {
                        return { ...item, Order: i + 1 };
                    })
                    .filter((item: ColumnSettings) => {
                        if (item.Code !== 'selected' && item.Code !== 'Actions') {
                            return item;
                        }
                        return null;
                    }),
                area: gridSettingsConstants['ca']
            })
        );
    };

    const applyButtonFunction = (attribute: string, selectedOption: string, selectedValue: string, endDate: string, startDate: string) => {
        dispatch(
            updateDateFilter({
                attribute,
                selectedOption,
                selectedValue,
                endDate,
                startDate
            })
        );
    };

    const handleColumnActivated = (activated: boolean) => {
        dispatch(updateCoulumnActivated(activated));
    };
    const clearAllFilters = () => {
        setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
        dispatch(resetLocationFilters());
        dispatch(resetDateFilters());
        dispatch(
            updateDateFilter({
                attribute: 'Date',
                selectedOption: '',
                selectedValue: '',
                endDate: '',
                startDate: ''
            })
        );
        dispatch(resetMotFilters());
        dispatch(updateMOTFilter([]));
        dispatch(resetShipmentGridSettings());
        dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: [] }));
        dispatch(updateLocationTypeFilter(''));
        dispatch(updateIsDateOpen(false));
        dispatch(updatedLocationIsFiltered(false));
        dispatch(resetApplylocationFilter());
        dispatch(updateShouldSubmitSearch(true));
    };

    const propDateValue = (dateValue: string) => {
        dispatch(setDate(dateValue));
    };
    const propDateType = (dateType: string) => {
        dispatch(setType(dateType));
    };
    const propDateLabel = (dateValue: string) => {
        dispatch(setDateDropdownLabel(dateValue));
    };
    const propStartDate = (dateValue: string) => {
        dispatch(setStartDateCa(dateValue));
    };
    const propEndDate = (dateValue: string) => {
        dispatch(setEndDateCa(dateValue));
    };
    const setappliedDate = (dateValue: string) => {
        dispatch(setAppliedDate(dateValue));
    };
    const setappliedTypeFunction = (dateValue: string) => {
        dispatch(setAppliedType(dateValue));
    };

    const handleCountryChange = async (selectedCountryCode: string | undefined) => {
        if (!selectedCountryCode) return;

        const preferenceValue = selectedCountryCode === 'ca' ? shipmentDataSourceConstants.ca : shipmentDataSourceConstants.us;

        await saveShipmentDataSource({ PreferenceValue: preferenceValue, Country: 'ca' });

        if (selectedCountryCode === 'us') {
            history.push(PortalRoutes.clearances);
        }
    };

    const downloadAllCADData = () => {
        dispatch(
            DownloadCASSShipment({
                entries: [],
                viewModel: {
                    ...model,
                    PageSize: cadDownloadConfigParams.PAGE_SIZE,
                    StartRow: cadDownloadConfigParams.START_ROW,
                    ReturnAllFields: true,
                    Shipments: []
                },
                returnAllFields: true
            })
        );
    };

    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar columnsLayout mobileView={mobileView}>
                <div className='d-flex align-items-center'>
                    <span className='badge badge-circular badge-info mr-3'>
                        <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <h1 className='m-0 mr-2'>{translate('CompleteEntryDataTitle_Label')}</h1>
                </div>
                <div className={clsx('d-flex', mobileView && 'dropdown-examples')} style={{ alignItems: 'unset' }}>
                    {country && country.Code === 'ca' && shipmentDataSource && (
                        <ShipmentDataSource
                            translations={dataSourceTranslations}
                            dataSource={shipmentDataSource}
                            handleDataSource={handleDataSourceSelection}
                            shipmentDataSourceConstants={shipmentDataSourceConstants}
                        />
                    )}
                    <ClientChooser onSubmit={handleCountryChange} />
                </div>
            </HeaderBar>

            <CADSearchBar />

            <nav className='bg-white border-bottom' id='FilterBarWithDropdowns'>
                <div className='d-flex justify-content-start'>
                    <div
                        className={clsx(
                            'filters-and-nav-arrows-wrapper',
                            filterBarHasFixedWidth && 'fixed-width',
                            filtersHaveSelected && 'clear-all-showing'
                        )}
                    >
                        <div className='scrollable-filter-area d-flex flex-nowrap align-items-center py-3 pr-3' ref={scrollableDivRef}>
                            <div className='font-weight-medium pl-3 pr-2'>{translate('Filters_Label')}</div>
                            <DateDocumentsFilter
                                setappliedDate={setappliedDate}
                                setappliedType={setappliedTypeFunction}
                                appliedDate={appliedDate}
                                appliedType={appliedType}
                                date={date}
                                type={type}
                                dateDropdownLabel={dateDropdownLabel}
                                setDate={propDateValue}
                                setType={propDateType}
                                applyButtonFunction={applyButtonFunction}
                                propDateLabel={propDateLabel}
                                setEndDateCa={propEndDate}
                                setStartDateCa={propStartDate}
                                startDateCa={startDateCa}
                                endDateCa={endDateCa}
                            />
                            <LocationFilter />
                            <MOTFilter />
                        </div>
                        <Button
                            className='btn btn-tertiary btn-sm btn-scroll-left shadow'
                            onClick={() => {
                                scrollableDivItems && scrollableDivItems[direction.LEFT].scrollIntoView(false);
                                setScrollRight(true);
                            }}
                            style={{
                                display:
                                    filterBarHasFixedWidth &&
                                    scrollableDivRef.current &&
                                    scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                    !scrollRight
                                        ? 'block'
                                        : 'none'
                            }}
                            variant='link'
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                        <Button
                            className='btn btn-tertiary btn-sm btn-scroll-right shadow'
                            onClick={() => {
                                scrollableDivItems && scrollableDivItems[direction.RIGHT].scrollIntoView(false);
                                setScrollRight(false);
                            }}
                            style={{
                                display:
                                    filterBarHasFixedWidth &&
                                    scrollableDivRef.current &&
                                    scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                    scrollRight
                                        ? 'block'
                                        : 'none'
                            }}
                            variant='link'
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                        <div className='clear-all-filters d-flex flex-row align-items-center fixed-width text-center border-left shadow-sm justify-content-center justify-content-start'></div>
                    </div>
                    {filtersHaveSelected && (
                        <div
                            className={clsx(
                                'clear-all-filters d-flex flex-row align-items-center',
                                filterBarHasFixedWidth
                                    ? 'fixed-width text-center border-left shadow-sm justify-content-center'
                                    : 'justify-content-start'
                            )}
                        >
                            <Button
                                className={clsx('no-bold-underline', !filterBarHasFixedWidth && 'text-decoration-none')}
                                variant='link'
                                onClick={clearAllFilters}
                            >
                                {translate('ClearAll_Label')}
                            </Button>
                        </div>
                    )}
                </div>
            </nav>

            <GridHeader
                ref={gridHeaderRef}
                children={
                    !!model?.Shipments?.length && (
                        <DownloadAllLink
                            linkText={translate('DownloadAllCAD_Label')}
                            limitText={translate('DownloadAllCADLimit_Label')}
                            handleDownload={() => downloadAllCADData()}
                        />
                    )
                }
                columnsDropdown={
                    !!model?.Shipments?.length && (
                        <ColumnSelectionDropdown
                            allColumns={allColumns ?? allColumns}
                            visibleColumns={visibleColumns ?? visibleColumns}
                            areColumnsLoading={areColumnsLoading}
                            applyDropdownChanges={applyDropdownChanges}
                            descriptiveText={translate('TableColumnsButton_Label')}
                            columnActivatedChange={handleColumnActivated}
                        />
                    )
                }
            />
            <CompleteEntryDataGrid />
        </div>
    );
});

export default CompleteEntryDataPage;
