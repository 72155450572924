import produce from 'immer';
import { global, userRole } from '../../constants/mlp-constants';
import { initValidationMessages } from '../../utils/mlp-utils';
import * as actionTypes from '../actions/accessManagement/accessManagementActions';

const initialState = {
    model: {
        User: {},
        ClientServicesByCountry: [],
        TeamMembers: [],
        TeamMembersModalVisible: false,
        Note: '',
        UserHasPendingChanges: '',
        IsReadOnly: '',
        ChangedProperties: []
    },
    validationMessages: {},
    teamMembers: [],
    teamMembersModalVisible: false
};

const setUserDetail = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        draft.model.TeamMembersModalVisible = false;
        draft.model.TeamMembers = [];
        draft.validationMessages = action.validationMessages;
        draft.originalUser = action.model.User;
        draft.teamMembersModalVisible = false;
        draft.teamMembers = [];
        if (action.model.ChangedProperties) {
            draft.model.ChangedProperties = action.model.ChangedProperties;
        }
    });
};

const handleUpdate = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.key && action.payload.key.includes('User')) {
            const keySlice = action.payload.key.split('.');
            for (let prop in draft.model.User) {
                if (Object.prototype.hasOwnProperty.call(draft.model.User, prop)) {
                    if (prop === keySlice[1]) {
                        draft.model.User[prop] = action.payload.value;
                        if (!draft.model.ChangedProperties.includes(action.payload.key)) {
                            draft.model.ChangedProperties.push(action.payload.key);
                        }
                    }
                }
            }
        }
        for (let prop in draft.model) {
            if (Object.prototype.hasOwnProperty.call(draft.model, prop)) {
                if (prop === action.payload.key) {
                    draft.model[prop] = action.payload.value;
                    if (!draft.model.ChangedProperties.includes(action.payload.key)) {
                        draft.model.ChangedProperties.push(action.payload.key);
                    }
                }
            }
        }
    });
};

const setValidationMessagesClientAdmin = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.validationMessages) {
            draft.validationMessages = action.payload.validationMessages;
        } else {
            draft.validationMessages = initValidationMessages(state.model);
        }
        if (action.payload.validationMessages['AllParentServices']) {
            draft.validationMessages['AllParentServices'] = action.payload.validationMessages['AllParentServices'];
        }
    });
};

const selectAllFeaturesCA = (state, action) => {
    return produce(state, (draft) => {
        let features = [];
        let value = true;
        const paymentAdmin = draft.model.User.Roles.find((x) => x.IsSelected === true).Value === userRole.PAYMENTADMIN;
        const disabledClientServiceNumbers = Object.entries(draft.model.User.ClientStatuses).reduce((acc, [key, value]) => {
            if (value !== '') acc.push(key);
            return acc;
        }, []);

        const paymentFeature =
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices[0].Features.find((f) => {
                return f.FeatureId === action.payload.featureId;
            }).FeatureCode === global.PAY_FEATURE_CODE;

        if (paymentAdmin) {
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices.forEach((s) => {
                if (!s.PaymentAdminId && !s.PendingPaymentAdminId && s.IsPaymentEligible) {
                    features.push(
                        s.Features.find((f) => {
                            return f.FeatureId === action.payload.featureId;
                        })
                    );
                }
            });
        } else {
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices.forEach((service) => {
                const feature = service.Features.find((feature) => feature.FeatureId === action.payload.featureId);
                if (
                    ((!paymentFeature && !service.ClientAdminId && !service.PendingClientAdminId) ||
                        (paymentFeature && !service.PaymentAdminId && !service.PendingPaymentAdminId && service.IsPaymentEligible)) &&
                    (feature.IsSelected ||
                        feature.Name === 'Clearances' ||
                        feature.Name === 'My Livingston Portal' ||
                        !disabledClientServiceNumbers.includes(service.Number))
                ) {
                    features.push(feature);
                }
            });
        }

        if (
            features.every((f) => {
                return f.IsSelected;
            })
        ) {
            value = false;
        }

        if (value === true && !paymentAdmin && !paymentFeature) {
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices.forEach((s) => {
                if (!s.ClientAdminId && !s.PendingClientAdminId) {
                    let f = s.Features.find((f) => {
                        return f.FeatureCode === global.MLP_FEATURE_CODE;
                    });
                    f.IsSelected = true;
                }
            });
        }

        features.forEach((f) => {
            f.IsSelected = value;
        });
    });
};

const toggleCountryServices = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].IsHidden =
            !draft.model.ClientServicesByCountry[action.payload.countryIndex].IsHidden;
    });
};

const removeParentService = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices.splice(action.payload.parentIndex, 1);
    });
};

const updateFeature = (state, action) => {
    return produce(state, (draft) => {
        let cs =
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].ClientServices[
                action.payload.clientIndex
            ];
        cs.Features[action.payload.featureIndex].IsSelected = action.payload.isSelected;
        const mlpFeature = cs.Features.find((f) => {
            return f.FeatureCode === global.MLP_FEATURE_CODE;
        });
        const paymentFeature = cs.Features.find((f) => {
            return f.FeatureCode === global.PAY_FEATURE_CODE;
        });
        const mlpFeatureIndex = cs.Features.indexOf(mlpFeature);
        const selectedFeatures = cs.Features.filter((item) => {
            return item.IsSelected === true && item.FeatureCode !== global.PAY_FEATURE_CODE && item.FeatureCode !== global.MLP_FEATURE_CODE;
        });
        if (action.payload.isSelected) {
            // autocheck mlp column if exists
            if (cs.Features[mlpFeatureIndex]) {
                cs.Features[mlpFeatureIndex].IsSelected = true;
            }
        } else if (action.payload.featureIndex === cs.Features.indexOf(paymentFeature)) {
            //  when MakePayment is only selected feature autocheck/uncheck mlp column if exists
            if (cs.Features[mlpFeatureIndex]) {
                cs.Features[mlpFeatureIndex].IsSelected = selectedFeatures.length === 0 ? action.payload.isSelected : true;
            }
        }
    });
};

const removeClientService = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].ClientServices.splice(
            action.clientIndex,
            1
        );
    });
};

const removeFeature = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].AvailableFeatures.find(
            (f) => {
                return f.FeatureId === action.payload.featureId;
            }
        ).IsSelected = false;
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].ClientServices.forEach(
            (cs) => {
                cs.Features.find((f) => {
                    return f.FeatureId === action.payload.featureId;
                }).IsSelected = false;
            }
        );
    });
};

const addFeature = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].AvailableFeatures.find(
            (f) => {
                return f.FeatureId === action.payload.featureId;
            }
        ).IsSelected = true;
    });
};

const afterShowTeamMembers = (state, action) => {
    return produce(state, (draft) => {
        draft.model.TeamMembers = action.payload.teamMemberData;
        draft.model.TeamMembersModalVisible = true;
    });
};

const findWhere = (array, criteria) => {
    return array.find((item) => Object.keys(criteria).every((key) => item[key] === criteria[key]));
};

const afterAddClientServiceClientAdmin = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry.forEach((csc) => {
            // split children accross countries
            const parents = action.payload.item;
            let svc = parents.find((p) => {
                return p.CountryCode === csc.CountryCode;
            });

            if (!svc) {
                return;
            }

            svc.ClientServices.forEach((s) => {
                if (s.CountryCode.toLowerCase() !== csc.CountryCode.toLowerCase()) {
                    const index = svc.ClientServices.indexOf(s);
                    svc.ClientServices.splice(index, 1);
                }
            });

            // add parent service if it doesn't exist
            // if it does exist add only missing client services
            const existingParentService = findWhere(csc.ParentServices, { Number: svc.Number });

            if (existingParentService === undefined) {
                csc.ParentServices.push(svc);
                csc.ParentServices.sort((a, b) => a.Number - b.Number);
            } else {
                const servicesToAdd = svc.ClientServices.filter((n) => {
                    return !findWhere(existingParentService.ClientServices, { Number: n.Number, CaptionNumber: n.CaptionNumber });
                });

                if (servicesToAdd.length > 0) {
                    existingParentService.ClientServices = existingParentService.ClientServices.concat(servicesToAdd);
                }
            }
        });
    });
};

const setUserStatusClientAdmin = (state, action) => {
    return produce(state, (draft) => {
        draft.model.User.Status = action.status;
    });
};

const afterGetClientStatus = (state, action) => {
    return produce(state, (draft) => {
        draft.model.User.ClientStatuses = action.payload.item;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_DETAIL:
            return setUserDetail(state, action);
        case actionTypes.AFTER_ADD_CLIENT_SERVICE_CLIENT_ADMIN:
            return afterAddClientServiceClientAdmin(state, action);
        case actionTypes.AM_TOGGLE_COUNTRY_SERVICES:
            return toggleCountryServices(state, action);
        case actionTypes.AM_REMOVE_PARENT_SERVICE:
            return removeParentService(state, action);
        case actionTypes.AM_UPDATE_FEATURE:
            return updateFeature(state, action);
        case actionTypes.AM_REMOVE_CLIENT_SERVICE:
            return removeClientService(state, action);
        case actionTypes.AM_REMOVE_FEATURE:
            return removeFeature(state, action);
        case actionTypes.AM_ADD_FEATURE:
            return addFeature(state, action);
        case actionTypes.AM_SELECT_ALL_FEATURES_CA:
            return selectAllFeaturesCA(state, action);
        case actionTypes.AM_AFTER_SHOW_TEAM_MEMBERS:
            return afterShowTeamMembers(state, action);
        case actionTypes.HANDLE_UPDATE:
            return handleUpdate(state, action);
        case actionTypes.SET_VALIDATION_MESSAGES_CLIENT_ADMIN:
            return setValidationMessagesClientAdmin(state, action);
        case actionTypes.SET_USER_STATUS_CLIENT_ADMIN:
            return setUserStatusClientAdmin(state, action);
        case actionTypes.RESET_STATE_ACCCESS_MANAGEMENT:
            return initialState;
        case actionTypes.AFTER_GET_CLIENT_STATUS:
            return afterGetClientStatus(state, action);
        default:
            return state;
    }
};

export default reducer;
