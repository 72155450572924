import { clsx } from 'clsx';
import type { FormEvent } from 'react';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setPageSelected as setPageSelectedClearances } from '../../../pages/clearances/redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../pages/clearances/redux/reducers/shipmentStatusSlice';
import { setPageSelected as setPageSelectedProfile } from '../../../pages/profile/redux/reducers/profileGridSettingsSlice';
import { setPageSelected as setPageSelectedSubscribers } from '../../../pages/subscribers/redux/reducers/subscribersGridSettingsSlice';
import {
    useGetClientCaptionNumbersQuery,
    useGetClientSelectionSettingsQuery,
    useUpdateClientSelectionSettingsMutation
} from '../../../redux/api/clientSelectionApi';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { resetClientSelectionState, setIsApplyClicked } from '../../../redux/reducers/clientSelectionReducer';
import { setUserCountryCode } from '../../../redux/reducers/hydrationSlice';
import { addToast } from '../../../redux/reducers/toastSlice';
import { REACT_APP_ASSETS_URL } from '../../constants/constants-portal';
import useDropdownToggle from '../../hooks/useDropdownToggle';
import useTranslation from '../../hooks/useTranslation';
import Spinner from '../Spinner';
import classes from './ClientChooser.module.css';
import CountrySwitcher from './components/CountrySwitcher';
import SearchBar from './components/SearchBar';
import SelectionOptions from './components/SelectionOptions';

export interface ClientChooserRowOption {
    name: string;
    selected: boolean;
    caption: string;
    fullNumber: string;
}

type CountryCode = 'us' | 'ca';

export interface ClientChooserRow {
    name: string;
    country: CountryCode;
    number: string;
    fullNumber: string;
    options: ClientChooserRowOption[];
}

interface ClientChooserContextProps {
    searchIsLoading: boolean;
    setSearchIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    isUnrestricted: boolean;
    selectedCountryCode: CountryCode | undefined;
    availableCountries: {
        Code: CountryCode;
        Name: 'United States' | 'Canada';
        IsSelected: boolean;
    }[];
}

export const ClientChooserContext = createContext<ClientChooserContextProps | null>(null);

interface ClientChooserProps {
    onSubmit?: (selectedCountryCode: string | undefined) => void | Promise<void>;
}

export default function ClientChooser({ onSubmit }: ClientChooserProps) {
    const translate = useTranslation();

    const location = useLocation();

    const dispatch = usePortalDispatch();
    const { user } = usePortalSelector((state) => state.hydration);
    const { clientSelection, initialClientSelection } = usePortalSelector((state) => state.clientSelection);
    const isUnrestricted = clientSelection?.HasUnrestrictedAccess || clientSelection?.ServicesLimitReached || false;
    const selectedCountryCodeInitial = initialClientSelection?.Countries.find(({ IsSelected }) => IsSelected)?.Code;
    const totalClientsAmountInitial = initialClientSelection?.ClientServices.filter(
        ({ CountryCode }) => CountryCode === selectedCountryCodeInitial
    ).length;
    const selectedClientsAmountInitial = initialClientSelection?.ClientServices.filter(
        ({ IsSelected, CountryCode }) => IsSelected && CountryCode === selectedCountryCodeInitial
    ).length;
    const selectedCountryCode = clientSelection?.Countries.find(({ IsSelected }) => IsSelected)?.Code;

    const [searchQuery, setSearchQuery] = useState('');
    const [searchIsLoading, setSearchIsLoading] = useState(false);

    const form = useRef<HTMLFormElement>(null);
    const { isOpen, handleToggle, setIsOpen } = useDropdownToggle(form);

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const { isFetching: clientSelectionSettingsIsFetching } = useGetClientSelectionSettingsQuery(undefined, {
        refetchOnMountOrArgChange: true
    });
    const { isFetching: clientCaptionNumbersIsFetching } = useGetClientCaptionNumbersQuery();

    const [updateClientSelectionSettings, { isLoading: isLoadingUpdate }] = useUpdateClientSelectionSettingsMutation();

    const handleSubmit = useCallback(
        async (
            showToast: boolean,
            callback: ((selectedCountryCode: string | undefined) => void | Promise<void>) | undefined,
            e?: FormEvent<HTMLFormElement>
        ) => {
            e?.preventDefault();

            if (!clientSelection) return;

            const clientServices = !isUnrestricted
                ? clientSelection?.ClientServices
                : clientSelection?.ClientServices.filter(({ IsSelected }) => IsSelected);

            await updateClientSelectionSettings({
                ServicesLimitReached: clientSelection?.ServicesLimitReached,
                MaxSelectedServicesPerCountry: clientSelection?.MaxSelectedServicesPerCountry,
                HasUnrestrictedAccess: isUnrestricted,
                Countries: clientSelection?.Countries,
                ClientServices: clientServices
            });

            dispatch(setIsApplyClicked(true));

            if (selectedCountryCode) {
                dispatch(setUserCountryCode(selectedCountryCode));
            }

            if (showToast) {
                dispatch(addToast({ message: translate('ClientSelectionUpdated_Label'), type: 'success' }));
            }

            if (isMounted.current) {
                setIsOpen(false);
                setSearchQuery('');
            }

            if (callback) await callback(selectedCountryCode);

            // For clearances grid
            if (location.pathname.includes('/Clearances')) {
                dispatch(setPageSelectedClearances(1));
                dispatch(resetStatus());
            }

            // For subscribers grid
            if (location.pathname.includes('/Subscribers')) {
                dispatch(setPageSelectedSubscribers(1));
            }

            // For profile grid
            if (location.pathname.includes('/Profile')) {
                dispatch(setPageSelectedProfile(1));
            }
        },
        [
            clientSelection,
            dispatch,
            isUnrestricted,
            location.pathname,
            selectedCountryCode,
            setIsOpen,
            translate,
            updateClientSelectionSettings
        ]
    );

    const initialRender = useRef(true);

    // Click apply on initial render because sometimes user CountryCode is not equal to clientSelection CountryCode
    useEffect(() => {
        if (!selectedCountryCode || !initialRender.current) {
            return;
        }

        if (user?.CountryCode !== selectedCountryCode) {
            handleSubmit(false, undefined);
        }

        initialRender.current = false;
    }, [handleSubmit, selectedCountryCode, setIsOpen, user?.CountryCode]);

    const handleCancel = async () => {
        dispatch(resetClientSelectionState());
        setIsOpen(false);
        setSearchQuery('');
    };

    const initialClientsSelectedLabel = translate('ClientsSelected_Label', selectedClientsAmountInitial, totalClientsAmountInitial);
    const initialClientsSelectedLabelSplit = typeof initialClientsSelectedLabel === 'string' ? initialClientsSelectedLabel?.split(' ') : [];

    const isLoading = clientSelectionSettingsIsFetching || clientCaptionNumbersIsFetching || isLoadingUpdate;

    if (!clientSelection) return null;

    return (
        <ClientChooserContext.Provider
            value={{
                searchIsLoading,
                setSearchIsLoading,
                searchQuery,
                setSearchQuery,
                isUnrestricted,
                selectedCountryCode,
                availableCountries: clientSelection?.Countries
            }}
        >
            <div className='dropdown mr-1 mr-md-0'>
                <button
                    type='button'
                    className='btn btn-tertiary dropdown-toggle d-flex flex-row align-items-center'
                    onClick={handleToggle}
                >
                    <span className='dropdown-btn-flag'>
                        {selectedCountryCodeInitial && (
                            <img
                                src={`${REACT_APP_ASSETS_URL}/assets/images/icons/flags/${selectedCountryCodeInitial}-flag.svg`}
                                alt='USA flag'
                            />
                        )}
                    </span>
                    <span>{initialClientsSelectedLabelSplit[0]}</span>
                    <span className={clsx('ml-1', classes['visible-label-large'])}>{`${selectedCountryCodeInitial?.toUpperCase()} ${
                        initialClientsSelectedLabelSplit[1]
                    } ${initialClientsSelectedLabelSplit[2]}`}</span>
                </button>
                {isOpen && (
                    <form
                        className={clsx('dropdown-menu dropdown-menu-right p-0', classes.dropdown)}
                        ref={form}
                        onSubmit={handleSubmit.bind(null, true, onSubmit)}
                    >
                        <div className='card flex-1 shadow-none d-flex flex-column overflow-hidden'>
                            <CountrySwitcher />
                            <SearchBar />
                            <SelectionOptions />
                            <div className='d-flex justify-content-center flex-grow-0 px-4 pt-4 pb-3'>
                                <button type='button' className='btn btn-secondary btn-md flex-grow-1 mr-1 w-50' onClick={handleCancel}>
                                    {translate('Cancel_Label')}
                                </button>
                                <button
                                    type='submit'
                                    className={clsx(
                                        'btn btn-primary btn-md flex-grow-1 ml-1 w-50 d-flex align-items-center justify-content-center',
                                        isLoading && 'disabled'
                                    )}
                                    disabled={isLoading}
                                >
                                    {isLoading && <Spinner size='small' color='inherit' className='mr-2' />}
                                    {translate('Apply_Label')}
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </ClientChooserContext.Provider>
    );
}
