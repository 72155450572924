import { formatDateMMDDYY } from '../../../../../common/functions/parser';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { useUserDetailsQuery } from '../../../redux/api/profileApi';
import ProfileHeader from './ProfileHeader';
import ProfileSection from './ProfileSection';

export default function ProfileTab() {
    const translate = useTranslation();

    const { data: userDetails } = useUserDetailsQuery();

    return (
        <>
            <ProfileHeader />
            <div className='container-fluid'>
                <ProfileSection label={translate('StatusProfile_Label')} className='pb-4 pt-2 pb-md-5 pt-md-3'>
                    <div className='col-md-12'>
                        <span className='badge badge-pill badge-success'>{userDetails?.UserStatus?.Name}</span>
                    </div>
                </ProfileSection>
                <ProfileSection label={translate('PersonalProfile_Label')}>
                    <div className='col-12 col-md-9 '>
                        <div className='row info-block'>
                            <div className='col-md-6 mb-3'>
                                <h3 className='h5'>{translate('FirstName_Label')}</h3>
                                <div>{userDetails?.FirstName}</div>
                            </div>
                            <div className='col-md-6'>
                                <h3 className='h5'>{translate('LastName_Label')}</h3>
                                <div>{userDetails?.LastName}</div>
                            </div>
                        </div>
                    </div>
                </ProfileSection>
                <ProfileSection label={translate('CompanyProfile_Label')}>
                    <div className='col-md-12'>
                        <h3 className='h5'>{translate('CompanyNameProfile_Label')}</h3>
                        <div>{userDetails?.CompanyName}</div>
                    </div>
                </ProfileSection>
                <ProfileSection label={translate('ContactProfile_Label')}>
                    <div className='col-md-6 mb-3'>
                        <h3 className='h5'>{translate('EmailAddress_Label')}</h3>
                        <div>{userDetails?.Email}</div>
                    </div>
                    <div className='col-md-6'>
                        <h3 className='h5'>{translate('PhoneNumber_Label')}</h3>
                        <div>{userDetails?.Phone}</div>
                    </div>
                </ProfileSection>
                <ProfileSection label={translate('RoleProfile_Label')}>
                    <div className='col-md-12'>
                        <h3 className='h5'>{translate('UserRole_Label')}</h3>
                        <div>{userDetails?.UserRoles?.[0]?.Role?.DisplayName}</div>
                    </div>
                </ProfileSection>
                <ProfileSection label={translate('AccessExpiration_Label')}>
                    <div className='col-md-12'>
                        <h3 className='h5'>{translate('ExpirationDate_Label')}</h3>
                        <div>
                            {userDetails?.ExpirationDate
                                ? formatDateMMDDYY(userDetails?.ExpirationDate)
                                : translate('NoExpirationDate_Label')}
                        </div>
                    </div>
                </ProfileSection>
            </div>
        </>
    );
}
