import { clsx } from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItemProps {
    url?: string;
    children: React.ReactNode;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ url, children }) => {
    return <li className={clsx('breadcrumb-item', !url && 'active')}>{url ? <Link to={url}>{children}</Link> : children}</li>;
};

export default BreadcrumbItem;
