import { API_CLEARANCES_PATH, shipmentDataSourceConstants } from '../../../../common/constants/constants-portal';
import { GetCustomsInfoIdPayload } from '../../../../common/models/EllipsisModels';
import { apiSlice } from '../../../../redux/api/apiSlice';
import { setCustomsInfoId, setEllipsisEntryNo } from '../../../../redux/reducers/ellipsisSlice';

interface SaveShipmentDataSourceBody {
    PreferenceValue: string;
    Country: string;
}

type ShipmentDataSource = (typeof shipmentDataSourceConstants)[keyof typeof shipmentDataSourceConstants];

export const clearancesApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getShipmentDataSource: build.query<ShipmentDataSource, void>({
            query: () => ({
                url: `${API_CLEARANCES_PATH}/clearance/get-shipment-data-source/ca`
            })
        }),
        saveShipmentDataSource: build.mutation<ShipmentDataSource, SaveShipmentDataSourceBody>({
            query: (body) => ({
                url: `${API_CLEARANCES_PATH}/clearance/save-shipment-data-source`,
                method: 'POST',
                body
            })
        }),
        customsInfoId: build.query<string, GetCustomsInfoIdPayload>({
            query: ({ ClientNumbers, DocumentEntryNumber }) => ({
                url: `${API_CLEARANCES_PATH}/GetCustomsInfoIdByEntryNumber`,
                method: 'POST',
                body: {
                    ColumnFilters: {
                        DocumentEntryNumber
                    },
                    DateRange: {
                        DateRangeSelected: null
                    },
                    ClientCaptionNumbers: ClientNumbers
                }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    setCustomsInfoId(data);

                    if (arg.IsForEllipsis && arg.DocumentEntryNumber) {
                        dispatch(setEllipsisEntryNo(arg.DocumentEntryNumber));
                    }
                } catch (error) {
                    console.error('Failed to get customs info id:', error);
                }
            }
        })
    })
});

export const {
    useGetShipmentDataSourceQuery,
    useLazyGetShipmentDataSourceQuery,
    useSaveShipmentDataSourceMutation,
    useLazyCustomsInfoIdQuery,
    useCustomsInfoIdQuery
} = clearancesApi;
