import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOM from 'react-dom';
import useTranslation from '../../../../common/hooks/useTranslation';
import useTranslationHydration from '../../../../common/hooks/useTranslationHydration';
import { EntryClientPair } from '../../../clearances/common/models/CADDetails';
import { DownloadCASSShipment } from '../../redux/actions/completeEntryData';
import { useCompleteEntryDataDispatch } from '../../redux/hooks';

interface DocumentsFooterProps {
    amountSelected?: string;
    selectedEntries: EntryClientPair[];
}

const CompleteEntryDataFooter: React.FC<DocumentsFooterProps> = ({ amountSelected, selectedEntries }) => {
    const dispatch = useCompleteEntryDataDispatch();
    const parentContainer = document.querySelector('.navbar-and-stage-wrapper') ?? document.body;
    const translate = useTranslation();
    const translateHydration = useTranslationHydration();

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const handleGridColumnData = () => {
        dispatch(
            DownloadCASSShipment({
                entries: selectedEntries,
                viewModel: null,
                returnAllFields: false
            })
        );
    };

    const handleFullCADData = () => {
        dispatch(
            DownloadCASSShipment({
                entries: selectedEntries,
                viewModel: null,
                returnAllFields: true
            })
        );
    };

    return ReactDOM.createPortal(
        <div id='FixedFooter' className='bg-white shadow-lg' style={{ zIndex: '1201', width: '-webkit-fill-available' }}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex flex-column flex-md-row align-items-md-center align-items-stretch justify-content-md-end py-3'>
                            <div className='d-flex justify-content-start'>
                                <div className='helper-text'>{`${amountSelected} ${translate('ItemsSelected_Label')}`}</div>
                            </div>

                            <div style={{ flex: 1 }}></div>
                            {isSafari && (
                                <div className='d-flex justify-content-center' style={{ flex: 1, color: 'red' }}>
                                    <div className='dropup ml-md-2'>
                                        <button
                                            className='btn btn-primary btn-block dropdown-toggle justify-content-center'
                                            type='button'
                                            id='dropdownMenuButton'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            {translateHydration('DownloadButton_Label')}
                                        </button>
                                        <div className='dropdown-menu w-100 w-md-auto' aria-labelledby='dropdownMenuButton'>
                                            <span
                                                className='btn dropdown-item d-flex justify-content-between align-items-center omit-checkmark'
                                                onClick={handleGridColumnData}
                                            >
                                                {translate('GridColumnData_Label')}{' '}
                                                <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                                            </span>
                                            <span
                                                className='btn dropdown-item d-flex justify-content-between align-items-center omit-checkmark'
                                                onClick={handleFullCADData}
                                            >
                                                {translate('FullCADData_Label')} <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isSafari && (
                                <div className='d-flex flex-column flex-sm-row flex-shrink-3'>
                                    <div className='dropup ml-md-2'>
                                        <button
                                            className='btn btn-primary btn-block dropdown-toggle justify-content-center'
                                            type='button'
                                            id='dropdownMenuButton'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            {translateHydration('DownloadButton_Label')}
                                        </button>
                                        <div className='dropdown-menu w-100 w-md-auto' aria-labelledby='dropdownMenuButton'>
                                            <span
                                                className='btn dropdown-item d-flex justify-content-between align-items-center omit-checkmark'
                                                onClick={handleGridColumnData}
                                            >
                                                {translate('GridColumnData_Label')}{' '}
                                                <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                                            </span>
                                            <span
                                                className='btn dropdown-item d-flex justify-content-between align-items-center omit-checkmark'
                                                onClick={handleFullCADData}
                                            >
                                                {translate('FullCADData_Label')} <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        parentContainer
    );
};

export default CompleteEntryDataFooter;
