import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { clsx } from 'clsx';
import { useState } from 'react';
import Button from '../../../../../common/features/Button';
import CountryFlag from '../../../../../common/features/CountryFlag';
import MilestoneDropdown from '../../../../../common/features/MilestoneDropdown';
import Modal from '../../../../../common/features/Modal';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import { formatDateMMDDYY } from '../../../../../common/functions/parser';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Notifications, SubscriptionsData } from '../../../../../common/models/ResponseData';
import OnHoldWrapper from '../../../../profile/screens/Profile/ClearanceNotificationsTab/OnHoldWrapper';
import { UpdateClearanceSubscriptionRequest } from '../../../common/models/EditSubscriber';
import { UpdateGlobalSubscription } from '../../../redux/actions/editSubscribers';
import { useSubscribersDispatch, useSubscribersSelector } from '../../../redux/hooks';
import { resetUnsubscribe, setCustomerNumber, setSubscriptionUpdateAction } from '../../../redux/reducers/editSubscriptiosSubscribersSlice';
import { resetSelectedState } from '../../../redux/reducers/subscribersGridSettingsSlice';
import NotificationTypesDropdown from './NotificationTypesDropdown';

interface DataItem extends SubscriptionsData {
    selected?: boolean;
    isNew?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    isLocked: boolean;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    isLocked,
    className,
    style,
    selectionChange,
    ...rest
}) => {
    const { unsubscribe, editSubscriber } = useSubscribersSelector((state) => state.editSubscriptionsSubscriber);
    const { user } = useSubscribersSelector((state) => state.hydration);
    const [showModal, setShowModal] = useState(false);
    const translate = useTranslation();
    const dispatch = useSubscribersDispatch();
    let cellContent;

    const handleSubscriptionUpdate = (action: string, requestModel: UpdateClearanceSubscriptionRequest) => {
        dispatch(resetSelectedState());
        if (unsubscribe.removedSubscribersAmount) {
            dispatch(resetUnsubscribe());
        }
        dispatch(setSubscriptionUpdateAction(action));
        dispatch(setCustomerNumber(requestModel.CustomerNumber));
        dispatch(UpdateGlobalSubscription({ requestModel }));
    };

    const customerNumber = (dataItem: any) => {
        return <span>{dataItem.CustomerNumber}</span>;
    };

    const entryNumber = (dataItem: any) => {
        return <span>{dataItem.EntryNo}</span>;
    };

    const subscriptionType = (dataItem: any) => {
        const typeLabel = {
            SINGLE: 'SingleEntry_Label',
            CLIENT: 'ClientNo_Label'
        }[dataItem.SubscriptionType as 'SINGLE' | 'CLIENT'];

        return <span>{translate(typeLabel)}</span>;
    };

    const milestoneDropdown = (dataItem: any) => {
        const handleUpdate = (
            action: string,
            customerNumber: null | string,
            subscriptionId: null | number,
            milestones: null | Notifications[]
        ) => {
            if (user?.InternalUser) return;
            handleSubscriptionUpdate(action, {
                SubscriptionId: subscriptionId,
                Name: dataItem.Name,
                CustomerNumber: customerNumber,
                Language: editSubscriber.language,
                UserId: dataItem.UserId,
                Email: dataItem.ProfileEmail,
                Phone: dataItem.ProfilePhone,
                FullName: editSubscriber.fullName,
                RoleName: editSubscriber.role,
                DocID: dataItem.DocID,
                MilestoneNotifications: milestones
            });
        };

        return (
            <MilestoneDropdown
                {...dataItem}
                updateMilestones={handleUpdate}
                translate={translate}
                disabled={!dataItem?.IsAvailableToSubscribe || isLocked}
                applyButtonLabel={
                    !user?.InternalUser ? (
                        translate('Apply_Label')
                    ) : (
                        <Tooltip label='Disabled for LII users.'>
                            <span>{translate('Apply_Label')}</span>
                        </Tooltip>
                    )
                }
                cancelButtonLabel={translate('Cancel_Label')}
            />
        );
    };

    const subscribeButton = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        const subscribed = dataItem?.SubscriptionId ? dataItem?.SubscriptionId : false;
        const clientNumber = dataItem?.CustomerNumber;

        return (
            <>
                <div style={{ left: '0px', right: '0px' }}>
                    <Button
                        variant={subscribed ? 'danger-ghost' : 'ghost'}
                        onClick={() => {
                            if (dataItem?.IsAvailableToSubscribe) {
                                subscribed
                                    ? setShowModal(true)
                                    : handleSubscriptionUpdate('subscribe', {
                                          SubscriptionId: dataItem?.SubscriptionId,
                                          Name: dataItem.Name,
                                          CustomerNumber: clientNumber,
                                          Language: editSubscriber.language,
                                          UserId: dataItem.UserId,
                                          Email: dataItem.ProfileEmail,
                                          Phone: dataItem.ProfilePhone,
                                          FullName: editSubscriber.fullName,
                                          RoleName: editSubscriber.role,
                                          DocID: dataItem.DocID,
                                          MilestoneNotifications:
                                              country === 'ca' ? dataItem.MilestoneNotifications_CA : dataItem.MilestoneNotifications_US
                                      });
                            }
                        }}
                        disabled={user?.InternalUser ? true : isLocked}
                        data-target='#modal'
                    >
                        <FontAwesomeIcon icon={subscribed ? faBellSlash : faBell} className='mr-1' />
                        {subscribed ? (
                            translate('Unsubscribe_Label')
                        ) : !dataItem?.IsAvailableToSubscribe ? (
                            <Tooltip label={translate('NotificationsSubscribeMessageAllNoClearances_Label')}>
                                <span>{translate('Subscribe_Label')}</span>
                            </Tooltip>
                        ) : (
                            translate('Subscribe_Label')
                        )}
                    </Button>
                </div>
                {showModal && (
                    <Modal
                        header={translate('AreYouSureYouWantToUnsubscribe_Label', clientNumber)}
                        title={translate('YouWillBeUnsubscribedFromAll_Label')}
                        toggleModal={setShowModal}
                    >
                        <>
                            <Button
                                variant='ghost'
                                aria-label={translate('Close_Label')}
                                className='btn-lg'
                                children={translate('Close_Label')}
                                data-dismiss='modal'
                                onClick={() => setShowModal(false)}
                            />
                            <Button
                                variant='danger'
                                aria-label={translate('YesUnsubscribe_Label')}
                                className='btn-lg'
                                children={translate('YesUnsubscribe_Label')}
                                data-dismiss='modal'
                                onClick={() => [
                                    handleSubscriptionUpdate('unsubscribe', {
                                        SubscriptionId: dataItem?.SubscriptionId,
                                        Name: dataItem.Name,
                                        CustomerNumber: clientNumber,
                                        Language: editSubscriber.language,
                                        UserId: dataItem.UserId,
                                        Email: null,
                                        Phone: null,
                                        FullName: editSubscriber.fullName,
                                        RoleName: editSubscriber.role,
                                        DocID: dataItem.DocID,
                                        MilestoneNotifications: []
                                    }),
                                    setShowModal(false)
                                ]}
                            />
                        </>
                    </Modal>
                )}
            </>
        );
    };

    const onHoldToggle = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        let milestones = dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!;

        const handleToggle = (value: boolean) => {
            milestones = milestones.map((item: { Name: string }) => {
                if (item.Name === `HasNotificationsForClearanceOnHold${country === 'ca' ? '_CA' : ''}`) {
                    return { Name: item.Name, Value: value };
                }
                return item;
            });
            handleSubscriptionUpdate('onhold', {
                SubscriptionId: dataItem.SubscriptionId,
                Name: dataItem.Name,
                CustomerNumber: dataItem.CustomerNumber,
                Language: editSubscriber.language,
                UserId: dataItem.UserId,
                Email: dataItem.ProfileEmail,
                Phone: dataItem.ProfilePhone,
                FullName: editSubscriber.fullName,
                RoleName: editSubscriber.role,
                DocID: dataItem.DocID,
                MilestoneNotifications: milestones
            });
        };

        return (
            <OnHoldWrapper
                data={dataItem}
                toggleChecked={handleToggle}
                disabled={!dataItem?.IsAvailableToSubscribe || isLocked || user?.InternalUser}
            />
        );
    };

    const countryFlagImage = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        return (
            <div className='pl-3'>
                <CountryFlag countryCode={country} />
            </div>
        );
    };

    const dateSubscribed = (dataItem: any) => {
        const date = formatDateMMDDYY(dataItem.DateSubscribed);
        return <>{date}</>;
    };

    const notificationTypes = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        const email = dataItem.ProfileEmail;
        const phone = dataItem.ProfilePhone;
        let milestones = dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!;

        const handleNotificationTypes = (isEmailChecked: boolean, isMobileChecked: boolean) => {
            if (user?.InternalUser) return;
            handleSubscriptionUpdate('notifTypes', {
                SubscriptionId: dataItem.SubscriptionId,
                Name: dataItem.Name,
                CustomerNumber: dataItem.CustomerNumber,
                Language: editSubscriber.language,
                UserId: dataItem.UserId,
                Email: isEmailChecked ? email : null,
                Phone: isMobileChecked ? phone : null,
                FullName: editSubscriber.fullName,
                RoleName: editSubscriber.role,
                DocID: dataItem.DocID,
                MilestoneNotifications: milestones
            });
        };

        return (
            <NotificationTypesDropdown
                subscriptionId={dataItem.SubscriptionId}
                disabled={!dataItem?.IsAvailableToSubscribe || isLocked}
                applyChange={handleNotificationTypes}
            />
        );
    };

    switch (field) {
        case 'CustomerNumber':
            cellContent = dataItem ? customerNumber(dataItem) : null;
            break;
        case 'EntryNumber':
            cellContent = dataItem ? entryNumber(dataItem) : null;
            break;
        case 'SubscriptionType':
            cellContent = dataItem ? subscriptionType(dataItem) : null;
            break;
        case 'Milestones':
            cellContent = dataItem ? milestoneDropdown(dataItem) : null;
            break;
        case 'Subscription':
            cellContent = dataItem ? subscribeButton(dataItem) : null;
            break;
        case 'OnHoldNotifications':
            cellContent = dataItem ? onHoldToggle(dataItem) : null;
            break;
        case 'Country':
            cellContent = dataItem ? countryFlagImage(dataItem) : null;
            break;
        case 'DateSubscribed':
            cellContent = dataItem ? dateSubscribed(dataItem) : null;
            break;
        case 'NotificationTypes':
            cellContent = dataItem ? notificationTypes(dataItem) : null;
            break;
        case 'selected':
            cellContent = (
                <Checkbox
                    value={!!dataItem.selected}
                    onChange={selectionChange}
                    disabled={!editSubscriber.isEditable ? true : !dataItem.IsEditable}
                />
            );
            break;
        default:
            cellContent = null;
    }

    return (
        <>
            <td
                className={clsx(
                    className,
                    dataItem?.selected && 'k-state-selected',
                    dataItem?.isNew && 'new-row-highlight',
                    field === 'selected' ? 'text-center' : 'text-left'
                )}
            >
                {cellContent}
            </td>
        </>
    );
};
export default FormattedGridCell;
