import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { useDocumentsDispatch, useDocumentsSelector } from '../../../redux/hooks';
import {
    resetSelectedEntries,
    setSearchBarOptions,
    updateSearchFilters,
    updateSearchKeywords,
    updateSubmittedSearchKeywords
} from '../../../redux/reducers/imagingSlice';
import SearchChip from './SearchChip';
import './styles.css';

// const suggestedSearches = [
//     { query: 'Billing invoices', period: '30 days' },
//     { query: 'Customs release & Customs entry docs', period: '3 months' },
//     { query: 'Customs release & Customs entry docs', period: '24 hrs' }
// ];

// const recentSearches = [
//     { keywords: ['404590'], time: 'Today' },
//     { keywords: ['9830143390037', '9830143390037'], time: 'Yesterday' },
//     { keywords: ['300-40364571'], time: '13/12/22' },
//     { keywords: ['6437554603423', '6029915117638', '613220'], time: '12/10/22' }
// ];

const areArraysEqual = (a: { id: string; text: string }[], b: { id: string; text: string }[]) =>
    a.length === b.length && a.every((element, index) => element.id === b[index].id);

const generateUUID = () => {
    return `${Date.now()}-${Math.random().toString(36).slice(2, 9)}`;
};

const DoucmentsSearchBar = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchKeywords, setSearchKeywords] = useState<{ id: string; text: string }[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const [onEnterKey, setOnEnterKey] = useState(false);
    const translate = useTranslation();

    const dispatch = useDocumentsDispatch();

    const { searchKeywords: reduxSearchKeywords } = useDocumentsSelector((state) => state.imaging);

    const { initialClientSelection } = useDocumentsSelector((state) => state.clientSelection);

    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;

    const placeholderText = !searchKeywords.length
        ? selectedCountryCodeInitial === 'us'
            ? translate('InputPlaceholderUS_Label')
            : translate('InputPlaceholderCA_Label')
        : '';

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const cleanString = value.replace(/[^a-zA-Z0-9,_\s-/]/g, '');

        setSearchInput(cleanString);
    };

    const handleClearSearch = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        resetSearchAndFilters();
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const resetSearchAndFilters = () => {
        setSearchInput('');
        setSearchKeywords([]);
        inputRef.current && inputRef.current.focus();

        dispatch(updateSubmittedSearchKeywords([]));
        return dispatch(
            updateSearchFilters({
                value: [],
                country: selectedCountryCodeInitial || 'us'
            })
        );
    };

    const updateKeywords = (nextVal?: string) => {
        const trimmedSearchInput = nextVal ? nextVal.trim() : searchInput.trim();

        if (trimmedSearchInput === '') {
            setSearchInput('');
            return;
        }
        const newSearchKeywords = [...searchKeywords];

        if (trimmedSearchInput.includes(' ') || trimmedSearchInput.includes(',') || trimmedSearchInput.includes('	')) {
            const keywordArr = trimmedSearchInput.split(/[\s,]/).filter((keyword) => keyword !== '');

            keywordArr.forEach((keyword) => {
                if (keyword !== '') {
                    newSearchKeywords.push({ id: generateUUID(), text: keyword });
                }
            });
        } else {
            newSearchKeywords.push({ id: generateUUID(), text: trimmedSearchInput });
        }

        setSearchKeywords(newSearchKeywords);
        setSearchInput('');
    };

    const removeKeyword = (keywordId: string) => {
        setSearchKeywords(searchKeywords.filter(({ id }) => id !== keywordId));

        if (searchKeywords.length <= 1) {
            resetSearchAndFilters();
        }
    };

    const handlePasteInput = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();

        const paste = e.clipboardData.getData('text');
        updateKeywords(paste);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // revert last keyword to text on backspace
        if (searchInput === '' && e.key === 'Backspace') {
            if (searchKeywords.length !== 0) {
                const lastElement = searchKeywords.slice(-1);
                setSearchKeywords(searchKeywords.slice(0, -1));
                setSearchInput(lastElement[0].text);
            }
        }
        if (e.key === 'Enter') {
            updateKeywords();
            setOnEnterKey(true);
        }
    };

    const handleInputBlur = () => {
        if (searchInput) updateKeywords();
    };

    const handleSubmit = (val: { id: string; text: string }[] | undefined, e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const payload = {
            value: val?.length ? val : searchKeywords,
            country: selectedCountryCodeInitial || 'us'
        };

        dispatch(resetSelectedEntries());
        dispatch(setSearchBarOptions());
        dispatch(updateSearchFilters(payload));
        dispatch(updateSubmittedSearchKeywords(searchKeywords));
    };

    useEffect(() => {
        if (!areArraysEqual(searchKeywords, reduxSearchKeywords)) {
            setSearchKeywords(reduxSearchKeywords);
        }
    }, [reduxSearchKeywords]);

    useEffect(() => {
        // only update after user adds a space or comma
        // handle copied sheet cells containing tab separators
        if (searchInput.includes(' ') || searchInput.includes(',') || searchInput.includes('	')) updateKeywords();
    }, [searchInput, dispatch]);

    useEffect(() => {
        dispatch(updateSearchKeywords(searchKeywords));
    }, [searchKeywords, dispatch]);

    // Clear search bar when client selection changes
    useEffect(() => {
        setSearchKeywords([]);
        dispatch(updateSearchKeywords([]));
        setSearchInput('');
    }, [initialClientSelection]);

    useEffect(() => {
        if (!searchKeywords.length && inputRef.current)
            inputRef.current.placeholder = searchInput || searchKeywords.length > 0 ? '' : placeholderText;
    }, [searchKeywords]);

    return (
        <form
            role='search'
            onSubmit={handleSubmit.bind(null, undefined)}
            className='input-group pb-3 pt-3 pl-3 pr-3 container-fluid border-bottom flex-nowrap bg-white'
        >
            <div className='simplified-search-bar simplified-search-bar-with-button documents_search_bar'>
                <div className='chips-input-wrapper'>
                    <div className='magnifying-glass-input-icon documents_search_bar_icon'>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <div className='documents_search_chips chips'>
                        {searchKeywords.map(({ id, text }) => (
                            <SearchChip key={id} id={id} text={text} setSearchKeywords={setSearchKeywords} onRemove={removeKeyword} />
                        ))}
                        <input
                            type='text'
                            ref={inputRef}
                            className='documents_search_input form-control preserve-borders'
                            placeholder={placeholderText}
                            value={searchInput}
                            onChange={handleChangeInput}
                            onKeyDown={handleKeyDown}
                            onBlur={handleInputBlur}
                            onPaste={handlePasteInput}
                        />
                    </div>
                    <div className='justify-content-end'>
                        <span
                            role='button'
                            onClick={handleClearSearch}
                            className={clsx('form-clear-simplified-search form-clear', !searchKeywords.length && 'd-none')}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                    </div>
                </div>
            </div>
            <div className='documents_search_bar_btn input-group-append'>
                <button type='submit' className='btn btn-primary d-flex align-items-center'>
                    {translate('SearchSubmitButton_Label')}
                </button>
            </div>
        </form>
    );
};

export default DoucmentsSearchBar;
