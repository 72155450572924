import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import ModalWrapper from '../../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { SubscribersModals } from '../../../../../../common/models/SubscribersModals';
import { useClearancesDispatch, useClearancesSelector } from '../../../../redux/hooks';
import { resetNewSubscribers, resetSubscribe } from '../../../../redux/reducers/clearanceSubscribersSlice';
import SingleSubscriberNotifications from './SingleSubscriberNotifications';
import SubscriberInfoForm from './SubscriberInfoForm';

interface AddSubscriberModalProps {
    toggleModal: (prop: keyof SubscribersModals, next?: boolean) => void;
    clearanceCustomerNo?: string;
}

const AddSubscriberModal: React.FC<AddSubscriberModalProps> = ({ toggleModal, clearanceCustomerNo }) => {
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);
    const [step, setStep] = useState(1);
    const dispatch = useClearancesDispatch();
    const translate = useTranslation();

    // On initial load reset "subscribe", "newSubscribers" and "newSubscribersValidated"
    useEffect(() => {
        dispatch(resetSubscribe());
        dispatch(resetNewSubscribers());
    }, [dispatch]);

    const handleStep = (value: number) => {
        setStep(value);
    };

    const handleAddSubscribers = () => {
        toggleModal('add', false);
    };

    return (
        <ModalWrapper id='SteppedProgressModal' onEscape={() => toggleModal('cancelAdd', true)}>
            <div className='modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <div className='mr-4'>
                            <h3 className='mb-0'>
                                {step === 1
                                    ? translate('AddSubscribersHeadline_Label')
                                    : translate('AddSubscribersHeadlineEntry_Label', shipmentDetails?.EntryNumber)}
                            </h3>
                            <h4 className='font-weight-medium mb-0'>
                                <span className='text-primary'>{translate('StepOfSteps_Label', step, 2)} </span>
                                {step === 1 ? translate('AddSubscribersStep1_Label') : translate('SelectMilestones_Label')}
                            </h4>
                        </div>
                        <button
                            type='button'
                            className='modal-close btn btn-tertiary btn-lg'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={() => toggleModal('cancelAdd', true)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div>
                        <div className='progress bg-info-light rounded-0 flex-shrink-0' style={{ height: '6px' }}>
                            <div className={clsx('progress-bar bg-primary', step === 1 ? 'w-50' : 'w-100')} role='progressbar'></div>
                        </div>
                    </div>

                    {step === 1 && <SubscriberInfoForm handleStep={handleStep} clearanceCustomerNo={clearanceCustomerNo} />}

                    {step === 2 && (
                        <SingleSubscriberNotifications handlePrevStep={() => setStep(1)} closeModal={() => handleAddSubscribers()} />
                    )}
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AddSubscriberModal;
