import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Contact, TeamsAdminByClientServices } from '../../common/models/Contact';
import type { ContactsSlice } from '../../common/models/ReduxSlices';

const emptyContact: Contact = {
    FullName: '',
    Email: '',
    MobileNumber: '',
    RoleLabel: '',
    Language: '',
    RowId: 0,
    CarrierCodes: ''
};
const initialEditState: Contact = {
    FullName: '',
    Email: '',
    MobileNumber: '',
    RoleLabel: '',
    Language: '',
    CarrierCodes: '',
    RowId: 1,
    ExternalContactID: 0,
    IsFullNameValid: true,
    IsEmailValid: true,
    IsMobileNumberValid: true,
    IsRoleLabelValid: true,
    IsLanguageValid: true,
    IsEditedRoleEmpty: true
};

const initialState: ContactsSlice = {
    contacts: [],
    newContacts: [emptyContact],
    editContact: initialEditState,
    formIsSubmitted: false,
    isLoading: true,
    error: undefined
};

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setNewContactAdmin(state, action: PayloadAction<{ id: number; value: TeamsAdminByClientServices }>) {
            const val = action.payload.value;
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? {
                          ...contact,
                          TeamId: val.TeamId,
                          TeamAdminUserCompanyName: val.TeamAdminUserCompanyName,
                          TeamAdminUserEmail: val.TeamAdminUserEmail,
                          TeamAdminUserFullName: val.TeamAdminUserFullName,
                          TeamAdminUserId: val.TeamAdminUserId
                      }
                    : contact
            );
        },
        setNewContactFullName(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, FullName: action.payload.value, IsFullNameValid: action.payload.isValid }
                    : contact
            );
        },
        setNewContactEmail(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, Email: action.payload.value, IsEmailValid: action.payload.isValid }
                    : contact
            );
        },
        setNewContactMobileNumber(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, MobileNumber: action.payload.value, IsMobileNumberValid: action.payload.isValid }
                    : contact
            );
        },
        setNewContactRole(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? action.payload.value.length
                        ? { ...contact, RoleLabel: action.payload.value + '_Label', IsRoleLabelValid: true }
                        : { ...contact, RoleLabel: '', IsRoleLabelValid: false }
                    : contact
            );
        },
        setNewContactLanguage(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? action.payload.value !== '-'
                        ? { ...contact, Language: action.payload.value, IsLanguageValid: true }
                        : { ...contact, Language: '', IsLanguageValid: false }
                    : contact
            );
        },
        setNewContactCarrierNumber(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, CarrierCodes: action.payload.value, IsCarrierCodesValid: action.payload.isValid }
                    : contact
            );
        },
        addNewContact(state) {
            state.newContacts.push({ ...emptyContact, RowId: Math.random() * 1000 + 1 });
        },
        removeNewContact(state, action: PayloadAction<number>) {
            if (state.newContacts.length === 1) {
                state.newContacts = initialState.newContacts;
                return;
            }
            state.newContacts = state.newContacts.filter((contact) => contact.RowId !== action.payload);
        },
        resetNewContacts(state) {
            state.newContacts = initialState.newContacts;
        },
        setFormIsSubmitted(state, action: PayloadAction<{ value: boolean }>) {
            state.formIsSubmitted = action.payload.value;
        },
        setInitialEditContact(state, action: PayloadAction<Contact>) {
            state.editContact = { ...state.editContact, ...action.payload };
        },
        setEditContactsValues(state, action: PayloadAction<{ value: string; name: string; isValid: boolean | null }>) {
            if (action.payload.name === 'RoleLabel') {
                state.editContact = {
                    ...state.editContact,
                    [action.payload.name]: action.payload.value + '_Label',
                    IsRoleLabelValid: action.payload.value.length > 0,
                    CarrierCodes: action.payload.value.includes('Carrier') ? state.editContact.CarrierCodes : ''
                };
            } else if (action.payload.name === 'Language') {
                state.editContact = {
                    ...state.editContact,
                    [action.payload.name]: action.payload.value,
                    IsLanguageValid: action.payload.value !== '-'
                };
            } else {
                state.editContact = {
                    ...state.editContact,
                    [action.payload.name]: action.payload.value,
                    ['Is' + action.payload.name + 'Valid']: action.payload.isValid
                };
            }
        },
        setEditedRoleChecker(state, action: PayloadAction<boolean>) {
            state.editContact.IsEditedRoleEmpty = action.payload.valueOf();
        },
        setExternalContactID(state, action: PayloadAction<number | undefined | null>) {
            state.editContact = { ...state.editContact, ExternalContactID: action.payload };
        }
    }
});

export const {
    setNewContactAdmin,
    setNewContactFullName,
    setNewContactEmail,
    setNewContactMobileNumber,
    setNewContactRole,
    setNewContactLanguage,
    addNewContact,
    removeNewContact,
    resetNewContacts,
    setFormIsSubmitted,
    setEditContactsValues,
    setExternalContactID,
    setInitialEditContact,
    setEditedRoleChecker,
    setNewContactCarrierNumber
} = contactsSlice.actions;

export default contactsSlice.reducer;
