import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REACT_APP_ASSETS_URL } from '../../../common/constants/constants-portal';
import Button from '../../../common/features/Button';
import Spinner from '../../../common/features/Spinner';
import { useTeamAdminsQuery } from '../redux/api/contactsApi';
import { useContactsSelector } from '../redux/hooks';

interface EmptyStateProps {
    onAdd: () => void;
    descriptionLabel: string;
    addButtonLabel: React.ReactNode;
    disabled?: boolean;
}

export default function EmptyStateContacts({ onAdd, descriptionLabel, addButtonLabel, disabled }: EmptyStateProps) {
    const { user } = useContactsSelector((state) => state.hydration);

    const { data: teamAdmins, isFetching: teamAdminsIsFetching } = useTeamAdminsQuery(undefined, { skip: !user?.InternalUser });

    return (
        <div className='container-fluid mt-4 mb-4 d-flex flex-column justify-content-center align-items-center flex-fill'>
            <img
                className='image-150px'
                src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/objects/object-inbox-zero.svg`}
                alt='Empty state'
            />
            <h3>{descriptionLabel}</h3>
            <Button
                variant='primary'
                size='medium'
                disabled={disabled || (!teamAdmins?.length && user?.InternalUser) || teamAdminsIsFetching}
                onClick={onAdd}
                className='d-flex align-items-center'
            >
                {teamAdminsIsFetching ? (
                    <Spinner size='small' color='white' className='mr-2' />
                ) : (
                    <FontAwesomeIcon icon={faUserPlus} className='mr-2' />
                )}
                <span>{addButtonLabel}</span>
            </Button>
        </div>
    );
}
