import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ClientSelectionSlice } from '../../common/models/ReduxSlices';
import type { ClientSelectionData, ClientService } from '../../common/models/ResponseData';

const initialState: ClientSelectionSlice = {
    clientSelection: null,
    initialClientSelection: null, // Used for unrestricted
    applyClicked: null
};

const clientSelectionSlice = createSlice({
    name: 'clientSelection',
    initialState,
    reducers: {
        setIsApplyClicked: (state, action: PayloadAction<boolean>) => {
            state.applyClicked = action.payload;
        },
        setClientSelectionCountry: (state, action: PayloadAction<'us' | 'ca'>) => {
            if (!state.clientSelection) return;

            state.clientSelection.Countries = state.clientSelection?.Countries.map((c) => ({
                ...c,
                IsSelected: c.Code === action.payload
            }));
        },
        setClientSelectionServices: (state, action: PayloadAction<ClientService[]>) => {
            if (!state.clientSelection) return;

            state.clientSelection.ClientServices = action.payload;
        },
        setClientSelectionSettings: (state, action: PayloadAction<ClientSelectionData['ClientServiceSelection']>) => {
            state.clientSelection = action.payload;
            state.initialClientSelection = action.payload;
        },
        setClientSelectionSearch: (state, action: PayloadAction<ClientService[]>) => {
            if (!state.clientSelection?.ClientServices) return;

            const oldSelected = state.clientSelection.ClientServices.filter((clientService) => clientService.IsSelected);
            const newClients = action.payload;

            const uniqueArr = [...oldSelected, ...newClients].reduce<ClientService[]>((acc, item) => {
                if (!acc.some((clientService) => clientService.FullNumber === item.FullNumber)) {
                    acc.push(item);
                }
                return acc;
            }, []);

            state.clientSelection.ClientServices = uniqueArr;
        },
        resetChooser: () => initialState,
        resetClientSelectionState: (state) => {
            state.clientSelection = state.initialClientSelection;
        }
    }
});

export const {
    setClientSelectionCountry,
    setClientSelectionServices,
    setIsApplyClicked,
    setClientSelectionSettings,
    setClientSelectionSearch,
    resetChooser,
    resetClientSelectionState
} = clientSelectionSlice.actions;

export default clientSelectionSlice.reducer;
