import $ from 'jquery';
import { Component } from 'react';
import { withRouter } from 'react-router';
import toastr from 'toastr';
import axios from '../../../axios-mlp';
import ImagingDropDown from './ImagingDropdown';
import styles from './PreviewDocument.module.css';

class PreviewDocument extends Component {
    static defaultProps = {
        includeEntrySelection: false
    };

    state = { documentUrl: '' };

    componentDidMount() {
        const selectedDocument = this.getSelectedDocument();
        if (selectedDocument) {
            this.getPublicUrl(selectedDocument);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.data && this.props.data !== prevProps.data) ||
            (this.props.entryData && this.props.entryData !== prevProps.entryData)
        ) {
            const selectedDocument = this.getSelectedDocument();
            this.getPublicUrl(selectedDocument);
        }
    }

    getPublicUrl = (document) => {
        $('#overlay').show();
        if (document.publicDocumentUrl === undefined) {
            const postUrl = '/Imaging/' + this.props.getPublicDocUrl + '/?url=' + document.documentUrl;
            axios
                .post(postUrl)
                .then((response) => {
                    this.setState({ documentUrl: response.data.result });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data && error.response.data.Message) {
                            if (error.response.status === 400) {
                                toastr.warning(error.response.data.Message);
                            } else {
                                toastr.error(error.response.data.Message);
                            }
                        } else if (error.response.status !== 401 && error.response.status !== 403) {
                            this.props.history.push('/BasicError');
                        }
                    }
                })
                .finally(() => {
                    $('#overlay').hide();
                });
        }
    };

    getSelectedEntry = () => {
        if (this.props.entryData) {
            const selectedEntry = this.props.entryData.find((x) => x.isSelected);
            return selectedEntry;
        } else {
            return undefined;
        }
    };

    getSelectedDocument = () => {
        const selectedEntry = this.getSelectedEntry();
        let selectedDocument;
        if (selectedEntry) {
            selectedDocument = selectedEntry.documents.find((x) => x.isSelected);
        } else {
            selectedDocument = this.props.data.find((x) => x.isSelected);
        }
        return selectedDocument;
    };

    getDocumentSelectSection = () => {
        const groupId = this.props.data[0].parentGroupId.toString();
        return (
            <div className='col-sm-12'>
                <ImagingDropDown
                    titleAttribute={'isDocTitleAttribute'}
                    options={this.props.data}
                    valueField='pid'
                    onItemChanged={this.props.selectAction}
                    name={groupId}
                />
            </div>
        );
    };

    getEntrySelectSection = () => {
        const selectedEntry = this.props.entryData.find((x) => x.isSelected);

        const groupId = selectedEntry.documents[0].parentGroupId.toString();

        return (
            <>
                <div className={`col-sm-12 ${styles.margin_top_1}`}>
                    <ImagingDropDown
                        titleAttribute={'isGroupTitleAttribute'}
                        options={this.props.entryData}
                        valueField='groupId'
                        onItemChanged={this.props.entrySelectAction}
                    />
                </div>
                <div className={`col-sm-12 ${styles.margin_top_1}`}>
                    <ImagingDropDown
                        titleAttribute={'isDocTitleAttribute'}
                        options={selectedEntry.documents}
                        valueField='pid'
                        name={groupId}
                        onItemChanged={this.props.selectAction}
                    />
                </div>
            </>
        );
    };

    render() {
        // const root = window.location.protocol + '//' + document.location.host;
        const publicUrl = this.state.documentUrl;
        // const viewerUrl = root + '/Static/PDFViewer/web/viewer.html?file=';
        // const fileUrl = encodeURIComponent(webAPIbaseURL + '/Imaging/PrintPage/?' + publicUrl);

        var dropdownSection = this.props.includeEntrySelection ? this.getEntrySelectSection() : this.getDocumentSelectSection();

        return (
            <div>
                {dropdownSection}
                {publicUrl && (
                    <div className={`col-sm-12 ${styles.height_800}`}>
                        <iframe width='100%' height='100%' frameBorder='0' title='Document preview' src={publicUrl} />
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(PreviewDocument);
