import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { useCallback, useMemo } from 'react';
import { usePortalDispatch, usePortalSelector } from '../../../../redux/hooks';
import { setClientSelectionServices } from '../../../../redux/reducers/clientSelectionReducer';
import { addToast } from '../../../../redux/reducers/toastSlice';
import useTranslation from '../../../hooks/useTranslation';
import Spinner from '../../Spinner';
import type { ClientChooserRow, ClientChooserRowOption } from '../ClientChooser';
import { useClientChooserContext } from '../hooks';
import { convertSelectedClients } from '../utils';
import classes from './SelectionOptions.module.css';

export default function SelectionOptions() {
    const translate = useTranslation();

    const dispatch = usePortalDispatch();
    const { clientSelection } = usePortalSelector((state) => state.clientSelection);

    const { searchIsLoading, isUnrestricted, searchQuery, selectedCountryCode } = useClientChooserContext();

    const clientSelectionUS = clientSelection?.ClientServices.filter(({ CountryCode }) => CountryCode === 'us');
    const clientSelectionCA = clientSelection?.ClientServices.filter(({ CountryCode }) => CountryCode === 'ca');
    const displayClients = {
        ca: clientSelectionCA,
        us: clientSelectionUS
    }[selectedCountryCode || 'ca'];
    const searchedDisplayClients = useMemo(
        () =>
            displayClients?.filter(
                ({ Name, FullNumber, IsSelected }) =>
                    Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    FullNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    IsSelected
            ) || [],
        [displayClients, searchQuery]
    );
    const clientsSelectedLabel = translate(
        'ClientsSelected_Label',
        displayClients?.filter(({ IsSelected }) => IsSelected).length,
        isUnrestricted ? displayClients?.length : searchedDisplayClients?.length
    );
    const selectedClientsAmount = displayClients?.filter(({ IsSelected }) => IsSelected).length || 0;

    // Are any clients selected at all?
    const checkAllAnySelection = () => {
        if (!displayClients) return false;

        const selectedAmount = displayClients.filter(({ IsSelected }) => IsSelected).length;

        return selectedAmount <= displayClients.length && selectedAmount >= 1;
    };

    // Are any clients selected but not all?
    const checkAllPartialSelection = () => {
        if (!displayClients) return false;

        const selectedAmount = displayClients.filter(({ IsSelected }) => IsSelected).length;

        return selectedAmount < displayClients.length && selectedAmount >= 1;
    };

    // Are any client options selected at all?
    const checkClientAnySelection = (client: ClientChooserRow) => {
        if (!client.options) return false;

        const selectedAmount = client.options.filter(({ selected }) => selected).length;
        return selectedAmount >= 1;
    };

    // Are some client options selected but not all?
    const checkClientPartialSelection = (client: ClientChooserRow) => {
        if (!client.options) return false;

        const selectedAmount = client.options.filter(({ selected }) => selected).length;
        return selectedAmount < client.options.length && selectedAmount >= 1;
    };

    // Toggle entire client
    const toggleClient = useCallback(
        (client: ClientChooserRow) => {
            if (!clientSelection?.ClientServices || !client.options) return;

            // Limit for amount of clients selected at once
            if (
                selectedClientsAmount + client.options.length > (clientSelection?.MaxSelectedServicesPerCountry || 250) &&
                !checkClientAnySelection(client)
            ) {
                dispatch(
                    addToast({
                        message: translate('ClientSelectionLimitWarning_Label', clientSelection?.MaxSelectedServicesPerCountry || 250),
                        type: 'error'
                    })
                );
                return;
            }

            const newClients = [...clientSelection?.ClientServices];

            const selectedAmount = client.options.filter(({ selected }) => selected).length;
            const nextValue = selectedAmount === client.options.length ? false : true;

            const updatedClients = newClients.map((country) => ({
                ...country,
                IsSelected: country.Number === client.number ? nextValue : country.IsSelected
            }));

            dispatch(setClientSelectionServices(updatedClients));
        },
        [clientSelection?.ClientServices, clientSelection?.MaxSelectedServicesPerCountry, dispatch, selectedClientsAmount, translate]
    );

    // Toggle one client option
    const toggleOption = (client: ClientChooserRow, option: ClientChooserRowOption) => {
        if (!clientSelection?.ClientServices || !client.options) return;

        // Limit for amount of clients selected at once
        if (
            selectedClientsAmount >= (clientSelection?.MaxSelectedServicesPerCountry || 250) &&
            !client.options.find((o) => o.fullNumber === option.fullNumber)?.selected
        ) {
            dispatch(
                addToast({
                    message: translate('ClientSelectionLimitWarning_Label', clientSelection?.MaxSelectedServicesPerCountry || 250),
                    type: 'error'
                })
            );
            return;
        }

        const newClients = [...clientSelection?.ClientServices];

        const updatedClients = newClients.map((country) => ({
            ...country,
            IsSelected: country.FullNumber === option.fullNumber ? !country.IsSelected : country.IsSelected
        }));

        dispatch(setClientSelectionServices(updatedClients));
    };

    const selectAll = () => {
        if (!displayClients || !clientSelection?.ClientServices) return false;

        const toBeSelectedClients = isUnrestricted
            ? displayClients
            : displayClients.filter(
                  ({ Name, FullNumber, IsSelected }) =>
                      Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      FullNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      IsSelected
              );

        // Limit for amount of clients selected at once
        if (toBeSelectedClients.length > (clientSelection?.MaxSelectedServicesPerCountry || 250)) {
            dispatch(
                addToast({
                    message: translate('ClientSelectionLimitWarning_Label', clientSelection?.MaxSelectedServicesPerCountry || 250),
                    type: 'error'
                })
            );
            return;
        }

        const newClients = [...clientSelection?.ClientServices];

        const selectedAmount = toBeSelectedClients.filter(({ IsSelected }) => IsSelected).length;
        const nextValue = selectedAmount === toBeSelectedClients.length ? false : true;

        const updatedSearched = searchedDisplayClients.map((country) => ({
            ...country,
            IsSelected: nextValue
        }));

        const updatedClients = isUnrestricted
            ? newClients.map((country) => ({ ...country, IsSelected: nextValue }))
            : newClients.map((country) => {
                  const exists = updatedSearched.find(({ FullNumber }) => FullNumber === country.FullNumber);

                  return {
                      ...country,
                      IsSelected: exists ? exists.IsSelected : country.IsSelected
                  };
              });

        dispatch(setClientSelectionServices(updatedClients));
    };

    const deselectAll = () => {
        if (!displayClients || !clientSelection?.ClientServices) return false;

        const newClients = [...clientSelection?.ClientServices];

        const updatedClients = newClients.map((country) => ({
            ...country,
            IsSelected: country.CountryCode === selectedCountryCode ? false : country.IsSelected
        }));

        dispatch(setClientSelectionServices(updatedClients));
    };

    const clientsSelectedLabelSplit = typeof clientsSelectedLabel === 'string' ? clientsSelectedLabel?.split(' ') : [];

    const convertedDisplayClients = useMemo(() => {
        const result = convertSelectedClients((isUnrestricted ? displayClients : searchedDisplayClients) || [])?.sort(
            (a, b) => parseInt(a.number) - parseInt(b.number)
        );
        return result;
    }, [displayClients, isUnrestricted, searchedDisplayClients]);

    return (
        <div className='border-bottom bg-gray-200 flex-fill px-4 py-2 overflow-auto position-relative'>
            <div className='d-flex border-bottom py-2'>
                <div className='custom-control custom-checkbox'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id='customCheck1'
                        onChange={selectAll}
                        checked={checkAllAnySelection()}
                    />
                    <label className={clsx('custom-control-label', checkAllPartialSelection() && 'indeterminate')} htmlFor='customCheck1'>
                        {translate('NotificationsSelectAllNotifications_Label')}
                    </label>
                </div>
                <button type='button' className={clsx('btn p-0 breadcrumb-item ml-3', classes['deselect-link'])} onClick={deselectAll}>
                    {translate('NotificationsDeselectAllNotifications_Label')}
                </button>
                <span className='flex-grow-1 text-right'>
                    {<b>{clientsSelectedLabelSplit?.[0]}</b>} {clientsSelectedLabelSplit?.[1]} {clientsSelectedLabelSplit?.[2]}
                </span>
            </div>
            <div className='accordion border-bottom' id='clients-accordion'>
                {convertedDisplayClients?.map((client, clientIndex) => (
                    <div className='accordion-item border-bottom py-2' key={clientIndex}>
                        <div className='d-flex'>
                            <div className='custom-control custom-checkbox'>
                                <input
                                    type='checkbox'
                                    className={clsx('custom-control-input', checkClientPartialSelection(client) && 'indeterminate')}
                                    id={`client-check-${clientIndex}`}
                                    checked={checkClientAnySelection(client)}
                                    onChange={toggleClient.bind(null, client)}
                                />
                                <label
                                    className={clsx('custom-control-label', checkClientPartialSelection(client) && 'indeterminate')}
                                    htmlFor={`client-check-${clientIndex}`}
                                />
                            </div>
                            <button
                                type='button'
                                className='btn p-0 d-flex flex-1 justify-content-between align-items-center collapsed font-weight-normal'
                                data-toggle='collapse'
                                data-target={`#collapse-${clientIndex}`}
                                aria-expanded='false'
                                aria-controls={`#collapse-${clientIndex}`}
                            >
                                <span>{client.number + ' - ' + client.name}</span>
                                <div className='chevron-down'>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                            </button>
                        </div>
                        <div id={`collapse-${clientIndex}`} className='collapse'>
                            <div className='ml-4 mt-2'>
                                {client.options.map((option, optionIndex) => (
                                    <div key={`${clientIndex}-${optionIndex}`} className='py-1'>
                                        <div className='custom-control custom-checkbox'>
                                            <input
                                                type='checkbox'
                                                className='custom-control-input'
                                                id={`client-option-check-${clientIndex}-${optionIndex}`}
                                                checked={option.selected}
                                                onChange={toggleOption.bind(null, client, option)}
                                            />
                                            <label
                                                className='custom-control-label'
                                                htmlFor={`client-option-check-${clientIndex}-${optionIndex}`}
                                            >
                                                {option.caption + ' - ' + option.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {searchIsLoading && (
                <div
                    className={clsx(
                        'position-absolute w-100 h-100 d-flex align-items-center justify-content-center',
                        classes['search-loading']
                    )}
                >
                    <Spinner />
                </div>
            )}
        </div>
    );
}
