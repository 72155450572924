import { useEffect } from 'react';
import ToastWithPortal from '../../../../common/features/ToastWithPortal';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useSubscribersDispatch, useSubscribersSelector } from '../../redux/hooks';
import { clearNewSubscribers, resetSubscribe, resetSubscriptionUpdate, resetUnsubscribe } from '../../redux/reducers/subscribersSlice';

const TIMEOUT = 3000;

const SubscriptionStatusAlerts = () => {
    const { isLoading, subscribe, subscriptionUpdate, unsubscribe, subscribers } = useSubscribersSelector((state) => state.subscribers);
    const translate = useTranslation();
    const dispatch = useSubscribersDispatch();

    // Reset all action inside "subscribers" slice on unmount
    useEffect(() => {
        return () => {
            dispatch(resetSubscriptionUpdate());
            dispatch(resetSubscribe());
            dispatch(resetUnsubscribe());
        };
    }, [dispatch]);

    const debounce = useDebounce(TIMEOUT);

    useEffect(() => {
        debounce(() => {
            dispatch(clearNewSubscribers());
        });
    }, [subscribers?.length, dispatch, debounce]);

    if (!isLoading && !subscriptionUpdate.isLoading && !subscribe.isLoading && !unsubscribe.isLoading) {
        return (
            <>
                {/* Status alert for subscription update */}
                {subscriptionUpdate.data &&
                    !subscriptionUpdate.error &&
                    subscriptionUpdate.alertMessage &&
                    subscriptionUpdate.alertType && (
                        <ToastWithPortal message={translate(subscriptionUpdate.alertMessage)} type={subscriptionUpdate.alertType} />
                    )}
            </>
        );
    } else {
        return null;
    }
};

export default SubscriptionStatusAlerts;
