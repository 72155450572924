import { faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import useDropdownToggle from '../hooks/useDropdownToggle';
import DropdownMenuButton from './DropdownMenuButton';

interface ShipmentDataSourceProps {
    translations: { [key: string]: string };
    dataSource: string;
    shipmentDataSourceConstants: { [key: string]: string };
    handleDataSource: (index: number) => void;
}

export const ShipmentDataSource: React.FC<ShipmentDataSourceProps> = ({
    translations,
    dataSource,
    shipmentDataSourceConstants,
    handleDataSource
}) => {
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const selectedStyle = { border: '1.5px solid #60a5eb', borderRadius: 6, background: '#ecf5fd', color: '#0070e0' };
    const deSelectedStyle = { border: '1.5px solid #dfe6ed', borderRadius: 6, background: 'white', color: '#325073' };

    const handleSelection = (index: number) => {
        handleDataSource(index);
        handleToggle();
    };

    const getCicrleIcon = (isSelected: boolean) => {
        return isSelected ? (
            <span className='mr-3'>
                <FontAwesomeIcon icon={faCheckCircle} size='2x' style={{ color: '#0070e0' }} />
            </span>
        ) : (
            <div className='mr-3' style={{ border: '1.5px solid #dfe6ed', borderRadius: '50%', height: '30px', width: '30px' }} />
        );
    };

    return (
        <DropdownMenuButton
            inlineIcon={faClock}
            iconWidth='16px'
            iconHeight='16px'
            btnClassNameSecondary='align-items-center dropdown-toggle'
            ref={dropdownMenuRef}
            divClassName='mr-1 mb-1 mb-md-0'
            label={dataSource === shipmentDataSourceConstants.us ? translations.RealTimeData : translations.CompleteEntryData}
            isOpen={isOpen}
            onClick={handleToggle}
        >
            <div className='p-3'>
                <div
                    className='d-flex align-items-center pl-2 pr-2 mb-2'
                    style={dataSource === shipmentDataSourceConstants.us ? selectedStyle : deSelectedStyle}
                    onClick={() => handleSelection(0)}
                >
                    {getCicrleIcon(dataSource === shipmentDataSourceConstants.us)}
                    <div className='mt-2 ml-2 mb-2 d-flex flex-column'>
                        <strong>{translations.RealTimeData}</strong>
                        <span>{translations.MonitorClearanceStatuses}</span>
                        <span>{translations.UpdatedRealTime}</span>
                    </div>
                </div>
                <div
                    className='d-flex align-items-center pl-2 pr-2'
                    style={dataSource === shipmentDataSourceConstants.ca ? selectedStyle : deSelectedStyle}
                    onClick={() => handleSelection(1)}
                >
                    {getCicrleIcon(dataSource === shipmentDataSourceConstants.ca)}
                    <div className='mt-2 ml-2 mb-2 d-flex flex-column'>
                        <strong>{translations.CompleteEntryData}</strong>
                        <span>{translations.ComprehensiveClearanceData}</span>
                        <span>{translations.UpdatedPeriodically}</span>
                    </div>
                </div>
            </div>
        </DropdownMenuButton>
    );
};
