import { clsx } from 'clsx';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Badge, { BadgeProps } from '../../../common/features/Badge';
import CloseButton from '../../../common/features/CloseButton';
import { DownloadLobbyItem } from '../../../common/models/ReduxSlices';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { removeUnreadLobbyItem } from '../../../redux/reducers/alertsSlice';
import { packageSources, PortalRoutes } from '../../constants/constants-portal';
import { createDateForAlert } from '../../functions/parser';
import useTranslationHydration from '../../hooks/useTranslationHydration';
import Button from '../Button';
import ToastWrapper from '../Wrappers/ToastWrapper';
import classes from './DownloadLobbyToastCard.module.css';

const TIMEOUT = 8000;

const DownloadLobbyToastCard: React.FC<DownloadLobbyItem> = ({
    inProgress,
    imageUrl,
    createdOn,
    documentCount,
    entryNumbers,
    packageSource,
    hasError,
    packageId
}) => {
    const { user } = usePortalSelector((state) => state.hydration);
    const { languageSelected } = usePortalSelector((state) => state.language);
    const dispatch = usePortalDispatch();

    const [isVisible, setIsVisible] = useState(true);
    const translate = useTranslationHydration();

    const timeout = user?.PortalTimeoutLong || TIMEOUT;

    // Close alert and remove from unread after clicking the "close" button
    const closeToast = () => {
        if (!inProgress) {
            dispatch(removeUnreadLobbyItem(packageId));
        }
        setIsVisible(false);
    };

    const titleLabel = hasError
        ? translate('DownloadLobbyErrorTitle_Label')
        : inProgress
        ? translate('DownloadLobbyTitle_Label')
        : translate('DownloadReady_Label');

    const location = useLocation();
    const isCADPage = location.pathname.startsWith(PortalRoutes.completeEntryData);
    const isClearancesPage = location.pathname.startsWith(PortalRoutes.clearances);

    const descLabel = hasError
        ? translate('DownloadLobbyDesc4_Label') // Error desc
        : inProgress
        ? translate((isCADPage || isClearancesPage) && !documentCount ? undefined : 'DownloadLobbyDesc_Label') // Loading
        : entryNumbers.length
        ? translate('DownloadLobbyDesc2_Label', entryNumbers.length, documentCount) // Documents download
        : translate('DownloadLobbyDesc3_Label'); // Header/Line download

    const badgeInfo: { type: BadgeProps['type']; text: string } = hasError
        ? { type: 'danger', text: translate('ProcessingError_Label') }
        : inProgress
        ? { type: 'info', text: translate('ProcessingRequest_Label') }
        : { type: 'success', text: translate('ProcessingComplete_Label') };

    if (!isVisible) return null;

    return (
        <ToastWrapper
            type='on-hold-alert'
            timeout={timeout}
            onClose={() => {
                if (!inProgress) {
                    dispatch(removeUnreadLobbyItem(packageId));
                }
            }}
        >
            <div className='toast-body alert-toasts d-flex flex-column align-items-start p-4 justify-content-between w-100 position-relative'>
                <div className='d-flex justify-content-between w-100'>
                    {/* Badge */}
                    <Badge className='mb-2' type={badgeInfo.type}>
                        {badgeInfo.text}
                    </Badge>
                    {/* Date and Close button */}
                    <div className='d-flex ml-2 align-items-start'>
                        <p className={clsx('mb-1', classes.date)}>{createDateForAlert(createdOn, languageSelected?.locale)}</p>
                        <CloseButton type='toast' className={clsx('ml-1 mt-n1', classes['btn-close'])} onClick={closeToast} />
                    </div>
                </div>
                {/* Title */}
                <p className='toast-title w-100 mb-1'>
                    <strong>{titleLabel}</strong>
                </p>
                {/* Description */}
                <p className='alert-card-desc mb-0'>
                    {(packageSource === packageSources.Clearances || packageSource === packageSources.CAD) && !hasError ? '' : descLabel}
                </p>
                {/* Download button */}
                {hasError ? null : inProgress ? (
                    <Button variant='tertiary-blue' className='mt-3' onClick={closeToast}>
                        {translate('DownloadLobbyButton_Label')}
                    </Button>
                ) : (
                    <a href={imageUrl} target='_blank' rel='noopener noreferrer' className='btn btn-tertiary-blue mt-3'>
                        {translate('DownloadButton_Label')}
                    </a>
                )}
            </div>
        </ToastWrapper>
    );
};

export default DownloadLobbyToastCard;
