import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '../../../../../common/hooks/useTranslation';
import useTranslationHydration from '../../../../../common/hooks/useTranslationHydration';
import { docTypeCAHashMap, docTypeHashMap } from '../../../common/constants/constants-documents';
import { FileData } from '../../../common/models/ImagingModel';
import './styles.css';
interface dropDownAndButtonProps {
    isMobile: boolean;
    arrayOfFiles: FileData[];
    setIndex: (updateFunction: (index: number) => number) => void;
    titleToBeDisplayed: string;
    buttonText: string;
    entryNumberIndex: string;
    indexOfDocument: number;
    type: 'entry' | 'document';
    country: string;
    isClearances: boolean;
}
const DropDownAndButton: React.FC<dropDownAndButtonProps> = ({
    arrayOfFiles,
    isMobile,
    setIndex,
    titleToBeDisplayed,
    buttonText,
    entryNumberIndex,
    indexOfDocument,
    type,
    country,
    isClearances
}) => {
    const translateHydration = useTranslationHydration();
    const translate = useTranslation();

    const uniqueEntries = new Set();

    // Filter arrayOfFiles to include only unique entryNumberIndex values
    const uniqueFiles = arrayOfFiles.filter((x: FileData) => {
        const entryValue = x[entryNumberIndex];
        if (uniqueEntries.has(entryValue)) {
            return false; // Skip if the entryNumberIndex value is already in the set
        } else {
            uniqueEntries.add(entryValue);
            return true; // Include if the value is new
        }
    });
    //This function renders doc types labels based on page where the component is called
    const renderTranslatedDocTypes = (f: FileData) => {
        return `${translate(
            isClearances
                ? country === 'us'
                    ? docTypeHashMap[f._metadataFormat ?? ''].replaceAll(' ', '') + '_Label'
                    : docTypeCAHashMap[f._metadataFormat ?? ''].replaceAll(' ', '') + '_Label'
                : f._metadataFormat?.replaceAll(' ', '') + '_Label'
        )}`;
    };
    return (
        <>
            {!isMobile && (
                <div className='input-group-prepend d-none d-md-inline-block'>
                    <span
                        style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
                        className='input-group-text custom-button'
                        id='basic-addon1'
                    >
                        <small className='font-weight-bold text-uppercase'>{buttonText}</small>
                    </span>
                </div>
            )}
            <div
                className='dropdown'
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    ...(window.innerWidth < 400 ? { paddingRight: '20px' } : {})
                }}
            >
                <button
                    className='btn btn-tertiary dropdown-toggle custom-button'
                    type='button'
                    id='dropdownMenuButton'
                    style={{
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                        height: window.innerWidth > 400 ? '40px' : '25px',
                        ...(window.innerWidth < 400 ? { fontSize: '16px' } : {})
                    }}
                    data-toggle='dropdown'
                >
                    {titleToBeDisplayed}
                </button>

                {type === 'entry' && (
                    <div
                        className='dropdown-menu compare_documents_dropdown override-transform'
                        style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                        aria-labelledby='dropdownMenuButton'
                    >
                        <span className='dropdown-title d-flex justify-content-start align-items-center font-weight-bold text-nowrap'>
                            <FontAwesomeIcon icon={faFolder} className='mr-2 icon' />
                            {translateHydration('SelectAnEntry_Label')}
                        </span>
                        {uniqueFiles
                            .sort((f: FileData, g: FileData) => {
                                const dateF = (f._folderDate ?? '').split('T')[0];
                                const dateG = (g._folderDate ?? '').split('T')[0];
                                return dateF.localeCompare(dateG);
                            })
                            .map((x: FileData) => {
                                return (
                                    <button
                                        key={x._fileS3Path}
                                        onClick={() => {
                                            const cmp = arrayOfFiles.findIndex((file) => file._fileS3Path === x._fileS3Path);

                                            if (cmp !== -1 && cmp !== indexOfDocument) setIndex(() => cmp);
                                        }}
                                        className='dropdown-item d-flex justify-content-between align-items-stretch'
                                    >
                                        <div className='mr-3 d-flex align-items-center'>{x[entryNumberIndex]}</div>
                                    </button>
                                );
                            })}
                    </div>
                )}

                {type === 'document' && (
                    <div
                        className='dropdown-menu'
                        style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                        aria-labelledby='dropdownMenuButton'
                    >
                        <span className='dropdown-title d-flex justify-content-start align-items-center font-weight-bold text-nowrap'>
                            <FontAwesomeIcon icon={faFile} className='mr-2 icon' />
                            {translateHydration('SelectADocument_Label')}
                        </span>
                        {arrayOfFiles
                            .filter((x: FileData) => {
                                return x[entryNumberIndex] === arrayOfFiles[indexOfDocument][entryNumberIndex];
                            })
                            .map((f: FileData) => {
                                return (
                                    <button
                                        key={f._fileS3Path}
                                        onClick={() => {
                                            const cmp = arrayOfFiles.findIndex((file) => file._fileS3Path === f._fileS3Path);

                                            if (cmp !== -1 && cmp !== indexOfDocument) setIndex(() => cmp);
                                        }}
                                        className='dropdown-item d-flex justify-content-between align-items-stretch'
                                    >
                                        <div className='mr-3 d-flex align-items-center'>{`${renderTranslatedDocTypes(
                                            f
                                        )} ${f._imageDate?.replaceAll('-', '/')}`}</div>
                                    </button>
                                );
                            })}
                    </div>
                )}
            </div>
        </>
    );
};
export default DropDownAndButton;
