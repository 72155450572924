import { Link } from 'react-router-dom';
import useTranslation from '../../../../../common/hooks/useTranslation';

export default function RequestUpdateDropdown() {
    const translate = useTranslation();

    const menuItems = [
        translate('RequestAnUpdateYourName_Label'),
        translate('RequestAnUpdateCompanyName_Label'),
        translate('RequestAnUpdateContactInfo_Label'),
        translate('RequestAnUpdateUserRole_Label')
    ];

    return (
        <div className='dropdown'>
            <button
                className='btn btn-secondary btn-sm btn-alert-info dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {translate('RequestAnUpdate_Label')}
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <div className='dropdown-heading dropdown-header d-flex justify-content-between pb-3'>
                    <div className='name'>
                        <p className='mb-0'> {translate('RequestAnUpdateWhatInformation_Label')}</p>
                    </div>
                </div>
                {menuItems.map((item, index) => (
                    <Link
                        key={index}
                        to={{ pathname: '/Profile/RequestUpdate', state: { requestType: item } }}
                        className='dropdown-item d-flex justify-content-between align-items-stretch'
                    >
                        <div className='mr-3 d-flex align-items-center'>{item}</div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
