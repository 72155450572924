import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';

interface DownloadAllLinkProps {
    linkText: string;
    limitText: string;
    handleDownload: () => void;
}

const DownloadAllLink: React.FC<DownloadAllLinkProps> = ({ handleDownload, limitText, linkText }) => {
    return (
        <div>
            <Button variant='link' onClick={() => handleDownload()}>
                {linkText}
            </Button>
            {limitText}
            <span className='ml-2 mr-2'>
                <FontAwesomeIcon icon={faDownload} />
            </span>
        </div>
    );
};

export default DownloadAllLink;
