import React from 'react';
import styles from './UploadPreviewTemplate.module.css';

const UploadPreviewTemplate = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} id='preview-template' className={styles.d_none}>
            <div className='dz-preview dz-file-preview'>
                <div className='dz-details'>
                    <div className='dz-filename'>
                        <span data-dz-name>{props.filename}</span>
                    </div>
                    <i className='fa fa-file-text fa-5x file-icon'></i>
                    <div className='dz-size' data-dz-size></div>
                </div>
            </div>
        </div>
    );
});

export default UploadPreviewTemplate;
