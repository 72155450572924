import React, { Dispatch, useCallback, useEffect, useRef } from 'react';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../../common/constants/constants-portal';
import Button from '../../../../../../common/features/Button';
import NotificationsCheckboxes from '../../../../../../common/features/NotificationsCheckboxes';
import { getCountryFromClientNumber } from '../../../../../../common/functions/utils';
import useGTMEventTracking from '../../../../../../common/hooks/useGTMEventTracking';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { useSubscribersSelector } from '../../../../redux/hooks';

interface Props {
    notificationsCA: any;
    notificationsUS: any;
    setNotificationsUS: any;
    setNotificationsCA: any;

    step: number;
    setStep: Dispatch<number>;
    handleSubmit: () => void;
}

const SubscriberNotifications: React.FC<Props> = ({
    notificationsCA,
    notificationsUS,
    setNotificationsCA,
    setNotificationsUS,
    step,
    setStep,
    handleSubmit
}) => {
    const { selectedClients, newSubscribers } = useSubscribersSelector((state) => state.subscribers);
    const translate = useTranslation();
    const addSubscriberClearancesConfirmRef = useRef(null);
    useGTMEventTracking('Confirm_Subscriber_SUB_CPEvt', { location: 'Subscribers' }, addSubscriberClearancesConfirmRef);
    const hasCountry = useCallback(
        (c: 'ca' | 'us') => {
            if (!selectedClients) return false;
            return (
                Object.keys(selectedClients).filter((number) => {
                    const country = getCountryFromClientNumber(number);
                    return country === c;
                }).length > 0
            );
        },
        [selectedClients]
    );
    const hasSelected = (notifications: any[]) =>
        Object.values({ ...notifications }).filter((val) => {
            return val;
        }).length > 0;

    const updateNotificationsUS = (key: string, value: boolean) => {
        setNotificationsUS({ ...notificationsUS, [key]: value });
    };
    const updateNotificationsCA = (key: string, value: boolean) => {
        setNotificationsCA({ ...notificationsCA, [key]: value });
    };

    useEffect(() => {
        if (step !== 3) return;

        if (!hasCountry('ca')) setNotificationsCA(MILESTONES_CA);
        if (!hasCountry('us')) setNotificationsUS(MILESTONES_US);
    }, [step, hasCountry, setNotificationsCA, setNotificationsUS]);

    const isDisabled = (!hasSelected(notificationsCA) && hasCountry('ca')) || (!hasSelected(notificationsUS) && hasCountry('us'));

    return (
        <>
            <div className='modal-body d-flex flex-column align-items-start'>
                <div id='scrollTable' style={{ width: '100%' }}>
                    <div className='text-secondary mb-4'>{translate('NoticeMilestonesChange_Label')}</div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        {hasCountry('us') && (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                <NotificationsCheckboxes
                                    handleChange={updateNotificationsUS}
                                    setNotificationSettings={setNotificationsUS}
                                    notificationSettings={notificationsUS}
                                    country={[{ id: 'US' }]}
                                    addSubscriberModal={true}
                                    onHoldTitle=''
                                    onHoldLabel=''
                                />
                            </div>
                        )}
                        {hasCountry('ca') && (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                <NotificationsCheckboxes
                                    handleChange={updateNotificationsCA}
                                    setNotificationSettings={setNotificationsCA}
                                    notificationSettings={notificationsCA}
                                    country={[{ id: 'CA' }]}
                                    addSubscriberModal={true}
                                    onHoldTitle=''
                                    onHoldLabel=''
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='modal-footer justify-content-between'>
                <Button type='button' variant='tertiary-blue' className='btn-lg' onClick={() => setStep(2)}>
                    {translate('Back_Label')}
                </Button>

                <Button
                    type='button'
                    variant='primary'
                    className='btn-lg'
                    ref={addSubscriberClearancesConfirmRef}
                    onClick={() => handleSubmit()}
                    disabled={isDisabled}
                >
                    {translate('Add_Label', newSubscribers.length)}{' '}
                    {newSubscribers.length === 1
                        ? translate('SubscriberStepModalButton_Label')
                        : translate('SubscribersStepModalButton_Label')}
                </Button>
            </div>
        </>
    );
};

export default SubscriberNotifications;
