import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import {
    ACTIVE_FEATURE_SETUP_PENDING_STEPS,
    TEAM_MEMBER_STATUS_LABELS,
    TEAM_SEARCH_PARAM_KEYS
} from '../../../common/constants/constants-team';
import { useTeamMemberDetailsQuery } from '../../../redux/api/teamApi';

interface PendingModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type ActiveFeatureSetupPendingStepsKey = keyof typeof ACTIVE_FEATURE_SETUP_PENDING_STEPS;

export default function PendingModal({ isOpen, setIsOpen }: PendingModalProps) {
    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data } = useTeamMemberDetailsQuery(userId.toString());

    const teamMember = data?.Users?.[0];

    const translate = useTranslation();

    const handleClose = () => {
        setIsOpen(false);
    };

    const activePendingReasons = useMemo(() => {
        return data?.ParentServices?.reduce<string[]>((acc, service) => {
            const pendingClientServices = service.ClientServices.reduce<string[]>((acc, clientService) => {
                const pendingFeatures = clientService.Features.reduce<string[]>((acc, feature) => {
                    if (
                        feature.IsPending &&
                        feature.Name &&
                        ACTIVE_FEATURE_SETUP_PENDING_STEPS[feature.Name as ActiveFeatureSetupPendingStepsKey]
                    ) {
                        acc.push(feature.Name);
                    }
                    return acc;
                }, []);
                acc.push(...pendingFeatures);
                return acc;
            }, []);
            acc.push(...pendingClientServices);
            return acc;
        }, []);
    }, [data?.ParentServices]);

    if (!isOpen) return null;

    return (
        <ModalWrapper onEscape={handleClose}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className='modal-header '>
                        <h3 className='modal-title'>{teamMember?.Status && translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])}</h3>
                        <button
                            type='button'
                            className='modal-close btn btn-tertiary btn-lg'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div className='modal-body'>
                        <p className='note mb-3 notification-message'>{translate('PendingReason_Label')}</p>
                        {teamMember?.PendingSteps?.map((item, index) => (
                            <p key={index} className='note mb-0 notification-message'>
                                {translate(item.PendingReason, teamMember.Email, dayjs(item.DateStarted).format('MM/DD/YYYY'))}
                            </p>
                        )) ||
                            activePendingReasons?.map((item, index) => (
                                <p key={index} className='note mb-0 notification-message'>
                                    {translate(ACTIVE_FEATURE_SETUP_PENDING_STEPS[item as ActiveFeatureSetupPendingStepsKey])}
                                </p>
                            )) ||
                            translate('OtherReasonForPendingStatus_Label')}
                    </div>
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleClose}>
                            {translate('Close_Label')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
}
