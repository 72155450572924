import { useDispatch } from 'react-redux';
import DownloadAllLink from '../../../../../common/features/DownloadAllLink';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DownloadClearancesData } from '../../../redux/actions/clearancesDownload';
import { useClearancesSelector } from '../../../redux/hooks';

const clearanceListConfigParams = {
    PAGE_SIZE: 10000,
    START_ROW: 1
};

const DownloadAllResults = () => {
    const { dateRangeSelected: selectedDateRange, customDateRange } = useClearancesSelector((state) => state.dateRange);
    const { initialClientSelection } = useClearancesSelector((state) => state.clientSelection);
    const gridSettings = useClearancesSelector((state) => state.shipmentGridSettings);
    const searchFilter = useClearancesSelector((state) => state.searchFilter);
    const { user } = useClearancesSelector((state) => state.hydration);

    const translate = useTranslation();
    const dispatch = useDispatch();

    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);

    const getClearances = (pageSize: number, startRow: number) => {
        if (user?.ClientCaptionNumbers && country?.Code) {
            const params = {
                Status: null,
                Country: country?.Code.toUpperCase(),
                ClientCaptionNumbers: user?.ClientCaptionNumbers,
                PageSize: pageSize,
                OrderBy: gridSettings.columnSortFields,
                StartRow: startRow,
                ColumnFilters: gridSettings?.columnFilters,
                DateRange: { dateRangeSelected: selectedDateRange.id, customDateRange: customDateRange },
                AdvancedSearchFilters: { Tags: searchFilter.tags },
                ClearancesCreatedOnDate: user?.PortalClearancesCreatedOnMinDate ?? ''
            };

            dispatch(
                DownloadClearancesData({
                    CountryCode: country?.Code.toUpperCase(),
                    ReturnAllFields: true,
                    SearchModel: params,
                    Shipments: []
                })
            );
        }
    };

    return (
        <DownloadAllLink
            linkText={translate('DownloadAllClearances_Label')}
            limitText={translate('DownloadAllClearancesLimit_Label')}
            handleDownload={() => getClearances(clearanceListConfigParams.PAGE_SIZE, clearanceListConfigParams.START_ROW)}
        />
    );
};

export default DownloadAllResults;
