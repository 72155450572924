import { clsx } from 'clsx';
import useTabSelection from '../../hooks/useTabSelection';

interface TabPanelItemProps {
    id: string;
    className?: string;
    children: React.ReactNode;
}

const TabPanelItem: React.FC<TabPanelItemProps> = ({ id, className = '', children }) => {
    const { active } = useTabSelection({ id });

    return (
        <div
            className={clsx('tab-pane h-100 fade container-fluid-nopadding', className, active && 'show active')}
            id={id}
            role='tabpanel'
            aria-labelledby={`${id}-tab`}
        >
            {children}
        </div>
    );
};

export default TabPanelItem;
