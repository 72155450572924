import { clsx } from 'clsx';
import { useRef } from 'react';
import DropdownMenuButton from '../../../../../common/features/DropdownMenuButton';
import useDropdownToggle from '../../../../../common/hooks/useDropdownToggle';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DateTypesCaLabels, DateTypesUsLabels } from '../../../common/constants/constants-documents';
import { useDocumentsDispatch, useDocumentsSelector } from '../../../redux/hooks';
import {
    resetDataFilter,
    resetSearchResults,
    resetSelectedEntries,
    resetTypeFilter,
    setApplyDateRangeFilter,
    setApplyDateTypeFilter,
    setSearchBarOptions,
    updateDateFilters,
    updateDateType,
    updateSearchFilters,
    updateShouldSubmitImageSearch,
    updateSubmittedSearchKeywords
} from '../../../redux/reducers/imagingSlice';
import DateRangeFilter from './DateRangeFilter';

const DateDocumentsFilter = () => {
    const { initialClientSelection } = useDocumentsSelector((state) => state.clientSelection);
    const { dateRangeSelected } = useDocumentsSelector((state) => state.dateRange);

    const {
        dateTypesCaLabel,
        dateTypesUsLabel,
        dateRangeCaLabel,
        dateRangeUsLabel,
        customDateRangeCaEndLabel,
        customDateRangeCaStartLabel,
        customDateRangeUsEndLabel,
        customDateRangeUsStartLabel,
        applyDateRangeUsFilter,
        applyDateRangeCaFilter,
        startCaRangeDate,
        endCaRangeDate,
        searchKeywords,
        model
    } = useDocumentsSelector((state) => state.imaging);
    const country = initialClientSelection?.Countries.find((c) => c.IsSelected);
    const dateTypesLabels = country?.Code === 'us' ? DateTypesUsLabels : DateTypesCaLabels;

    const dropdownMenuRef = useRef(null);
    const dropdownNestedMenuRef = useRef(null);
    const dropdownDateRangeRef = useRef<HTMLDivElement>(null);
    const dispatch = useDocumentsDispatch();
    const translate = useTranslation();

    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const { isOpen: nestedIsOpen, handleToggle: nestedHandleToggle } = useDropdownToggle(dropdownNestedMenuRef);
    const { isOpen: dateRangeIsOpen, handleToggle: dateRangeHandleToggle } = useDropdownToggle(dropdownDateRangeRef);

    return (
        <DropdownMenuButton
            label={
                country?.Code === 'us'
                    ? customDateRangeUsStartLabel && customDateRangeUsEndLabel && applyDateRangeUsFilter
                        ? translate(dateRangeUsLabel, customDateRangeUsStartLabel, customDateRangeUsEndLabel)
                        : dateRangeUsLabel && applyDateRangeUsFilter
                        ? translate(dateRangeUsLabel)
                        : translate('Date_Label')
                    : customDateRangeCaStartLabel && customDateRangeCaEndLabel && applyDateRangeCaFilter
                    ? translate(dateRangeCaLabel, customDateRangeCaStartLabel, customDateRangeCaEndLabel)
                    : dateRangeCaLabel && applyDateRangeCaFilter
                    ? translate(dateRangeCaLabel)
                    : translate('Date_Label')
            }
            isOpen={isOpen}
            ref={isOpen && !nestedIsOpen && !dateRangeIsOpen ? dropdownMenuRef : null}
            onClick={handleToggle}
            btnClassName='btn btn-tertiary  btn-sm dropdown-toggle ml-1'
        >
            <>
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3 border-bottom font-weight-bold'>{translate('FilterByDate_Label')}</div>
                </div>
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3'>
                        <DropdownMenuButton
                            label={
                                country?.Code === 'us'
                                    ? translate(dateTypesUsLabel)
                                        ? translate(dateTypesUsLabel)
                                        : translate('SelectDateByType_Label')
                                    : translate(dateTypesCaLabel)
                                    ? translate(dateTypesCaLabel)
                                    : translate('SelectDateByType_Label')
                            }
                            isOpen={nestedIsOpen}
                            disabled={model && model.Filters ? false : true}
                            onClick={nestedHandleToggle}
                            ref={dropdownNestedMenuRef}
                            btnClassName='btn-sm dropdown-toggle mb-2 btn-block'
                            style={{ minWidth: '155px' }}
                            childrenDivClassName=''
                        >
                            {dateTypesLabels.map((item, index) => (
                                <div
                                    className={clsx(
                                        'dropdown-item',
                                        (country?.Code === 'us' ? dateTypesUsLabel : dateTypesCaLabel) === item.label && 'active'
                                    )}
                                    key={item.label}
                                    onClick={() => {
                                        nestedHandleToggle();
                                        if (model && model.Filters) {
                                            dispatch(
                                                updateDateType({
                                                    dateType: item.name,
                                                    attribute: item.attribute,
                                                    dateTypeLabel: item.label,
                                                    searchTypeCode: item.searchTypeCode
                                                })
                                            );
                                        }

                                        if (country?.Code === 'ca' && startCaRangeDate && endCaRangeDate) {
                                            dispatch(
                                                updateDateFilters({
                                                    startDate: startCaRangeDate,
                                                    endDate: endCaRangeDate,
                                                    attribute: item.attribute,
                                                    dateRangeLabel: dateRangeSelected.label
                                                })
                                            );
                                        }
                                    }}
                                    style={
                                        country?.Code === 'us'
                                            ? dateTypesUsLabel === item.label
                                                ? { backgroundColor: '#fff', cursor: 'pointer' }
                                                : { cursor: 'pointer' }
                                            : dateTypesCaLabel === item.label
                                            ? { backgroundColor: '#fff', cursor: 'pointer' }
                                            : { cursor: 'pointer' }
                                    }
                                >
                                    {translate(item.label)}
                                </div>
                            ))}
                        </DropdownMenuButton>
                        <DateRangeFilter
                            btnClass='btn-block'
                            onClick={dateRangeHandleToggle}
                            ref={dropdownDateRangeRef}
                            isOpen={dateRangeIsOpen}
                            handleToggle={dateRangeHandleToggle}
                        />
                    </div>
                </div>
                <div className='card-footer d-flex px-4 py-3'>
                    <button
                        type='button'
                        className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                        onClick={() => {
                            dispatch(resetDataFilter({ applyDateRangeUsFilter, applyDateRangeCaFilter }));
                            dispatch(resetTypeFilter({ applyDateRangeUsFilter, applyDateRangeCaFilter }));

                            handleToggle();
                        }}
                    >
                        {translate('Cancel_Label')}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                        disabled={
                            country?.Code === 'us'
                                ? !dateTypesUsLabel || dropdownDateRangeRef.current?.textContent === translate('SelectDateByRange_Label')
                                : !dateTypesCaLabel || dropdownDateRangeRef.current?.textContent === translate('SelectDateByRange_Label')
                        }
                        onClick={() => {
                            handleToggle();
                            const payload = {
                                value: searchKeywords,
                                country: country?.Code || 'us'
                            };
                            dispatch(resetSelectedEntries());
                            dispatch(updateSearchFilters(payload));
                            dispatch(setSearchBarOptions());
                            dispatch(updateSubmittedSearchKeywords(searchKeywords));
                            dispatch(updateShouldSubmitImageSearch(true));

                            dispatch(
                                setApplyDateRangeFilter({ country: country?.Code, usLabel: dateRangeUsLabel, caLabel: dateRangeCaLabel })
                            );
                            dispatch(
                                setApplyDateTypeFilter({
                                    country: country?.Code,
                                    usDataType: dateTypesUsLabel,
                                    caDataType: dateTypesCaLabel
                                })
                            );
                            dispatch(resetSearchResults());
                        }}
                    >
                        {translate('Apply_Label')}
                    </button>
                </div>
            </>
        </DropdownMenuButton>
    );
};
export default DateDocumentsFilter;
