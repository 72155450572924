import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { EllipsisSlice } from '../../common/models/ReduxSlices';

const initialState: EllipsisSlice = {
    ellipsisData: { customsInfoID: undefined, entryNo: undefined }
};

const ellipsisSlice = createSlice({
    name: 'ellipsisSlice',
    initialState,
    reducers: {
        setEllipsisEntryNo(state, action: PayloadAction<string>) {
            state.ellipsisData.entryNo = action.payload;
        },
        setInitialEllipsisState(state) {
            state.ellipsisData = initialState.ellipsisData;
        },
        setCustomsInfoId(state, action: PayloadAction<string>) {
            state.ellipsisData.customsInfoID = action.payload;
        }
    }
});

export const { setInitialEllipsisState, setEllipsisEntryNo, setCustomsInfoId } = ellipsisSlice.actions;

export default ellipsisSlice.reducer;
