import { useEffect, useState } from 'react';
import GridCaption from '../../../../common/features/Grid/GridCaption';
import useTranslation from '../../../../common/hooks/useTranslation';
import { STATUS_FILTER_OPTIONS } from '../../common/constants/constants-team';
import { useTeamGridPageReset } from '../../common/hooks/useTeamGridPageReset';
import { useTeamSelector } from '../../redux/hooks';

export default function TeamGridCaption() {
    const { selectedStatus } = useTeamSelector((state) => state.teamStatusFilter);
    const { searchTerm } = useTeamSelector((state) => state.teamSearchFilter);
    const { dataState, totalItems } = useTeamSelector((state) => state.teamGridSettings);
    const [showClear, setShowClear] = useState(false);
    const translate = useTranslation();

    useEffect(() => {
        if (selectedStatus.id !== STATUS_FILTER_OPTIONS[0].id || searchTerm.length > 0 || dataState.filter !== undefined) {
            setShowClear(true);
        } else {
            setShowClear(false);
        }
    }, [selectedStatus.id, searchTerm, dataState]);

    const resetTeamGridPage = useTeamGridPageReset();

    const formatMessage = () => {
        switch (selectedStatus.id) {
            case 'ALL':
                return translate('AllMembers_Label', totalItems);
            case 'ACTIVE':
                return translate('ActiveMembers_Label', totalItems);
            case 'INACTIVE':
                return translate('InactiveMembers_Label', totalItems);
        }
    };

    return (
        <GridCaption clearLabel={translate('Clear_Label')} showClear={showClear} onClear={resetTeamGridPage} tableColumnsDropdown={false}>
            <span>{formatMessage()}</span>
        </GridCaption>
    );
}
