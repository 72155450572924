import GridCaption from '../../../../common/features/Grid/GridCaption';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useContactsDispatch, useContactsSelector } from '../../redux/hooks';
import { resetDataState, setPageSelected } from '../../redux/reducers/contactsGridSettingsSlice';

interface ContactsGridCaptionProps {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

export default function ContactsGridCaption({ searchTerm, setSearchTerm }: ContactsGridCaptionProps) {
    const { dataState, totalItems } = useContactsSelector((state) => state.contactsGridSettings);
    const dispatch = useContactsDispatch();

    const translate = useTranslation();

    const isClearVisible = searchTerm.length > 0 || dataState.filter !== undefined;

    const clearFilters = () => {
        setSearchTerm('');
        dispatch(resetDataState({ take: 10, skip: 0, sort: [{ field: 'LastLogin', dir: 'desc' }] }));
        dispatch(setPageSelected(1));
    };

    return (
        <GridCaption clearLabel={translate('Clear_Label')} showClear={isClearVisible} onClear={clearFilters} tableColumnsDropdown={false}>
            <span>{translate('AllContacts_Label', totalItems)}</span>
        </GridCaption>
    );
}
