import { clsx } from 'clsx';
import { createPortal } from 'react-dom';
import Button from '../Button';
import Spinner from '../Spinner';

interface RowWithButtonProps {
    extraRowClasses?: string;
    disabled?: boolean;
    isLoading?: boolean;
    children: React.ReactNode;
    element: string;
    onClick: () => void;
}

const RowWithButton: React.FC<RowWithButtonProps> = ({ extraRowClasses = '', children, element, disabled, isLoading, onClick }) => {
    const parentContainer = document.querySelector(element) || document.body;

    return (
        <>
            {createPortal(
                <div className={clsx('row-with-button', extraRowClasses)}>
                    <Button disabled={disabled} variant='tertiary-blue' size='small' onClick={onClick}>
                        {isLoading && <Spinner size='small' className='mr-2' />}
                        {children}
                    </Button>
                </div>,
                parentContainer
            )}
        </>
    );
};

export default RowWithButton;
