import { useEffect, useState } from 'react';
import Button from '../../../../../common/features/Button';
import FilterDropdown from '../../../../../common/features/FilterDropdown';
import BasicSearchBox from '../../../../../common/features/SearchBox/BasicSearchBox';
import useClearanceNotificationsFilter from '../../../../../common/hooks/useClearanceNotificationsFilter';
import useTranslation from '../../../../../common/hooks/useTranslation';
import type { GridSubscriptions } from '../../../common/constants/constants-profile';
import { subscriptionTypeConstants } from '../../../common/constants/constants-profile';
import { useSubscriptionsWithClientNumbersQuery } from '../../../redux/api/profileApi';
import { useProfileDispatch, useProfileSelector } from '../../../redux/hooks';
import { setFilteredSubscriptions } from '../../../redux/reducers/subscriptionsSlice';

export default function FiltersBar() {
    const { user } = useProfileSelector((state) => state.hydration);
    const dispatch = useProfileDispatch();

    const translate = useTranslation();

    const [gridSubscriptions, setGridSubscriptions] = useState<GridSubscriptions[]>([]);
    const [subscriptionType, setSubscriptionType] = useState<{ value: string; label: string }[]>([]);

    const [searchParam, setSearchParam] = useState('');

    const handleSearch = (value: string) => {
        setSearchParam(value);
    };

    const handleResetSearch = () => {
        setSearchParam('');
    };

    const [dropdownSelection, setDropdownSelection] = useState<number[]>([]);

    const handleApply = (id: string, selectedArray: number[]) => {
        const subsType = selectedArray.map((item) => {
            return subscriptionTypeConstants[item];
        });
        setSubscriptionType(subsType);
        setDropdownSelection(selectedArray);
    };

    const clearFilters = () => {
        setDropdownSelection([]);
        setSubscriptionType([]);
    };

    const { filteredSubscriptions, count } = useClearanceNotificationsFilter({
        searchParam,
        subscriptionType,
        gridSubscriptions
    });

    useEffect(() => {
        dispatch(setFilteredSubscriptions(filteredSubscriptions || null));
    }, [dispatch, filteredSubscriptions]);

    const { data: subscriptionsData } = useSubscriptionsWithClientNumbersQuery(
        { ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber },
        { skip: !user }
    );

    useEffect(() => {
        if (subscriptionsData?.length) setGridSubscriptions(subscriptionsData);
    }, [subscriptionsData]);

    return (
        <>
            <div className='p-3 bg-white border-bottom'>
                <BasicSearchBox
                    initialValue={searchParam}
                    placeholderLabel={translate('SearchClientNoEntryNo_Label')}
                    submitButtonLabel={translate('Search_Label')}
                    onReset={handleResetSearch}
                    onSubmit={handleSearch}
                />
            </div>
            <div className='scrollable-filter-area d-flex flex-nowrap align-items-center border-bottom bg-white py-3 pr-3'>
                <div className='font-weight-medium pl-3 pr-2'>{translate('Filters_Label')}</div>
                <FilterDropdown
                    id='SubscriptionType'
                    name={translate('SubscriptionType_Label')}
                    options={subscriptionTypeConstants}
                    optionsLabel={translate('FilterBySubscriptionType_Label')}
                    selection={dropdownSelection}
                    handleSelection={handleApply}
                />
                {subscriptionType.length ? (
                    <div className='clear-all-filters d-flex flex-row align-items-center'>
                        <Button className='no-bold-underline' variant='link' onClick={clearFilters}>
                            {translate('Clear_Label')}
                        </Button>
                    </div>
                ) : null}
            </div>
            <div className='align-items-center border-bottom bg-white py-3 pr-3 pl-3'>
                <span className='font-weight-bold'>{count}</span>
                {` ${translate('Subscriptions_Label')}`}
            </div>
        </>
    );
}
