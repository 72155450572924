import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ToastProps } from '../../common/features/Toast';

interface Toast extends Omit<ToastProps, 'customTimeout'> {
    id: string;
}

const initialState: { toasts: Toast[] } = {
    toasts: []
};

let count = 0;
const TOAST_LIMIT = 1;

function genId() {
    count = (count + 1) % Number.MAX_SAFE_INTEGER;
    return count.toString();
}

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addToast(state, action: PayloadAction<Omit<Toast, 'id'>>) {
            state.toasts = [{ ...action.payload, id: genId() }, ...state.toasts].slice(0, TOAST_LIMIT);
        },
        removeOldestToast(state) {
            state.toasts = state.toasts.slice(0, -1);
        }
    }
});

export const { addToast, removeOldestToast } = toastSlice.actions;

export default toastSlice.reducer;
