import { clsx } from 'clsx';

interface HeaderBarProps {
    children: React.ReactNode;
    columnsLayout?: boolean;
    mobileView?: boolean;
}

const HeaderBar: React.FC<HeaderBarProps> = ({ children, columnsLayout, mobileView }) => {
    return (
        <div className='header-bar bg-white py-3 '>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <div
                            className={clsx(
                                'd-flex justify-content-between align-items-center',
                                columnsLayout && mobileView && 'dropdown-examples'
                            )}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderBar;
