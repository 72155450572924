import { clsx } from 'clsx';
import React from 'react';

interface ProfileSectionProps {
    label: string;
    children: React.ReactNode;
    className?: string;
}

export default function ProfileSection({ label, children, className = 'py-4 py-md-5' }: ProfileSectionProps) {
    return (
        <div className={clsx('row profile-content-block', className)}>
            <h2 className='h4 col-12 col-md-3 mb-4 mb-md-0'>{label}</h2>
            <div className='col-12 col-md-9'>
                <div className='row info-block'>{children}</div>
            </div>
        </div>
    );
}
