import { useEffect, useState } from 'react';
import { TabNavigationIds } from '../../../../common/constants/constants-portal';
import ClientChooser from '../../../../common/features/ClientChooser/ClientChooser';
import ErrorPage from '../../../../common/features/ErrorPage';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import Spinner from '../../../../common/features/Spinner';
import TabNav from '../../../../common/features/Tabs/TabNav';
import TabNavItem from '../../../../common/features/Tabs/TabNavItem';
import TabPanel from '../../../../common/features/Tabs/TabPanel';
import TabPanelItem from '../../../../common/features/Tabs/TabPanelItem';
import { UserProfileImage } from '../../../../common/features/UserProfileImage';
import { isFetchBaseQueryError } from '../../../../common/functions/utils';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useLazySubscriptionsWithClientNumbersQuery, useUserDetailsQuery } from '../../redux/api/profileApi';
import { useProfileSelector } from '../../redux/hooks';
import FiltersBar from './ClearanceNotificationsTab/FiltersBar';
import ProfileSubscriptionTable from './ClearanceNotificationsTab/ProfileSubscriptionTable';
import ProfileTab from './ProfileTab/ProfileTab';

export default function ProfilePage() {
    const { user } = useProfileSelector((state) => state.hydration);

    const translate = useTranslation();

    const [currentTab, setCurrentTab] = useState<1 | 2>(localStorage.getItem('activeTab') === 'profile-subscription' ? 2 : 1);

    const { data: userDetails, error, isLoading } = useUserDetailsQuery();
    const errorStatus = (isFetchBaseQueryError(error) && error.status) || undefined;

    const [getSubscriptionsWithClientNumbers] = useLazySubscriptionsWithClientNumbersQuery();

    useEffect(() => {
        if (!user?.CountryCode) return;

        getSubscriptionsWithClientNumbers({ ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber });
    }, [getSubscriptionsWithClientNumbers, user?.CountryCode, user?.Email, user?.MobileNumber]);

    return (
        <>
            {/* Status: pending */}
            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {!isLoading && userDetails && !error && (
                <div className='d-flex flex-column flex-fill h-100'>
                    <HeaderBar>
                        <div className='profile-name d-flex align-items-center'>
                            <div className='avatar icon mr-3'>
                                <UserProfileImage />
                            </div>
                            <h1>{`${userDetails?.FirstName}  ${userDetails?.LastName}`}</h1>
                        </div>
                        {currentTab === 2 && <ClientChooser />}
                    </HeaderBar>
                    {currentTab === 2 && <FiltersBar />}
                    <TabNav className='border-none' tabs={TabNavigationIds.Profile}>
                        <TabNavItem onClick={() => setCurrentTab(1)} id={TabNavigationIds.Profile[0]}>
                            {translate('UserProfile_Label')}
                        </TabNavItem>
                        {!user?.InternalUser && (
                            <TabNavItem onClick={() => setCurrentTab(2)} id={TabNavigationIds.Profile[1]}>
                                {translate('ClearanceNotifications_Label')}
                            </TabNavItem>
                        )}
                    </TabNav>
                    <TabPanel>
                        <TabPanelItem id={TabNavigationIds.Profile[0]} className='bg-white'>
                            <ProfileTab />
                        </TabPanelItem>
                        <TabPanelItem id={TabNavigationIds.Profile[1]}>
                            <ProfileSubscriptionTable />
                        </TabPanelItem>
                    </TabPanel>
                </div>
            )}
            {/* Status: rejected */}
            {!isLoading && error && <ErrorPage errorCode={errorStatus} withTranslations />}
        </>
    );
}
