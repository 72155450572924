import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import Button from '../../../../../common/features/Button';
import CountryFlag from '../../../../../common/features/CountryFlag';
import MilestoneDropdown from '../../../../../common/features/MilestoneDropdown';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import useTranslation from '../../../../../common/hooks/useTranslation';
import type { Notifications, SubscriptionsData } from '../../../../../common/models/ResponseData';
import { addToast } from '../../../../../redux/reducers/toastSlice';
import { useUnsubscribeMutation, useUpdateSubscriptionMutation } from '../../../redux/api/profileApi';
import { useProfileDispatch, useProfileSelector } from '../../../redux/hooks';
import { resetSelectedState } from '../../../redux/reducers/profileGridSettingsSlice';
import NotificationTypesDropdown from './NotificationTypesDropdown';
import OnHoldWrapper from './OnHoldWrapper';
import UnsubscribeModal from './UnsubscribeModal';

interface DataItem extends SubscriptionsData {
    selected?: boolean;
    SubscriptionType: 'SINGLE' | 'CLIENT';
    EntryNo: string;
    DocID: number;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    isLocked: boolean;
}

export type SubscribeActions = 'subscribe' | 'unsubscribe' | 'notifTypes' | 'onhold' | 'milestones';

export default function FormattedGridCell({ field = '', dataItem, isLocked, selectionChange, className }: FormattedGridCellProps) {
    const { user } = useProfileSelector((state) => state.hydration);
    const dispatch = useProfileDispatch();

    const translate = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const [unsubscribeAction, { isLoading: unsubscribeIsLoading }] = useUnsubscribeMutation();
    const [updateSubscription, { isLoading: updateSubscriptionIsLoading }] = useUpdateSubscriptionMutation();

    const handleSubscriptionUpdate = async (
        action: SubscribeActions,
        {
            customerNumber,
            subscriptionId,
            milestones,
            email,
            phone,
            docId
        }: {
            customerNumber: null | string;
            subscriptionId: null | number;
            milestones: null | Notifications[];
            email: null | string;
            phone: null | string;
            docId: null | number;
        }
    ) => {
        dispatch(resetSelectedState());

        if (action === 'unsubscribe' && subscriptionId) {
            try {
                const data = await unsubscribeAction({ subscriptionId }).unwrap();
                const alertMessage =
                    data.ResponseCode === 400 && data.ResponseMessage === 'FAIL'
                        ? 'Error'
                        : data.ResponseMessage === 'PASS' && data.RequestedAction === 'UNSUBSCRIBE'
                        ? 'UnsubscribedFromClient_Label'
                        : '';
                const alertType = data.ResponseCode === 400 && data.ResponseMessage === 'FAIL' ? 'warning' : 'success';

                dispatch(
                    addToast({
                        message:
                            alertMessage === 'UnsubscribedFromClient_Label'
                                ? translate(alertMessage, customerNumber)
                                : translate(alertMessage),
                        type: alertType
                    })
                );
            } catch (error) {
                console.error('Failed to unsubscribe:', error);
            }
        } else if (customerNumber) {
            try {
                const data = await updateSubscription({
                    customerNumber,
                    subscriptionId,
                    milestones,
                    email,
                    phone,
                    docId,
                    language: user?.LanguageCode,
                    profileEmail: user?.Email,
                    profilePhoneNumber: user?.MobileNumber
                }).unwrap();

                const alertMessages: Record<SubscribeActions, string> = {
                    subscribe: 'SubscribedToClient_Label',
                    unsubscribe: 'UnsubscribedFromClient_Label',
                    onhold: 'OnHoldNotificationsUpdated_Label',
                    notifTypes: 'NotificationTypeUpdated_Label',
                    milestones: 'MilestonesUpdated_Label'
                };

                const alertMessage =
                    data.ResponseCode === 400 && data.ResponseMessage === 'FAIL'
                        ? 'ClientNumberNoClearancesAvailable_Label'
                        : alertMessages[action];

                const alertType = data.ResponseCode === 400 && data.ResponseMessage === 'FAIL' ? 'warning' : 'success';

                dispatch(
                    addToast({
                        message:
                            alertMessage === 'UnsubscribedFromClient_Label'
                                ? translate(alertMessage, customerNumber)
                                : translate(alertMessage),
                        type: alertType
                    })
                );
            } catch (error) {
                console.error('Failed to update subscription:', error);
            }
        }
    };

    let cellContent;

    switch (field) {
        case 'CustomerNumber':
            cellContent = dataItem.CustomerNumber;
            break;
        case 'EntryNumber':
            cellContent = dataItem.EntryNo;
            break;
        case 'SubscriptionType':
            {
                const typeLabel = {
                    SINGLE: 'SingleEntry_Label',
                    CLIENT: 'ClientNo_Label'
                };

                cellContent = dataItem ? translate(typeLabel[dataItem.SubscriptionType]) : null;
            }
            break;
        case 'Milestones':
            {
                const email = dataItem.Email || null;
                const phone = dataItem.Phone || null;
                const docId = dataItem.DocID;

                const handleUpdate = (
                    action: SubscribeActions,
                    customerNumber: null | string,
                    subscriptionId: null | number,
                    milestones: null | Notifications[]
                ) => {
                    handleSubscriptionUpdate(action, {
                        customerNumber,
                        subscriptionId,
                        milestones,
                        email,
                        phone,
                        docId
                    });
                };

                cellContent = (
                    <MilestoneDropdown
                        {...dataItem}
                        updateMilestones={handleUpdate}
                        disabled={!dataItem?.IsAvailableToSubscribe || isLocked}
                        applyButtonLabel={
                            !user?.InternalUser ? (
                                translate('Apply_Label')
                            ) : (
                                <Tooltip label='Disabled for LII users.'>
                                    <span>{translate('Apply_Label')}</span>
                                </Tooltip>
                            )
                        }
                        cancelButtonLabel={translate('Cancel_Label')}
                    />
                );
            }
            break;
        case 'Subscription':
            {
                const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
                const subscribed = dataItem?.SubscriptionId ? dataItem?.SubscriptionId : false;
                const subscriptionId = dataItem?.SubscriptionId || null;
                const clientNumber = dataItem?.CustomerNumber || null;
                const email = dataItem.Email || null;
                const phone = dataItem.Phone || null;
                const docId = dataItem.DocID;

                const handleUnsubscribe = async () => {
                    await handleSubscriptionUpdate('unsubscribe', {
                        customerNumber: clientNumber,
                        subscriptionId,
                        milestones: null,
                        email,
                        phone,
                        docId: null
                    });
                    setShowModal(false);
                };

                cellContent = (
                    <>
                        <div className='d-flex justify-content-center'>
                            <Button
                                variant={subscribed ? 'danger-ghost' : 'ghost'}
                                onClick={() => {
                                    if (dataItem?.IsAvailableToSubscribe) {
                                        subscribed
                                            ? setShowModal(true)
                                            : handleSubscriptionUpdate('subscribe', {
                                                  customerNumber: clientNumber,
                                                  subscriptionId: null,
                                                  milestones: dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!,
                                                  email,
                                                  phone,
                                                  docId
                                              });
                                    }
                                }}
                                disabled={isLocked || unsubscribeIsLoading || updateSubscriptionIsLoading}
                                data-target='#modal'
                            >
                                <FontAwesomeIcon icon={subscribed ? faBellSlash : faBell} className='mr-1' />
                                {subscribed ? (
                                    translate('Unsubscribe_Label')
                                ) : !dataItem?.IsAvailableToSubscribe ? (
                                    <Tooltip label={translate('NotificationsSubscribeMessageAllNoClearances_Label')}>
                                        <span>{translate('Subscribe_Label')}</span>
                                    </Tooltip>
                                ) : (
                                    translate('Subscribe_Label')
                                )}
                            </Button>
                        </div>
                        <UnsubscribeModal
                            clientNumber={clientNumber}
                            isPending={unsubscribeIsLoading}
                            isVisible={showModal}
                            setIsVisible={setShowModal}
                            onSubmit={handleUnsubscribe}
                        />
                    </>
                );
            }
            break;
        case 'OnHoldNotifications':
            {
                const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
                const subscriptionId = dataItem?.SubscriptionId || null;
                const clientNumber = dataItem?.CustomerNumber;
                const email = dataItem.Email || null;
                const phone = dataItem.Phone || null;
                let milestones = dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!;
                const docId = dataItem.DocID;

                const handleToggle = (value: boolean) => {
                    milestones = milestones.map((item) => {
                        if (item.Name === `HasNotificationsForClearanceOnHold${country === 'ca' ? '_CA' : ''}`) {
                            return { Name: item.Name, Value: value };
                        }
                        return item;
                    });
                    handleSubscriptionUpdate('onhold', {
                        customerNumber: clientNumber!,
                        subscriptionId,
                        milestones,
                        email,
                        phone,
                        docId
                    });
                };

                cellContent = (
                    <OnHoldWrapper data={dataItem} toggleChecked={handleToggle} disabled={!dataItem?.IsAvailableToSubscribe || isLocked} />
                );
            }
            break;
        case 'Country':
            {
                const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);

                cellContent = (
                    <div className='pl-3'>
                        <CountryFlag countryCode={country} />
                    </div>
                );
            }
            break;
        case 'DateSubscribed':
            cellContent = dataItem.DateSubscribed ? dayjs(dataItem.DateSubscribed).format('MM/DD/YY') : null;
            break;
        case 'NotificationTypes':
            {
                const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
                const email = dataItem.Email ?? dataItem.ProfileEmail;
                const phone = dataItem.Phone ?? dataItem.ProfilePhone;
                const subscriptionId = dataItem?.SubscriptionId || null;
                const emailChecked = dataItem.SubscriptionId && dataItem.Email;
                const clientNumber = dataItem?.CustomerNumber;
                const docId = dataItem.DocID;
                let milestones = dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!;

                const handleNotificationTypes = (isEmailChecked: boolean, mobilePhone: null | string) => {
                    handleSubscriptionUpdate('notifTypes', {
                        customerNumber: clientNumber!,
                        subscriptionId,
                        milestones,
                        email: isEmailChecked ? email || null : null,
                        phone: mobilePhone,
                        docId
                    });
                };

                cellContent = (
                    <NotificationTypesDropdown
                        email={email || null}
                        phone={phone || null}
                        emailChecked={emailChecked}
                        applyChange={handleNotificationTypes}
                        disabled={user?.InternalUser || !dataItem?.IsAvailableToSubscribe || isLocked}
                    />
                );
            }
            break;
        case 'selected':
            cellContent = <Checkbox value={!!dataItem.selected} onChange={selectionChange} disabled={!dataItem.IsEditable} />;
            break;
        default:
            cellContent = null;
    }

    return (
        <td className={clsx(className, dataItem?.selected && 'k-state-selected', field === 'selected' ? 'text-center' : 'text-left')}>
            {cellContent}
        </td>
    );
}
