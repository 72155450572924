import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PortalRoutes, REACT_APP_ASSETS_URL_CSS } from '../../common/constants/constants-portal';
import Protected from '../../common/features/Protected';
import Toaster from '../../common/features/Toaster';
import App from './App';
import store from './redux/store';

const Portal = () => {
    return (
        <Provider store={store}>
            <Protected conditions={{ isInternalUser: false }} redirectUrl={PortalRoutes.clearances}>
                <HelmetProvider>
                    <Helmet>
                        <title>Portal</title>
                        <link rel='stylesheet' href={REACT_APP_ASSETS_URL_CSS} />
                        <link rel='stylesheet' href='/css/portal-team.css' />
                    </Helmet>
                    <Toaster />
                    <App />
                </HelmetProvider>
            </Protected>
        </Provider>
    );
};

export default Portal;
