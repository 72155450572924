import { API_PROFILE_PATH, NewSubscriberRoles } from '../../../../common/constants/constants-portal';
import type { Notifications, SubscriptionsData, SubscriptionsWithClientNumbersData } from '../../../../common/models/ResponseData';
import { apiSlice } from '../../../../redux/api/apiSlice';
import type { RequestAnUpdateModel } from '../../common/models/RequestAnUpdateModel';
import type { UserProfileData } from '../../common/models/ResponseData';
import type { SubscribeData, SubscriptionIdCustomerNumber } from '../../common/models/SubscriptionUpdate';
import type { UsersTicketsDetailsModel } from '../../common/models/UsersTicketDetailsModel';

interface SubscriptionsWithClientNumbersBody {
    ProfileEmail: string | undefined | null;
    ProfileMobileNumber: string | undefined | null;
}

interface UnsubscribeBody {
    subscriptionId: number;
}

interface UnsubscribeMultipleBody {
    subscriptionIds: number[];
}

interface UpdateSubscriptionBody {
    customerNumber: string;
    subscriptionId: null | number;
    milestones: null | Notifications[];
    email: null | string;
    phone: null | string;
    docId: null | number;
    language: string | undefined;
    profileEmail: string | null | undefined;
    profilePhoneNumber: string | null | undefined;
}

interface UpdateSubscriptionsByUserAndClientBody {
    SubscriptionIdCustomerNumberDyads: SubscriptionIdCustomerNumber[];
    FullName: null | string;
    Role: null | (typeof NewSubscriberRoles)[number]['value'];
    UserId: string | null;
    Email: string | null | undefined;
    Phone: string | null;
    Language: string | null;
    MilestoneNotifications_US: Notifications[];
    MilestoneNotifications_CA: Notifications[];
}

interface SubscriptionsByUserAndClientData {
    ResponseCode: number | null;
    ResponseMessage: string;
    RequestedAction: string;
    SubscriptionStatusCode: string;
    SubscriptionIds: string[];
}

export const profileApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        userDetails: build.query<UserProfileData, void>({
            query: () => ({
                url: `${API_PROFILE_PATH}/getUserDetails`
            })
        }),
        userTickets: build.query<UsersTicketsDetailsModel[], void>({
            query: () => ({
                url: `${API_PROFILE_PATH}/getUsersCaseTicketsDetails`
            }),
            providesTags: ['UserTickets']
        }),
        subscriptionsWithClientNumbers: build.query<SubscriptionsData[], SubscriptionsWithClientNumbersBody>({
            query: (body) => ({
                url: 'api/clearance/clearance/get-subscriptions-per-clientnumbers',
                method: 'POST',
                body
            }),
            transformResponse: (response: SubscriptionsWithClientNumbersData) => response.SubscriptionsPerClientNumbers,
            providesTags: ['SubscriptionsWithClientNumbers']
        }),
        unsubscribe: build.mutation<SubscribeData, UnsubscribeBody>({
            query: (body) => ({
                url: 'api/clearance/clearance/unsubscribe-subscription',
                method: 'PUT',
                body
            }),
            invalidatesTags: ['SubscriptionsWithClientNumbers']
        }),
        unsubscribeMultiple: build.mutation<SubscribeData, UnsubscribeMultipleBody>({
            query: (body) => ({
                url: 'api/clearance/clearance/unsubscribe-subscriptions',
                method: 'PUT',
                body
            }),
            invalidatesTags: ['SubscriptionsWithClientNumbers']
        }),
        updateSubscription: build.mutation<SubscribeData, UpdateSubscriptionBody>({
            query: (body) => ({
                url: 'api/clearance/clearance/update-subscription-by-user-client',
                method: 'POST',
                body: {
                    SubscriptionId: body.subscriptionId,
                    customerNumber: body.customerNumber,
                    MilestoneNotifications: body.milestones,
                    Phone: body.phone,
                    Email: body.email,
                    DocID: body.docId,
                    Language: body.language
                }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    profileApi.util.updateQueryData(
                        'subscriptionsWithClientNumbers',
                        { ProfileEmail: arg.profileEmail, ProfileMobileNumber: arg.profilePhoneNumber },
                        (draft) =>
                            draft.map((item) => {
                                return item.CustomerNumber === arg.customerNumber
                                    ? {
                                          ...item,
                                          MilestoneNotifications_CA:
                                              Number(item.CustomerNumber[0]) <= 3 || Number(item.CustomerNumber[0]) === 7
                                                  ? arg.milestones
                                                  : item.MilestoneNotifications_CA,
                                          MilestoneNotifications_US:
                                              Number(item.CustomerNumber[0]) > 3 && Number(item.CustomerNumber[0]) !== 7
                                                  ? arg.milestones
                                                  : item.MilestoneNotifications_US
                                      }
                                    : item;
                            })
                    )
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
            invalidatesTags: ['SubscriptionsWithClientNumbers']
        }),
        updateSubscriptionsByUserAndClient: build.mutation<SubscriptionsByUserAndClientData, UpdateSubscriptionsByUserAndClientBody>({
            query: (body) => ({
                url: 'api/clearance/clearance/update-subscriptions-by-user-client',
                method: 'POST',
                body
            }),
            invalidatesTags: ['SubscriptionsWithClientNumbers']
        }),
        getCaseNumber: build.mutation<string, RequestAnUpdateModel>({
            query: (body) => ({
                url: `${API_PROFILE_PATH}/getCaseNumberFromSFDC`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['UserTickets']
        })
    })
});

export const {
    useUserDetailsQuery,
    useSubscriptionsWithClientNumbersQuery,
    useLazySubscriptionsWithClientNumbersQuery,
    useUnsubscribeMutation,
    useUserTicketsQuery,
    useGetCaseNumberMutation,
    useUnsubscribeMultipleMutation,
    useUpdateSubscriptionMutation,
    useUpdateSubscriptionsByUserAndClientMutation
} = profileApi;
