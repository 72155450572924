import { clsx } from 'clsx';
import React from 'react';

interface CloseButtonProps {
    type?: 'standard' | 'toast';
    onClick: () => void;
    style?: React.CSSProperties;
    className?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ type = 'standard', onClick, style, className = '' }) => {
    // Return appropriate button based on the selected type
    if (type === 'standard') {
        return (
            <button type='button' className={clsx('close close-button', className)} onClick={onClick} style={style}>
                <span>&times;</span>
            </button>
        );
    } else {
        return (
            <button
                type='button'
                className={clsx('close close-button', className)}
                style={style}
                data-dismiss='toast'
                aria-label='Close'
                onClick={onClick}
            >
                <span aria-hidden='true'>&times;</span>
            </button>
        );
    }
};

export default CloseButton;
