import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PortalRoutes } from '../../../../common/constants/constants-portal';
import Breadcrumb from '../../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../common/features/Breadcrumb/BreadcrumbItem';
import { useSearchParams } from '../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../common/hooks/useTranslation';
import { usePortalDispatch } from '../../../../redux/hooks';
import { addToast } from '../../../../redux/reducers/toastSlice';
import { TEAM_SEARCH_PARAM_KEYS } from '../../common/constants/constants-team';
import type { ClientsAndPermissionsActions } from '../../common/features/ClientsAndPermissions';
import ClientsAndPermissions from '../../common/features/ClientsAndPermissions';
import Header from '../../common/features/Header';
import TeamMemberFooter from '../../common/features/TeamMemberFooter';
import UserDetails, { UserDetailsActions } from '../../common/features/UserDetails';
import { useMappedParentServices } from '../../common/hooks/useMappedParentServices';
import { useTeamGridPageReset } from '../../common/hooks/useTeamGridPageReset';
import { useClonedTeamMemberDetailsQuery, useCreateTeamMemberMutation } from '../../redux/api/teamApi';

export default function CloneTeamMember() {
    const dispatch = usePortalDispatch();

    const translate = useTranslation();

    const history = useHistory();
    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data: teamMemberData, isFetching: teamMemberIsFetching } = useClonedTeamMemberDetailsQuery(userId.toString());
    const { data: parentServicesData, isLoading: parentServicesIsLoading } = useMappedParentServices(teamMemberData?.ParentServices);

    const resetTeamGridPage = useTeamGridPageReset();

    const userDetails = useRef<UserDetailsActions>(null);
    const clientsAndPermissions = useRef<ClientsAndPermissionsActions>(null);

    const [error, setError] = useState({ userDetails: false, clientsAndPermissions: false });

    const [createTeamMember, { isLoading: createTeamMemberIsPending }] = useCreateTeamMemberMutation();

    const handleSubmit = async () => {
        const userDetailsData = userDetails.current?.getData();
        const parentServices = clientsAndPermissions.current?.getData();

        const userDetailsIsValid = userDetails.current?.validate() || false;
        const clientsAndPermissionsIsValid = clientsAndPermissions.current?.validate(parentServices) || false;

        if (!clientsAndPermissionsIsValid || !userDetailsIsValid) {
            setError({ userDetails: !userDetailsIsValid, clientsAndPermissions: !clientsAndPermissionsIsValid });
            return;
        }

        setError({ userDetails: false, clientsAndPermissions: false });

        try {
            const response = await createTeamMember({ ...userDetailsData, ParentServices: parentServices }).unwrap();

            if (response === 'SuccessfulSubmit') {
                dispatch(addToast({ type: 'success', message: translate('StatusAlertUsersAdded_Label', userDetailsData?.Users?.length) }));
                resetTeamGridPage();

                return history.push(PortalRoutes.team, { newTeamMembersAmount: userDetailsData?.Users?.length });
            }
        } catch (error) {
            dispatch(addToast({ type: 'error', message: translate('SomethingWentWrong_Label') }));
        }
    };

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem url={PortalRoutes.team}>{translate('Team_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{translate('CloneUser_Label')}</BreadcrumbItem>
            </Breadcrumb>
            <Header title={translate('HeaderCloneUser_Label')} />
            <div className='container-fluid py-3'>
                <UserDetails ref={userDetails} data={teamMemberData} isLoading={teamMemberIsFetching} />
                <ClientsAndPermissions
                    ref={clientsAndPermissions}
                    teamMemberData={teamMemberData}
                    parentServicesData={parentServicesData}
                    isLoading={teamMemberIsFetching || parentServicesIsLoading}
                />
            </div>
            <TeamMemberFooter
                data={teamMemberData}
                isPending={createTeamMemberIsPending}
                isDisabled={teamMemberIsFetching || parentServicesIsLoading}
                error={error}
                onSubmit={handleSubmit}
            />
        </>
    );
}
