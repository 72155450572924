import { createAsyncThunk } from '@reduxjs/toolkit';
import gridWidthPreferences from '../../../../common/grid-preferences/grid-width-preferences.json';
import { GET_WIDTH_GRID_PREFERENCES } from './actionTypes';

export const GetUserWidthGridSetting = createAsyncThunk(GET_WIDTH_GRID_PREFERENCES, async (payload, { rejectWithValue }) => {
    try {
        const json = gridWidthPreferences;
        return json;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
