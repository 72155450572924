import { useEffect, useState } from 'react';
import Checkbox from '../../../../../common/features/Checkbox';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import { SubscriptionsData } from '../../../../../common/models/ResponseData';

interface OnHoldWrapperProps {
    data: SubscriptionsData;
    disabled?: boolean;
    toggleChecked?: (isOnHold: boolean) => void;
}

export default function OnHoldWrapper({ data, disabled, toggleChecked }: OnHoldWrapperProps) {
    const [isChecked, setIsChecked] = useState(false);
    const country = getCountryFromClientNumber(data?.CustomerNumber!);
    const countryNotifications = country === 'ca' ? data?.MilestoneNotifications_CA : data?.MilestoneNotifications_US;

    useEffect(() => {
        countryNotifications?.forEach((element) => {
            if (element.Name === `HasNotificationsForClearanceOnHold${country === 'ca' ? '_CA' : ''}`) setIsChecked(element?.Value!);
        });
    }, [data, country, countryNotifications]);

    const handleOnChange = (key: string, value: boolean) => {
        setIsChecked(isChecked ? false : true);
        if (toggleChecked) {
            toggleChecked(value);
        }
    };

    return (
        <Checkbox
            id={`check-${data?.CustomerNumber!}`}
            name={`check-${data?.CustomerNumber!}`}
            onChange={handleOnChange}
            isChecked={isChecked}
            divStyle={{ width: '1.25rem', height: '1.32813rem', cursor: 'pointer', margin: 'auto' }}
            divClass=''
            labelClass=''
            inputStyle={{ width: '1.25rem', height: '1.32813rem', cursor: 'pointer', accentColor: '#0070ff' }}
            inputClass=''
            disabled={disabled}
        />
    );
}
