import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SubscriptionsData } from '../../../../common/models/ResponseData';
import type { SubscriptionsSlice } from '../../common/models/ReduxSlices';
import { GetUserWidthGridSetting } from '../actions/subscriptions';

const initialState: SubscriptionsSlice = {
    filteredSubscriptions: null,
    allColumns: [],
    visibleColumns: []
};

const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        setFilteredSubscriptions(state, action: PayloadAction<null | SubscriptionsData[]>) {
            state.filteredSubscriptions = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetUserWidthGridSetting.pending, (state) => {})
            .addCase(GetUserWidthGridSetting.fulfilled, (state, action) => {
                state.allColumns = action.payload.GridColumns;
                state.visibleColumns = action.payload.GridColumns;
            });
    }
});

export const { setFilteredSubscriptions } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
