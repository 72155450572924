import { faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import DropdownMenuButton from '../../../../../../common/features/DropdownMenuButton';
import Spinner from '../../../../../../common/features/Spinner';
import { checkAllFalsy } from '../../../../../../common/functions/utils';
import useGTMEventTracking from '../../../../../../common/hooks/useGTMEventTracking';
import useNotifDropdownToggle from '../../../../../../common/hooks/useNotifDropdownToggle';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { DownloadCASSShipment } from '../../../../../completeEntryData/redux/actions/completeEntryData';
import { configFilters } from '../../../../common/functions/documentModelFunctions';
import { DownloadDocuments, ExportHeaderAndDetail } from '../../../../redux/actions/clearanceDocuments';
import { useClearancesDispatch, useClearancesSelector } from '../../../../redux/hooks';

const DownloadDropdown = () => {
    const { documents, headerDetails, lineDetails, isLoading } = useClearancesSelector((state) => state.clearanceDocuments);
    const { documentsIndex } = useClearancesSelector((state) => state.shipmentsWithTotals);
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);
    const { clientSelection } = useClearancesSelector((state) => state.clientSelection);
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useNotifDropdownToggle(dropdownMenuRef);
    const dispatch = useClearancesDispatch();
    const translate = useTranslation();
    const selectedCountry = clientSelection?.Countries.find((country) => country.IsSelected === true)?.Code;

    // google analytics
    const headerlineDetailsEntryRef = useRef(null);
    const documentsDownloadEntryRef = useRef(null);
    const fullCADDownloadEntryRef = useRef(null);

    useGTMEventTracking('HL_DNLD_Entry_CLR_CPEvt', { location: 'Clearances' }, headerlineDetailsEntryRef);
    useGTMEventTracking('Doc_DNLD_Entry_CLR_CPEvt', { location: 'Clearances' }, documentsDownloadEntryRef);
    useGTMEventTracking('CAD_DNLD_Entry_CLR_CPEvt', { location: 'Clearances' }, fullCADDownloadEntryRef);

    const downloadHeaderAndLine = () => {
        handleToggle();
        if (documentsIndex?.Filters && documentsIndex.ImageGroupings && headerDetails) {
            dispatch(
                ExportHeaderAndDetail({
                    shipments: [headerDetails],
                    filters: configFilters(documentsIndex, [shipmentDetails?.EntryNumber!])!,
                    imageGroupings: documentsIndex.ImageGroupings
                })
            );
        }
    };

    const downloadDocuments = () => {
        handleToggle();
        if (documents) {
            dispatch(
                DownloadDocuments({
                    fileNames: { [shipmentDetails?.EntryNumber || 'document']: documents.map((document) => document.path) },
                    ImagePackageId: 1
                })
            );
        }
    };

    const downloadFullCADData = () => {
        handleToggle();

        if (shipmentDetails?.EntryNumber) {
            dispatch(
                DownloadCASSShipment({
                    entries: [
                        {
                            EntryNumber: shipmentDetails?.EntryNumber,
                            ClientNumber: shipmentDetails?.ClientCaptionNumber!.replace(/-/g, '')!
                        }
                    ],
                    viewModel: null,
                    returnAllFields: true
                })
            );
        }
    };

    return (
        <>
            <DropdownMenuButton
                btnSize='small'
                isOpen={isOpen}
                onClick={handleToggle}
                ref={dropdownMenuRef}
                btnClassName='dropdown-toggle mr-1'
                childrenDivClassName='dropdown-menu w-100 w-md-auto'
                label={
                    <>
                        <FontAwesomeIcon icon={faDownload} className='mr-2' />
                        {translate('DownloadButton_Label')}
                    </>
                }
            >
                <>
                    {selectedCountry === 'us' ? (
                        <button
                            className='dropdown-item d-flex justify-content-between align-items-center'
                            style={{ cursor: 'pointer' }}
                            onClick={() => downloadHeaderAndLine()}
                            ref={headerlineDetailsEntryRef}
                            disabled={!checkAllFalsy(headerDetails) || !lineDetails?.length}
                        >
                            {translate('HeaderAndLineDetail_Label')}
                            <div className='icon'>
                                <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                            </div>
                        </button>
                    ) : (
                        <button
                            className='dropdown-item d-flex justify-content-between align-items-center'
                            style={{ cursor: 'pointer' }}
                            onClick={() => downloadFullCADData()}
                            ref={fullCADDownloadEntryRef}
                        >
                            {translate('FullCADData_Label')}
                            <div className='icon'>
                                <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                            </div>
                        </button>
                    )}

                    <button
                        className='dropdown-item d-flex justify-content-between align-items-center'
                        style={{ cursor: 'pointer' }}
                        onClick={() => downloadDocuments()}
                        ref={documentsDownloadEntryRef}
                        disabled={!documents?.length}
                    >
                        {translate('Documents_Label')}
                        <div className='icon'>
                            <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                        </div>
                        {isLoading && <Spinner size='small' />}
                    </button>
                </>
            </DropdownMenuButton>
        </>
    );
};

export default DownloadDropdown;
