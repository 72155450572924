import { imagingPackageStatusType } from '../../common/constants/constants-portal';
import { setCheckDownloadLobby, setInitialDownloadLobby } from '../reducers/alertsSlice';
import { addToast } from '../reducers/toastSlice';
import { apiSlice } from './apiSlice';

export interface CheckDownloadLobbyResponseModified {
    ImageUrl: string;
    ImagingPackageStatusTypeID: number;
    PackageId: number;
    PackageTypeID: number;
    PackageSource: string;
}

export interface InitialDownloadLobbyResponseModified extends CheckDownloadLobbyResponseModified {
    CreatedDate: string;
    CountOfDocuments: number;
    EntryNumbers: string[];
}

export const downloadLobbyApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        checkDownloadLobby: build.query<CheckDownloadLobbyResponseModified, string>({
            query: (packageId) => ({
                url: `/Imaging/DownloadLobby?packageId=${packageId}`
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                const { data } = await queryFulfilled;

                if (
                    data.ImageUrl === '' &&
                    data.ImagingPackageStatusTypeID === 0 &&
                    data.PackageId.toString() === arg &&
                    data.PackageTypeID === 0
                ) {
                    dispatch(addToast({ message: 'HeaderAndLineInfoNoData_Label', isLabel: true, type: 'error' }));
                } else if (data.ImagingPackageStatusTypeID === imagingPackageStatusType['FaultTerminated']) {
                    dispatch(addToast({ message: 'DownloadLobbyErrorTitle_Label', isLabel: true, type: 'error' }));
                } else if (data.ImagingPackageStatusTypeID === imagingPackageStatusType['Completed']) {
                    dispatch(addToast({ message: 'DocumentsDownloadReady_Label', isLabel: true, type: 'success' }));
                    dispatch(downloadLobbyApi.endpoints.initialDownloadLobby.initiate(undefined, { forceRefetch: true }));
                }

                dispatch(setCheckDownloadLobby(data));
            },
            transformResponse: (response: string, _meta, arg) => {
                if (response === '') {
                    return { ImageUrl: '', ImagingPackageStatusTypeID: 0, PackageId: arg, PackageTypeID: 0, PackageSource: '' };
                }

                return JSON.parse(response);
            }
        }),
        initialDownloadLobby: build.query<InitialDownloadLobbyResponseModified[], void>({
            query: () => ({
                url: '/Imaging/DownloadLobbyUserInfo'
            }),
            async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
                const { data } = await queryFulfilled;

                dispatch(setInitialDownloadLobby(data));
            },
            transformResponse: (response: string) => {
                return JSON.parse(response);
            }
        })
    })
});

export const { useLazyCheckDownloadLobbyQuery, useInitialDownloadLobbyQuery } = downloadLobbyApi;
