import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { GridColumn as Column, Grid, GridCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GridLoadingOverlay from '../../../common/features/Grid/GridLoadingOverlay';
import useGridLanguage from '../../../common/hooks/useGridLanguage';
import useTranslation from '../../../common/hooks/useTranslation';
import { useGetClientSelectionSettingsQuery } from '../../../redux/api/clientSelectionApi';
import { addToDownloadLobby } from '../../../redux/reducers/alertsSlice';
import FixedFooterWhite from '../common/components/FixedFooterWhite';
import { useDownloadProductReportMutation } from '../redux/api/productReportApi';
import { useProductReportDispatch, useProductReportSelector } from '../redux/hooks';
import { setPageSelected, setPageSize, setSortFields } from '../redux/reducers/productReportGridSettingsSlice';
import {
    applySorting,
    handleSelection,
    resetSelection,
    setIsRedirecting,
    setProductReportData
} from '../redux/reducers/productReportSlice';
import { FormattedGridCell } from './productReport/FormatedGridCell';

const SELECTED_FIELD = 'selected';

const ProductReport = () => {
    const { languageSelected: selectedLanguage } = useProductReportSelector((state) => state.language);
    const { clientSelection } = useProductReportSelector((state) => state.clientSelection);
    const gridSettings = useProductReportSelector((state) => state.productReportGridSettings);
    const { productReportData, isRedirecting, selectedItems } = useProductReportSelector((state) => state.productReport);
    const { initialClientSelection } = useProductReportSelector((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const dispatch = useProductReportDispatch();
    const { user } = useProductReportSelector((state) => state.hydration);

    const { isFetching: clientSelectionSettingsIsFetching } = useGetClientSelectionSettingsQuery();

    const translate = useTranslation();

    const history = useHistory();
    const gridLanguage = useGridLanguage(selectedLanguage);

    const [headerCheckbox, setHeaderCheckbox] = useState(false);

    const { isLoading: clientSelectionSettingsIsLoading } = useGetClientSelectionSettingsQuery();

    // Redirect to Dashboard if US selected
    useEffect(() => {
        if (country?.Code === 'us' && !clientSelectionSettingsIsLoading) {
            dispatch(setIsRedirecting(true));
            history.push('/OverviewDashboard');
        }
    }, [country?.Code, dispatch, history, clientSelectionSettingsIsLoading, isRedirecting]);

    useEffect(() => {
        /* Data only available for CA */
        const gridData = clientSelection?.ClientServices.filter((item) =>
            user?.InternalUser ? item.IsSelected && item.CountryCode === 'ca' : item.CountryCode === 'ca'
        );

        if (gridData)
            dispatch(setProductReportData(gridData.filter((obj, i, arr) => arr.findIndex((obj2) => obj2.Number === obj.Number) === i)));
    }, [clientSelection?.ClientServices, dispatch, user?.InternalUser]);

    // Initial sort
    useEffect(() => {
        dispatch(applySorting(gridSettings.columnSortFields));
    }, [dispatch, gridSettings.columnSortFields, productReportData]);

    const gridDataPerPage = useMemo(() => {
        if (!productReportData || !gridSettings) return [];
        const { pageSelected, pageSize } = gridSettings;

        return productReportData.slice((pageSelected - 1) * pageSize, pageSelected * pageSize);
    }, [productReportData, gridSettings]);

    // Sort Change
    const sortChange = (event: GridSortChangeEvent) => {
        dispatch(setSortFields(event.sort));
        dispatch(applySorting(event.sort));
    };

    // Pagination Section Change
    const pageChange = (event: GridPageChangeEvent) => {
        dispatch(setPageSelected(event.page.skip / event.page.take + 1));
        dispatch(setPageSize(event.page.take));
    };

    const handleHeaderCheckbox = useCallback(() => {}, []);

    /* Header */
    const customHeader = useCallback(
        (props: any) => {
            return props.field === 'selected' ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox value={headerCheckbox} onChange={handleHeaderCheckbox} />
                </div>
            ) : (
                <div className='d-flex justify-content-between grabbable' onClick={props.onClick}>
                    <div className='k-column-title'>{props.title}</div>
                    {props.children}
                </div>
            );
        },
        [headerCheckbox, handleHeaderCheckbox]
    );

    const handleCheckbox = useCallback(
        (event: CheckboxChangeEvent) => {
            const clientId = event.target.name;
            if (clientId) dispatch(handleSelection(clientId));
        },
        [dispatch]
    );
    const [downloadProductReport] = useDownloadProductReportMutation();

    const handleDownloadProductReport = async (clientNumber?: string) => {
        const response = await downloadProductReport({
            clientNumbers: clientNumber && typeof clientNumber === 'string' ? [clientNumber] : selectedItems
        });
        if ('data' in response) dispatch(addToDownloadLobby({ packageId: response.data }));
        dispatch(resetSelection());
    };
    const formattedCell = (props: GridCellProps) => (
        <FormattedGridCell
            {...props}
            selectedItems={selectedItems}
            checkboxChange={handleCheckbox}
            handleDownoloadProductReport={handleDownloadProductReport}
        />
    );

    return (
        <>
            <LocalizationProvider language={gridLanguage.language}>
                <IntlProvider locale={gridLanguage.locale}>
                    <div className='d-flex position-relative flex-fill'>
                        <Grid
                            className='flex-fill'
                            data={gridDataPerPage}
                            pageSize={gridSettings.pageSize}
                            total={productReportData?.length}
                            skip={(gridSettings.pageSelected - 1) * gridSettings.pageSize}
                            take={gridSettings.pageSize}
                            pageable={productReportData && productReportData?.length <= 10 ? false : gridSettings.pageable}
                            onPageChange={pageChange}
                            sortable={{
                                allowUnsort: false
                            }}
                            sort={gridSettings.columnSortFields}
                            onSortChange={sortChange}
                        >
                            <Column field={SELECTED_FIELD} cell={formattedCell} headerCell={customHeader} width='50px' orderIndex={0} />

                            <Column key='Number' field='Number' title={translate('ClientId_Label')} sortable={true} cell={formattedCell} />
                            <Column key='Name' field='Name' title={translate('ClientName_Label')} sortable={true} cell={formattedCell} />
                            <Column
                                field='Download'
                                title=' '
                                headerCell={undefined}
                                cell={formattedCell}
                                sortable={false}
                                resizable={false}
                                width='50%'
                            />
                        </Grid>
                        {clientSelectionSettingsIsFetching && <GridLoadingOverlay />}
                    </div>
                </IntlProvider>
            </LocalizationProvider>

            {!!selectedItems.length && (
                <FixedFooterWhite
                    primaryLabel='Download'
                    primaryIcon={faFileDownload}
                    isPending={false}
                    count={selectedItems.length}
                    countLabel='entries'
                    primaryOnClick={handleDownloadProductReport}
                />
            )}
        </>
    );
};

export default memo(ProductReport);
