import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useInitialDownloadLobbyQuery, useLazyCheckDownloadLobbyQuery } from '../../../redux/api/downloadLobbyApi';
import { usePortalSelector } from '../../../redux/hooks';
import { useDebounce } from '../../hooks/useDebounce';
import type { ClearanceAlert } from '../../models/ClearanceAlert';
import type { DownloadLobbyItem } from '../../models/ReduxSlices';
import AlertToastCard from './AlertToastCard';
import DownloadLobbyToastCard from './DownloadLobbyToastCard';

const ALERTS_TOAST_TIME_KEY = 'alertsToastTime';
const TIMEOUT = 5000;

const AlertsToasts = () => {
    const { alerts, downloadLobby } = usePortalSelector((state) => state.alerts);
    const { pathname } = useLocation();

    useInitialDownloadLobbyQuery();
    const [checkDownloadLobby] = useLazyCheckDownloadLobbyQuery();

    const debounce = useDebounce(TIMEOUT);
    useEffect(() => {
        let isCurrent = true;

        async function scheduleCheck() {
            if (!isCurrent) return;

            const foundPackageId = downloadLobby?.find((i) => i.inProgress)?.packageId;

            if (!foundPackageId) return;

            await checkDownloadLobby(foundPackageId);

            if (isCurrent) debounce(scheduleCheck);
        }

        scheduleCheck();

        return () => {
            isCurrent = false;
        };
    }, [checkDownloadLobby, debounce, downloadLobby]);

    const filteredToasts = useMemo(() => {
        if (!alerts || !downloadLobby) return;

        const alertsToastTime = localStorage.getItem(ALERTS_TOAST_TIME_KEY);

        const sortAlertsDownloads = (arr: (ClearanceAlert | DownloadLobbyItem)[]) =>
            arr.sort((a, b) => {
                const dateA = a.type === 'DOWNLOAD' ? a.createdOn : a.date;
                const dateB = b.type === 'DOWNLOAD' ? b.createdOn : b.date;

                return dayjs(dateB).valueOf() - dayjs(dateA).valueOf();
            });

        const visibleDownloads =
            pathname.startsWith('/Clearances') || pathname.startsWith('/Documents') || pathname.startsWith('/CompleteEntryData')
                ? downloadLobby
                : [];

        if (!alertsToastTime) {
            return [...alerts.notices, ...sortAlertsDownloads([...alerts.statusAlerts, ...visibleDownloads])];
        }

        const filteredNotices = alerts.notices.filter(
            (notice) => dayjs(notice.date).utc().valueOf() > dayjs(alertsToastTime).utc().valueOf()
        );
        const filteredAlerts = alerts.statusAlerts.filter(
            (alert) => dayjs(alert.date).utc().valueOf() > dayjs(alertsToastTime).utc().valueOf()
        );
        const filteredDownloads = visibleDownloads.filter(
            (download) => dayjs(download.createdOn).utc().valueOf() > dayjs(alertsToastTime).utc().valueOf()
        );

        return [...filteredNotices, ...sortAlertsDownloads([...filteredAlerts, ...filteredDownloads])];
    }, [alerts, downloadLobby, pathname]);

    useEffect(() => {
        if (!filteredToasts?.length) return;

        localStorage.setItem(ALERTS_TOAST_TIME_KEY, dayjs().toISOString());
    }, [filteredToasts?.length]);

    return (
        <>
            {filteredToasts?.map((item) => {
                if (item.type === 'DOWNLOAD') {
                    return <DownloadLobbyToastCard key={item.packageId} {...item} />;
                } else if (item.type === 'NOTICE') {
                    return <AlertToastCard key={item.id} {...item} />;
                } else {
                    return <AlertToastCard key={item.id} {...item} />;
                }
            })}
        </>
    );
};

export default AlertsToasts;
