import { GridCellProps } from '@progress/kendo-react-grid';
import { clsx } from 'clsx';
import CarrierIcon from '../../../../../common/features/CarrierIcon';
import { ShipmentsCASS } from '../../../../completeEntryData/common/models/completeEntryDataModels';
import {
    dateConvert,
    numberWithCommas,
    numericCADValuesFormatting
} from '../../../../completeEntryData/utils/complete-entry-data-functions';
import { MOTCodes, MOTMap } from '../../../common/constants/constants-clearances';

interface DataItem extends ShipmentsCASS {
    selected?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    selectedEntries?: string[];
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    selectionChange,
    selectedEntries,
    className,
    style,
    ...rest
}) => {
    let cellContent: any;

    switch (field) {
        case 'Mot':
            cellContent = <CarrierIcon mot={MOTCodes[dataItem.Mot as keyof typeof MOTCodes]} MOTMap={MOTMap} />;
            break;
        case 'ReleaseDate':
            cellContent = dataItem?.ReleaseDate && dateConvert(dataItem.ReleaseDate);
            break;
        case 'CadAcceptDtTime':
            cellContent = dataItem?.CadAcceptDtTime && dateConvert(dataItem.CadAcceptDtTime);
            break;
        case 'AccountingDate':
            cellContent = dataItem?.AccountingDate && dateConvert(dataItem.AccountingDate);
            break;
        case 'PayDueDate':
            cellContent = dataItem?.PayDueDate && dateConvert(dataItem.PayDueDate);
            break;
        case 'GrossWeight':
            cellContent = dataItem?.GrossWeight && numberWithCommas(dataItem.GrossWeight);
            break;
        case 'FreightAmt':
            cellContent = dataItem?.FreightAmt && numberWithCommas(dataItem.FreightAmt);
            break;
        case 'InvSeqNo':
            cellContent = dataItem?.InvSeqNo && numberWithCommas(dataItem.InvSeqNo);
            break;
        case 'TotalInvAmount':
            cellContent = dataItem && dataItem.TotalInvAmount ? numericCADValuesFormatting(dataItem.TotalInvAmount, '00') : null;
            break;
        case 'CudRate1':
            cellContent = dataItem && dataItem.CudRate1 ? numericCADValuesFormatting(dataItem.CudRate1, '00000') : null;
            break;
        case 'CudRate2':
            cellContent = dataItem && dataItem.CudRate2 ? numericCADValuesFormatting(dataItem.CudRate2, '00000') : null;
            break;
        case 'CudRate3':
            cellContent = dataItem && dataItem.CudRate3 ? numericCADValuesFormatting(dataItem.CudRate3, '00000') : null;
            break;
        case 'CustomsDutyAssessed':
            cellContent = dataItem && dataItem.CustomsDutyAssessed ? numericCADValuesFormatting(dataItem.CustomsDutyAssessed, '00') : null;
            break;
        case 'CustomsDutyPayment':
            cellContent = dataItem && dataItem.CustomsDutyPayment ? numericCADValuesFormatting(dataItem.CustomsDutyPayment, '00') : null;
            break;
        case 'ExciseDutyRate':
            cellContent = dataItem && dataItem.ExciseDutyRate ? numericCADValuesFormatting(dataItem.ExciseDutyRate, '00000') : null;
            break;
        case 'ExciseDutyAssessed':
            cellContent = dataItem && dataItem.ExciseDutyAssessed ? numericCADValuesFormatting(dataItem.ExciseDutyAssessed, '00') : null;
            break;
        case 'ExciseDutyPayment':
            cellContent = dataItem && dataItem.ExciseDutyPayment ? numericCADValuesFormatting(dataItem.ExciseDutyPayment, '00') : null;
            break;
        case 'ExciseQty':
            cellContent = dataItem && dataItem.ExciseQty ? numericCADValuesFormatting(dataItem.ExciseQty, '00') : null;
            break;
        case 'ExciseTaxRate':
            cellContent = dataItem && dataItem.ExciseTaxRate ? numericCADValuesFormatting(dataItem.ExciseTaxRate, '00000') : null;
            break;
        case 'ExciseTaxAssessed':
            cellContent = dataItem && dataItem.ExciseTaxAssessed ? numericCADValuesFormatting(dataItem.ExciseTaxAssessed, '00') : null;
            break;
        case 'ExciseTaxPayment':
            cellContent = dataItem && dataItem.ExciseTaxPayment ? numericCADValuesFormatting(dataItem.ExciseTaxPayment, '00') : null;
            break;
        case 'SimaExportPriceDeduction':
            cellContent =
                dataItem && dataItem.SimaExportPriceDeduction ? numericCADValuesFormatting(dataItem.SimaExportPriceDeduction, '00') : null;
            break;
        case 'AddRate1':
            cellContent = dataItem && dataItem.AddRate1 ? numericCADValuesFormatting(dataItem.AddRate1, '00000') : null;
            break;
        case 'AddRate2':
            cellContent = dataItem && dataItem.AddRate2 ? numericCADValuesFormatting(dataItem.AddRate2, '00000') : null;
            break;
        case 'AntiDumpingDutyAssessed':
            cellContent =
                dataItem && dataItem.AntiDumpingDutyAssessed ? numericCADValuesFormatting(dataItem.AntiDumpingDutyAssessed, '00') : null;
            break;
        case 'AdjustmentInterestPayment':
            cellContent =
                dataItem && dataItem.AdjustmentInterestPayment
                    ? numericCADValuesFormatting(dataItem.AdjustmentInterestPayment, '00')
                    : null;
            break;
        case 'CvdRate':
            cellContent = dataItem && dataItem.CvdRate ? numericCADValuesFormatting(dataItem.CvdRate, '00000') : null;
            break;
        case 'CountervailingDutyAssessed':
            cellContent =
                dataItem && dataItem.CountervailingDutyAssessed
                    ? numericCADValuesFormatting(dataItem.CountervailingDutyAssessed, '00')
                    : null;
            break;
        case 'CountervailingDutyPayment':
            cellContent =
                dataItem && dataItem.CountervailingDutyPayment
                    ? numericCADValuesFormatting(dataItem.CountervailingDutyPayment, '00')
                    : null;
            break;
        case 'ValueForTax':
            cellContent = dataItem && dataItem.ValueForTax ? numericCADValuesFormatting(dataItem.ValueForTax, '00') : null;
            break;
        case 'ValueForTaxAssessed':
            cellContent = dataItem && dataItem.ValueForTaxAssessed ? numericCADValuesFormatting(dataItem.ValueForTaxAssessed, '00') : null;
            break;
        case 'GstRate':
            cellContent = dataItem && dataItem.GstRate ? numericCADValuesFormatting(dataItem.GstRate, '00000') : null;
            break;
        case 'GstAssessed':
            cellContent = dataItem && dataItem.GstAssessed ? numericCADValuesFormatting(dataItem.GstAssessed, '00') : null;
            break;
        case 'GstPayment':
            cellContent = dataItem && dataItem.GstPayment ? numericCADValuesFormatting(dataItem.GstPayment, '00') : null;
            break;
        case 'ProvSalesTaxAssessed':
            cellContent =
                dataItem && dataItem.ProvSalesTaxAssessed ? numericCADValuesFormatting(dataItem.ProvSalesTaxAssessed, '00') : null;
            break;
        case 'ProvSalesTaxPayment':
            cellContent = dataItem && dataItem.ProvSalesTaxPayment ? numericCADValuesFormatting(dataItem.ProvSalesTaxPayment, '00') : null;
            break;
        case 'TobaccoTaxAssessed':
            cellContent = dataItem && dataItem.TobaccoTaxAssessed ? numericCADValuesFormatting(dataItem.TobaccoTaxAssessed, '00') : null;
            break;
        case 'TobaccoTaxPayment':
            cellContent = dataItem && dataItem.TobaccoTaxPayment ? numericCADValuesFormatting(dataItem.TobaccoTaxPayment, '00') : null;
            break;
        case 'ProvAlcoholTaxAssessed':
            cellContent =
                dataItem && dataItem.ProvAlcoholTaxAssessed ? numericCADValuesFormatting(dataItem.ProvAlcoholTaxAssessed, '00') : null;
            break;
        case 'ProvAlcoholTaxPayment':
            cellContent =
                dataItem && dataItem.ProvAlcoholTaxPayment ? numericCADValuesFormatting(dataItem.ProvAlcoholTaxPayment, '00') : null;
            break;
        case 'CannabisTaxAssessed':
            cellContent = dataItem && dataItem.CannabisTaxAssessed ? numericCADValuesFormatting(dataItem.CannabisTaxAssessed, '00') : null;
            break;
        case 'CannabisTaxPayment':
            cellContent = dataItem && dataItem.CannabisTaxPayment ? numericCADValuesFormatting(dataItem.CannabisTaxPayment, '00') : null;
            break;
        case 'TotalDTAssessed':
            cellContent = dataItem && dataItem.TotalDTAssessed ? numericCADValuesFormatting(dataItem.TotalDTAssessed, '00') : null;
            break;
        case 'TotalDTPayment':
            cellContent = dataItem && dataItem.TotalDTPayment ? numericCADValuesFormatting(dataItem.TotalDTPayment, '00') : null;
            break;
        case 'AdjustmentInterestAssessed':
            cellContent =
                dataItem && dataItem.AdjustmentInterestAssessed
                    ? numericCADValuesFormatting(dataItem.AdjustmentInterestAssessed, '00')
                    : null;
            break;
        case 'Qty':
            cellContent = dataItem && dataItem.Qty ? numericCADValuesFormatting(dataItem.Qty, '00') : null;
            break;
        case 'AlcoholPercentage':
            cellContent = dataItem && dataItem.AlcoholPercentage ? numericCADValuesFormatting(dataItem.AlcoholPercentage, '00') : null;
            break;
        case 'SimaInvoicePrice':
            cellContent = dataItem && dataItem.SimaInvoicePrice ? (dataItem.SimaInvoicePrice, '00') : null;
            break;
        case 'ExchangeDate':
            cellContent = dataItem?.ExchangeDate && dateConvert(dataItem.ExchangeDate);
            break;
        case 'SimaDateOfSale':
            cellContent = dataItem?.SimaDateOfSale && dateConvert(dataItem.SimaDateOfSale);
            break;
        case 'TimeLimitStartDate':
            cellContent = dataItem?.TimeLimitStartDate && dateConvert(dataItem.TimeLimitStartDate);
            break;
        case 'TimeLimitEndDate':
            cellContent = dataItem?.TimeLimitEndDate && dateConvert(dataItem.TimeLimitEndDate);
            break;
        default:
            cellContent = dataItem[field as keyof typeof dataItem];
    }

    return (
        <td
            className={clsx(
                className,
                selectedEntries?.find((selected) => selected === dataItem?.TransactionNumber)
                    ? 'k-master-row k-state-selected'
                    : 'k-master-row'
            )}
            style={{
                ...style,
                textAlign: field === 'selected' ? 'center' : 'left',
                whiteSpace: 'nowrap',
                paddingTop: '1.85vh',
                paddingBottom: '1.85vh',
                position: field === 'Actions' ? 'sticky' : 'static',
                backgroundColor: field === 'Actions' ? 'white' : 'transparent',
                cursor: 'pointer'
            }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
