import { faInfo, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useState } from 'react';
import Badge from '../../../../../common/features/Badge';
import Spinner from '../../../../../common/features/Spinner';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { addToast } from '../../../../../redux/reducers/toastSlice';
import { TEAM_MEMBER_STATUS_LABELS, TEAM_SEARCH_PARAM_KEYS } from '../../../common/constants/constants-team';
import type { TeamMemberData } from '../../../common/models';
import { useReactivateTeamMemberMutation } from '../../../redux/api/teamApi';
import { useTeamDispatch } from '../../../redux/hooks';
import PendingModal from './PendingModal';

interface UserDetailsNonEditableProps {
    data: TeamMemberData | undefined;
}

const UserDetailsNonEditable: React.FC<UserDetailsNonEditableProps> = ({ data }) => {
    const dispatch = useTeamDispatch();

    const translate = useTranslation();

    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const teamMember = data?.Users?.[0];
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';

    const [reactivateTeamMember, { isLoading: reactivateTeamMemberIsLoading }] = useReactivateTeamMemberMutation();

    const changeTeamMemberStatus = async (userId: string) => {
        try {
            await reactivateTeamMember(userId);
        } catch (error) {
            dispatch(addToast({ type: 'error', message: translate('SomethingWentWrong_Label') }));
        }
    };

    const [pendingModalIsVisible, setPendingModalIsVisible] = useState(false);

    if (!teamMember) return null;

    return (
        <div className='container-fluid p-0'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                <div className='col order-md-3'>
                    <div className='form-group'>
                        <label>{translate('Status_Label')}</label>
                        <br />
                        <div className='d-flex align-items-center'>
                            {teamMember?.IsActive ? (
                                <Badge type='success'>
                                    {teamMember.Status === 'ActiveFeatureSetupInProgress' ? (
                                        <Tooltip label={translate('ClickForMoreInformation_Label')}>
                                            <button
                                                className='btn p-0 rounded-lg d-flex align-items-center'
                                                style={{ fontSize: 'inherit', color: 'inherit' }}
                                                onClick={() => setPendingModalIsVisible(true)}
                                            >
                                                {translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])}
                                                <FontAwesomeIcon className='mx-2' icon={faInfo} />
                                            </button>
                                        </Tooltip>
                                    ) : (
                                        teamMember.Status && translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])
                                    )}
                                </Badge>
                            ) : (
                                <>
                                    <Badge type='danger'>
                                        {!isInactiveStatus && teamMember?.Status !== 'EmailChangedConfirmRequired' ? (
                                            <Tooltip label={translate('PendingUserTooltip_Label')}>
                                                <button
                                                    type='button'
                                                    className='btn p-0 rounded-lg d-flex align-items-center'
                                                    style={{ fontSize: 'inherit', color: 'inherit' }}
                                                    onClick={() => setPendingModalIsVisible(true)}
                                                >
                                                    {teamMember.Status && translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])}
                                                    <FontAwesomeIcon icon={faInfo} className='ml-2' />
                                                </button>
                                            </Tooltip>
                                        ) : (
                                            teamMember?.Status && translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])
                                        )}
                                    </Badge>
                                    {(teamMember.Status === 'Cancelled' ||
                                        teamMember.Status === 'Deactivated' ||
                                        teamMember.Status === 'Expired') && (
                                        <button
                                            type='button'
                                            className='btn btn-outline-success btn-sm ml-2'
                                            onClick={changeTeamMemberStatus.bind(null, userId.toString())}
                                            disabled={reactivateTeamMemberIsLoading}
                                        >
                                            {reactivateTeamMemberIsLoading ? (
                                                <Spinner size='small' color='inherit' className='mr-1' />
                                            ) : (
                                                <FontAwesomeIcon icon={faSyncAlt} className='mr-1' />
                                            )}
                                            {translate('ReactivateUser_Label')}
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col order-md-1'>
                    <div className='form-group'>
                        <label>{translate('FirstName_Label')}</label>
                        <p>{teamMember?.FirstName}</p>
                    </div>
                </div>
                <div className='col order-md-2'>
                    <div className='form-group'>
                        <label>{translate('LastName_Label')}</label>
                        <p>{teamMember?.LastName}</p>
                    </div>
                </div>
                <div className='col order-md-4'>
                    <div className='form-group'>
                        <label>{translate('Email_Label')}</label>
                        <p>{teamMember?.Email}</p>
                    </div>
                </div>
                <div className='col order-md-5'>
                    <div className='form-group'>
                        <label>{translate('PhoneNumber_Label')}</label>
                        <p>{teamMember?.Phone}</p>
                    </div>
                </div>
                <div className='col order-md-6'>
                    <div className='form-group'>
                        <label>{translate('MobileNumberWithoutOptional_Label')}</label>
                        <p>{teamMember?.MobileNumber}</p>
                    </div>
                </div>
                <div className='col order-md-7'>
                    <div className='form-group'>
                        <label>{translate('Company_Label')}</label>
                        <p>{teamMember?.CompanyName}</p>
                    </div>
                </div>
                <div className='col order-md-8'>
                    <div className='form-group'>
                        <label>{translate('UserRole_Label')}</label>
                        <p>{teamMember?.Roles?.find((role) => role.IsSelected)?.Text}</p>
                    </div>
                </div>
                <div className='col order-md-9'>
                    <div className='form-group'>
                        <label>{translate('ExpirationDateWithoutOptional_Label')}</label>
                        <p>
                            {teamMember?.ExpirationDate ? dayjs(teamMember?.ExpirationDate).format('MM-DD-YYYY') : translate('Never_Label')}
                        </p>
                    </div>
                </div>
            </div>
            <PendingModal isOpen={pendingModalIsVisible} setIsOpen={setPendingModalIsVisible} />
        </div>
    );
};

export default UserDetailsNonEditable;
