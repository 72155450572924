import type { FormEvent } from 'react';
import Button from '../../../../../common/features/Button';
import Modal from '../../../../../common/features/Modal';
import Spinner from '../../../../../common/features/Spinner';
import useTranslation from '../../../../../common/hooks/useTranslation';

interface UnsubscribeModalProps {
    clientNumber: string | null;
    isPending: boolean;
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: () => void;
}

export default function UnsubscribeModal({ isVisible, setIsVisible, clientNumber, isPending, onSubmit }: UnsubscribeModalProps) {
    const translate = useTranslation();

    const handleUnsubscribe = async (e: FormEvent) => {
        e.preventDefault();

        onSubmit();
    };

    const closeModal = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <Modal
            header={translate('AreYouSureYouWantToUnsubscribe_Label', clientNumber)}
            title={translate('YouWillBeUnsubscribedFromAll_Label')}
            toggleModal={setIsVisible}
        >
            <form onSubmit={handleUnsubscribe}>
                <Button
                    variant='ghost'
                    aria-label={translate('Close_Label')}
                    className='btn-lg mr-2'
                    data-dismiss='modal'
                    onClick={closeModal}
                >
                    {translate('Close_Label')}
                </Button>
                <Button
                    type='submit'
                    variant='danger'
                    aria-label={translate('YesUnsubscribe_Label')}
                    className='btn-lg'
                    data-dismiss='modal'
                    disabled={isPending}
                >
                    {isPending && <Spinner size='small' color='inherit' className='mr-2' />}
                    {translate('YesUnsubscribe_Label')}
                </Button>
            </form>
        </Modal>
    );
}
