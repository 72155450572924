import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PortalRoutes } from '../../../../common/constants/constants-portal';
import { useSearchParams } from '../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../common/hooks/useTranslation';
import { addToast } from '../../../../redux/reducers/toastSlice';
import { TEAM_SEARCH_PARAM_KEYS } from '../../common/constants/constants-team';
import type { ClientsAndPermissionsActions } from '../../common/features/ClientsAndPermissions';
import ClientsAndPermissions from '../../common/features/ClientsAndPermissions';
import Header from '../../common/features/Header';
import { useMappedParentServices } from '../../common/hooks/useMappedParentServices';
import { useTeamMemberDetailsQuery, useUpdateTeamMemberMutation } from '../../redux/api/teamApi';
import { useTeamDispatch } from '../../redux/hooks';
import TeamMemberBreadcrumb from './EditTeamMemberBreadcrumb';
import EditTeamMemberFooter from './EditTeamMemberFooter';
import type { EditTeamMemberUserDetailsActions } from './EditTeamMemberUserDetails';
import EditTeamMemberUserDetails from './EditTeamMemberUserDetails';

export default function EditTeamMember() {
    const dispatch = useTeamDispatch();

    const translate = useTranslation();

    const history = useHistory();
    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data: teamMemberData, isFetching: teamMemberIsFetching } = useTeamMemberDetailsQuery(userId.toString());
    const { data: parentServicesData, isLoading: parentServicesIsLoading } = useMappedParentServices(teamMemberData?.ParentServices);

    const editTeamMemberUserDetails = useRef<EditTeamMemberUserDetailsActions>(null);
    const clientsAndPermissions = useRef<ClientsAndPermissionsActions>(null);

    const [error, setError] = useState({ userDetails: false, clientsAndPermissions: false });

    const [updateTeamMember, { isLoading: updateTeamMemberIsPending }] = useUpdateTeamMemberMutation();

    const handleSubmit = async () => {
        const userDetails = editTeamMemberUserDetails.current?.getData();
        const parentServices = clientsAndPermissions.current?.getData();

        const userDetailsIsValid = editTeamMemberUserDetails.current?.validate() || false;
        const clientsAndPermissionsIsValid = clientsAndPermissions.current?.validate(parentServices) || false;

        if (!clientsAndPermissionsIsValid || !userDetailsIsValid) {
            setError({ userDetails: !userDetailsIsValid, clientsAndPermissions: !clientsAndPermissionsIsValid });
            return;
        }

        setError({ userDetails: false, clientsAndPermissions: false });

        try {
            const response = await updateTeamMember({
                data: { ...teamMemberData, ParentServices: parentServices },
                editData: { ...userDetails, ParentServices: parentServices }
            }).unwrap();

            if (response === 'SuccessfulSubmit') {
                return history.push(PortalRoutes.team);
            }
        } catch (error) {
            dispatch(addToast({ type: 'error', message: translate('SomethingWentWrong_Label') }));
        }
    };

    return (
        <>
            <TeamMemberBreadcrumb />
            <Header title={translate('TeamMemberDetails_Label')} />
            <div className='container-fluid py-3'>
                <EditTeamMemberUserDetails ref={editTeamMemberUserDetails} />
                <ClientsAndPermissions
                    ref={clientsAndPermissions}
                    teamMemberData={teamMemberData}
                    parentServicesData={parentServicesData}
                    isLoading={teamMemberIsFetching || parentServicesIsLoading}
                />
            </div>
            <EditTeamMemberFooter
                isPending={updateTeamMemberIsPending}
                isDisabled={teamMemberIsFetching || parentServicesIsLoading}
                error={error}
                onSubmit={handleSubmit}
            />
        </>
    );
}
