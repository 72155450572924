import $ from 'jquery';
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import { accessManagement as constants } from '../../constants/mlp-constants';
import * as actionCreators from '../../store/actions/index';
import { formatTranslated } from '../../utils/mlp-utils';
import ClientAdminSearchBox from './ClientAdminSearchBox';
import NewClientAdminForm from './NewClientAdminForm';
import SalesforceUserSearch from './SalesforceUserSearch';

class UserSearchForm extends Component {
    state = {
        pageHeader: '',
        navigationLevel1: '',
        navigationLevel2: '',
        pageContent: <div />
    };
    componentDidMount() {
        //model mount
        if (this.props.location.state && this.props.location.state.userId) {
            this.props.onTransferRightsNewClientAdmin(this.props.location.state.userId);
        } else {
            this.props.onInitUserSearch();
        }
        //translations mount
        if (this.props.vm) {
            if (
                (this.props.vm.OriginalClientAdmin === null || this.props.vm.SelectedSfUser === null) &&
                (!this.props.isInternalUserEdit || !this.state.isInternalUserEdit)
            ) {
                this.props.onInitTranslations('AccessManagement.AccessManagement.SearchUsers');
            } else if (
                (this.props.vm.OriginalClientAdmin === null || this.props.vm.SelectedSfUser === null) &&
                (this.props.isInternalUserEdit || this.state.isInternalUserEdit)
            ) {
                this.props.onInitTranslations('AccessManagement.AccessManagement.SearchInternalUsers');
            } else if (this.props.vm.NewClientAdmin !== null || this.props.vm.PendingClientAdmin !== null) {
                this.props.onInitTranslations('AccessManagement.AccessManagement.SelectNewClientAdmin');
            } else {
                this.props.onInitTranslations('AccessManagement.AccessManagement.SelectNewClientAdmin');
            }
        } else {
            this.props.onInitTranslations('AccessManagement.AccessManagement.SearchUsers');
        }
    }

    componentDidUpdate(prevProps) {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
        if (this.props.vm.NewClientAdmin !== null && prevProps.vm.NewClientAdmin === null) {
            this.props.onInitTranslations('AccessManagement.AccessManagement.SelectNewClientAdmin');
        }
        if (this.props.location.pathname.includes('SearchUsers') && this.state.isInternalUserEdit) {
            this.setState({ isInternalUserEdit: false });
            this.props.onInitTranslations('AccessManagement.AccessManagement.SearchUsers');
        }
        if (this.props.location.pathname.includes('SearchInternalUsers') && !this.state.isInternalUserEdit) {
            this.setState({ isInternalUserEdit: true });
            this.props.onInitTranslations('AccessManagement.AccessManagement.SearchInternalUsers');
        }
    }

    findComponentUrl = (path) => {
        path = path.includes('?') ? path.substring(0, path.indexOf('?')) : path;
        return path.substring(1, path.length).replaceAll('/', '.');
    };

    transferRevokeAccess = () => {
        $('#overlay').show();
        axios
            .post(constants.COMPLETE_CLIENT_ADMIN_TRANSFER_URL_REVOKE, this.props.vm)
            .then((response) => {
                if (response.data.redirectUrl === 'TransferSuccessful') {
                    this.props.history.push({
                        pathname: '/SuccessfullSubmit',
                        state: {
                            backToPath: '/Dashboard',
                            backToLabel: 'BackToDashboard_Label',
                            phraseGroup: 'AccessManagement.AccessManagement.TransferSuccessful'
                        }
                    });
                } else if (response.data.includes('CentralAdminTransferError') || response.data.includes('PaymentsAdminTransferError')) {
                    const validationMessage = this.formatValidationError(response.data);
                    toastr.error(this.props.translations.Phrases[validationMessage]);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        this.props.history.push('/BasicError');
                    }
                }
            })
            .finally(() => {
                this.props.onResetStateUserSearch();
                $('#overlay').hide();
            });
    };

    transferRetainAccess = () => {
        $('#overlay').show();
        axios
            .post(constants.COMPLETE_CLIENT_ADMIN_TRANSFER_URL_RETAIN, this.props.vm)
            .then((response) => {
                if (response.data.redirectUrl === 'TransferSuccessful') {
                    this.props.history.push({
                        pathname: '/SuccessfullSubmit',
                        state: {
                            backToPath: '/Dashboard',
                            backToLabel: 'BackToDashboard_Label',
                            phraseGroup: 'AccessManagement.AccessManagement.TransferSuccessful'
                        }
                    });
                } else if (response.data.includes('CentralAdminTransferError')) {
                    const validationMessage = this.formatValidationError(response.data);
                    toastr.error(this.props.translations.Phrases[validationMessage]);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        this.props.history.push('/BasicError');
                    }
                }
            })
            .finally(() => {
                this.props.onResetStateUserSearch();
                $('#overlay').hide();
            });
    };

    formatValidationError = (errorMessage) => {
        const errorArray = errorMessage.split(':');
        const parsedMessage = errorArray[1]
            .replace(/["}]/g, '')
            .replace(/(\r\n|\n|\r)/gm, '')
            .trim();
        return parsedMessage;
    };

    formatResultBuilder = (i) => {
        const address = i.Address || '';
        const addressArray = address.split(',');
        let ContactAddress = <div></div>;
        if (addressArray.length > 1) {
            ContactAddress = (
                <div className='data-row'>
                    <div className='result-label'> Address: </div>
                    <div className='result-value'>
                        <div>{addressArray[0]}</div>
                        <div>
                            {addressArray[1]}, {addressArray[2]}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='user-search-results'>
                <div>
                    <strong> {i.FullName} </strong>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Email:</div>
                    <div className='result-value'>{i.Email}</div>
                    <div className='result-label'>Phone:</div>
                    <div className='result-value'>{i.Phone}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Company:</div>
                    <div className='result-value'>{i.CompanyName}</div>
                    <div className='result-label'> ContactId: </div>
                    <div className='result-value'>{i.SalesforceId} </div>
                </div>
                {ContactAddress}
            </div>
        );
    };
    onSelectClientAdmin = (item) => {
        this.props.history.push({
            pathname: '/AccessManagement',
            state: {
                userId: item.UserId
            }
        });
    };

    onSelectInternalUser = (item) => {
        this.props.history.push({
            pathname: '/AccessManagement/AccessManagement/InternalUser',
            state: {
                userId: item.UserId
            }
        });
    };

    cancelTransfer = () => {
        this.props.onResetStateUserSearch();
        return <Redirect to={constants.SEARCH_USERS_URL} />;
    };

    render() {
        if (
            this.props.translations.PhraseGroupCode === 'AccessManagement.AccessManagement.SearchUsers' ||
            this.props.translations.PhraseGroupCode === 'AccessManagement.AccessManagement.SearchInternalUsers' ||
            this.props.translations.PhraseGroupCode === 'AccessManagement.AccessManagement.SelectNewClientAdmin'
        ) {
            let pageHeader = this.state.pageHeader;
            let navigationLevel1 = this.state.navigationLevel1;
            let navigationLevel2 = this.state.navigationLevel2;
            let pageContent = this.state.pageContent;

            if (this.props.vm.OriginalClientAdmin === null) {
                pageHeader = this.props.translations.Phrases['FindExistingClientAdmin_PageHeader'];
                navigationLevel1 = this.props.translations.Phrases['FindExistingClientAdmin_NavLevel1'];
                navigationLevel2 = this.props.translations.Phrases['FindExistingClientAdmin_NavLevel2'];
                pageContent = (
                    <div>
                        <h4 className='bold'> {this.props.translations.Phrases['SearchClientAdmins_Label']} </h4>
                        <ClientAdminSearchBox
                            url={
                                this.props.isInternalUserEdit || this.state.isInternalUserEdit
                                    ? '/AccessManagement/GetInternalUsers'
                                    : '/AccessManagement/GetUsers'
                            }
                            formatResultBuilder={this.formatResultBuilder}
                            onSelectItem={
                                this.props.isInternalUserEdit || this.state.isInternalUserEdit
                                    ? this.onSelectInternalUser
                                    : this.onSelectClientAdmin
                            }
                        />
                    </div>
                );
            } else if (
                this.props.vm.SelectedSfUser === null ||
                (this.props.vm.ValidationErrors != null &&
                    (this.props.vm.ValidationErrors['emailChanged'] || this.props.vm.ValidationErrors['emailExistsAsPendingUser']))
            ) {
                pageHeader = this.props.translations.Phrases['SelectNewClientAdmin_PageHeader'];
                navigationLevel1 = this.props.translations.Phrases['SelectNewClientAdmin_NavLevel1'];
                navigationLevel2 = this.props.translations.Phrases['SelectNewClientAdmin_NavLevel2'];
                pageContent = (
                    <div>
                        <h4 className='bold'> {this.props.translations.Phrases['ExistingSFDCContacts_Label']} </h4>
                        <SalesforceUserSearch vm={this.props.vm} formatResultBuilder={this.formatResultBuilder} />
                    </div>
                );
            } else if (this.props.vm.NewClientAdmin !== null) {
                pageHeader = this.props.translations.Phrases['CompleteTransfer_PageHeader'];
                navigationLevel1 = this.props.translations.Phrases['CompleteTransfer_NavLevel1'];
                navigationLevel2 = this.props.translations.Phrases['CompleteTransfer_NavLevel2'];
                const payAdmin =
                    this.props.vm.OriginalClientAdmin !== null
                        ? this.props.vm.OriginalClientAdmin.Roles.find((x) => x.Value === constants.PAY_ADMIN_ROLE_NAME && x.IsSelected)
                        : undefined;
                const transferWithRetainOldButton =
                    payAdmin === undefined ? (
                        <button className='button blue small wide' onClick={this.transferRetainAccess}>
                            {this.props.translations.Phrases['TransferRetainAccess_Label']
                                ? formatTranslated(
                                      'TransferRetainAccess_Label',
                                      this.props.translations.Phrases,
                                      this.props.vm.OriginalClientAdmin.FullName
                                  )
                                : ''}
                        </button>
                    ) : (
                        ''
                    );
                pageContent = (
                    <div>
                        <div className='clear-padding message'>
                            {this.props.translations.Phrases['TransferMessage1']
                                ? formatTranslated(
                                      'TransferMessage1',
                                      this.props.translations.Phrases,
                                      this.props.vm.OriginalClientAdmin.FullName,
                                      this.props.vm.NewClientAdmin.FullName
                                  )
                                : ''}
                            {this.props.translations.Phrases['TransferMessage2']
                                ? formatTranslated(
                                      'TransferMessage2',
                                      this.props.translations.Phrases,
                                      this.props.vm.NewClientAdmin.FullName
                                  )
                                : ''}
                        </div>
                        <br />
                        <div className='message clear-padding pull-left'>
                            {this.props.translations.Phrases['TransferMessage3']
                                ? formatTranslated(
                                      'TransferMessage3',
                                      this.props.translations.Phrases,
                                      this.props.vm.OriginalClientAdmin.FullName
                                  )
                                : ''}
                        </div>
                        <br />
                        <br />
                        <div className='row'>
                            <div className='col-sm-8'>
                                <button className='button blue small wide' onClick={this.transferRevokeAccess}>
                                    {this.props.translations.Phrases['TransferRevokeAccess_Label']
                                        ? formatTranslated(
                                              'TransferRevokeAccess_Label',
                                              this.props.translations.Phrases,
                                              this.props.vm.OriginalClientAdmin.FullName
                                          )
                                        : ''}
                                </button>
                                <br />
                                <br />
                                {transferWithRetainOldButton}
                                <br />
                                <br />
                                <button className='btn btn-link' onClick={this.cancelTransfer}>
                                    {this.props.translations.Phrases['CancelTransfer_Label']}
                                </button>
                            </div>
                        </div>
                    </div>
                );
            } else if (this.props.vm.PendingClientAdmin !== null) {
                pageHeader = this.props.translations.Phrases['NewClientAdmin_PageHeader'];
                navigationLevel1 = this.props.translations.Phrases['NewClientAdmin_NavLevel1'];
                navigationLevel2 = this.props.translations.Phrases['NewClientAdmin_NavLevel2'];
                pageContent = <NewClientAdminForm ViewModel={this.props.vm} ValidationMessages={this.props.ValidationMessages} />;
            }
            return (
                <div>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {navigationLevel1}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <span>{navigationLevel2}</span>
                                </nav>
                                <h1 className='bold-title'>{pageHeader}</h1>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div className='col-sm-10 access-admin-user-search'>{pageContent}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        vm: state.userSearch.model,
        translations: state.translations.model,
        ValidationMessages: state.userSearch.validationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitUserSearch: () => dispatch(actionCreators.initUserSearch()),
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onTransferRightsNewClientAdmin: (userId) => dispatch(actionCreators.transferRightsNewClientAdmin(userId)),
        onResetStateUserSearch: () => dispatch(actionCreators.resetStateUserSearch())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSearchForm));
