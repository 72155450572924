import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PortalRoutes } from '../../common/constants/constants-portal';
import ProfilePage from './screens/Profile/ProfilePage';
import RequestSuccessPage from './screens/RequestSuccessPage';
import RequestUpdatePage from './screens/RequestUpdatePage';

const Profile = memo(function Profile() {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path={PortalRoutes.profile} exact component={ProfilePage} />
                    <Route path={`${PortalRoutes.profile}/RequestUpdate`} exact component={RequestUpdatePage} />
                    <Route path={`${PortalRoutes.profile}/RequestSuccess`} exact component={RequestSuccessPage} />
                </Switch>
            </div>
        </main>
    );
});

export default Profile;
