import type { GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { GridColumn as Column, Grid } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { forwardRef, useCallback, useImperativeHandle, useReducer } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import type { ClientService, ParentService, TeamMemberData } from '../models';
import ClientsAndPermissionsTableCheckboxCell from './ClientsAndPermissionsTableCheckboxCell';
import ClientsAndPermissionsTableHeaderCell from './ClientsAndPermissionsTableHeaderCell';
import ClientsAndPermissionsTableNumberCell from './ClientsAndPermissionsTableNumberCell';

const MLP_FEATURE_LABEL = 'My Livingston Portal';

const areAllFeaturesByIndexSelected = (
    clientServices:
        | (ClientServiceWithoutFeatures & {
              Features: ExtendedFeature[];
          })[]
        | ClientService[],
    featureIndex: number
) =>
    clientServices.every((clientService) => {
        const feature = clientService.Features[featureIndex];

        return feature === null || feature?.IsSelected;
    });

const findFeatureIndex = (
    clientServices:
        | (ClientServiceWithoutFeatures & {
              Features: ExtendedFeature[];
          })[]
        | ClientService[],
    featureName: string
) => {
    const outerIndex = clientServices.findIndex((clientService) => clientService.Features.some((feature) => feature?.Name === featureName));

    if (outerIndex === -1) return -1;

    return clientServices[outerIndex].Features.findIndex((feature) => feature?.Name === featureName);
};

const mapClientServices = (clientServices: ClientService[], availableFeatures: ParentService['AvailableFeatures']) =>
    clientServices.map((clientService) => ({
        ...clientService,
        Features:
            availableFeatures?.map((feature) => {
                const foundFeature = clientService.Features.find((clientFeature) => clientFeature?.Name === feature.Name);

                if (foundFeature) {
                    return { ...foundFeature, InitialSelected: foundFeature.IsSelected };
                }

                return null;
            }) ?? []
    }));

const createInitialState = (parentService: ParentService) => ({
    ...parentService,
    ClientServices: mapClientServices(parentService.ClientServices, parentService.AvailableFeatures),
    selectAll:
        parentService.AvailableFeatures?.reduce<Record<string, boolean>>((acc, curr) => {
            if (curr.Name) {
                const updatedClientServices = mapClientServices(parentService.ClientServices, parentService.AvailableFeatures);
                const featureIndex = findFeatureIndex(updatedClientServices, curr.Name);
                acc[curr.Name] = areAllFeaturesByIndexSelected(updatedClientServices, featureIndex);
            }
            return acc;
        }, {}) ?? {}
});

const isFeatureDisabled = (clientService: ClientServiceWithoutFeatures, feature: ExtendedFeature | undefined) =>
    feature?.IsPending ||
    (clientService.StatusCodeName !== undefined &&
        clientService.StatusCodeName !== 'Active' &&
        clientService.StatusCodeName !== '' &&
        feature?.Name !== 'My Livingston Portal' &&
        feature?.Name !== 'Clearances' &&
        !feature?.InitialSelected);

type Action =
    | { type: 'TOGGLE_CHECKBOX'; payload: { clientServiceIndex: number; featureName: string } }
    | { type: 'TOGGLE_SELECT_ALL'; payload: string };

type ExtendedFeature = (ClientService['Features'][number] & { InitialSelected?: null | boolean }) | null;
type ClientServiceWithoutFeatures = Omit<ClientService, 'Features'>;
type ParentServiceWithoutClientServices = Omit<ParentService, 'ClientServices'>;
type State = ParentServiceWithoutClientServices & {
    ClientServices: (ClientServiceWithoutFeatures & {
        Features: ExtendedFeature[];
    })[];
    selectAll: Record<string, boolean>;
};

function reducer(state: State, action: Action) {
    const { type, payload } = action;

    switch (type) {
        case 'TOGGLE_CHECKBOX': {
            const updatedClientServices = [...state.ClientServices];

            const updatedFeatures = [...state.ClientServices[payload.clientServiceIndex].Features];

            const featureIndex = updatedFeatures.findIndex((feature) => feature?.Name === payload.featureName);

            if (featureIndex === -1) return state;

            updatedFeatures[featureIndex] = {
                ...updatedFeatures[featureIndex],
                IsSelected: !updatedFeatures[featureIndex]?.IsSelected
            };

            const mlpFeatureIndex = updatedFeatures.findIndex((feature) => feature?.Name === MLP_FEATURE_LABEL);

            if (mlpFeatureIndex !== -1 && featureIndex !== mlpFeatureIndex && updatedFeatures[featureIndex]?.IsSelected === true) {
                updatedFeatures[mlpFeatureIndex] = { ...updatedFeatures[mlpFeatureIndex], IsSelected: true };
            }

            updatedClientServices[payload.clientServiceIndex] = {
                ...updatedClientServices[payload.clientServiceIndex],
                Features: updatedFeatures
            };

            const areAllFeaturesSelected = areAllFeaturesByIndexSelected(updatedClientServices, featureIndex);
            const areAllMLPFeaturesSelected = areAllFeaturesByIndexSelected(updatedClientServices, mlpFeatureIndex);

            return {
                ...state,
                ClientServices: updatedClientServices,
                selectAll: {
                    ...state.selectAll,
                    [payload.featureName]: areAllFeaturesSelected,
                    [MLP_FEATURE_LABEL]: areAllMLPFeaturesSelected
                }
            };
        }

        case 'TOGGLE_SELECT_ALL': {
            const isMlpFeatureSelected = payload === MLP_FEATURE_LABEL;
            const mlpFeatureIndex = findFeatureIndex(state.ClientServices, MLP_FEATURE_LABEL);

            const updatedClientServices = state.ClientServices.map((clientService) => ({
                ...clientService,
                Features: clientService.Features.map((feature, _, self) => {
                    const isTargetFeature = payload === feature?.Name;
                    const isMlpFeature = feature?.Name === MLP_FEATURE_LABEL;
                    const isFeatureAvailable = self.some((feature) => feature?.Name === payload);
                    const isDisabled = isFeatureDisabled(clientService, feature);

                    if (isTargetFeature) {
                        return { ...feature, IsSelected: isDisabled ? feature.IsSelected : !state.selectAll[payload] };
                    }

                    if (!isMlpFeatureSelected && isMlpFeature && isFeatureAvailable && !state.selectAll[payload]) {
                        return { ...feature, IsSelected: isDisabled ? feature.IsSelected : true };
                    }

                    return feature;
                })
            }));

            return {
                ...state,
                ClientServices: updatedClientServices,
                selectAll: {
                    ...state.selectAll,
                    [payload]: !state.selectAll[payload],
                    [MLP_FEATURE_LABEL]: isMlpFeatureSelected
                        ? !state.selectAll[payload]
                        : areAllFeaturesByIndexSelected(updatedClientServices, mlpFeatureIndex)
                }
            };
        }

        default:
            return state;
    }
}

export type ClientsAndPermissionsTableActions = {
    getData: () => ParentService;
};

interface ClientsAndPermissionsTableProps {
    teamMemberData: TeamMemberData;
    parentServiceData: ParentService;
    isDisabled?: boolean;
}

interface CellProps extends GridCellProps {
    dataItem: ClientService;
}

const ClientsAndPermissionsTable = forwardRef<ClientsAndPermissionsTableActions, ClientsAndPermissionsTableProps>(
    function ClientsAndPermissionsTable({ teamMemberData, parentServiceData, isDisabled: isDisabledProp }, ref) {
        const [state, dispatch] = useReducer(reducer, parentServiceData, createInitialState);

        const columns = parentServiceData.AvailableFeatures;
        const title = parentServiceData.Number + ' - ' + parentServiceData.Name;
        const clientServiceData = parentServiceData.ClientServices;
        const adminRole = teamMemberData.AdminRole;
        const translate = useTranslation();

        const handleSelectAll = useCallback((featureName: string) => {
            dispatch({ type: 'TOGGLE_SELECT_ALL', payload: featureName });
        }, []);

        const headerCell = useCallback(
            (props: GridHeaderCellProps) => {
                const isDisabled = clientServiceData?.every((clientService) => {
                    const feature = clientService.Features.find((feature) => feature.Name === props.field);

                    return isFeatureDisabled(clientService, feature);
                });

                return (
                    <ClientsAndPermissionsTableHeaderCell
                        adminRole={adminRole}
                        featureName={props.title || ''}
                        isDisabled={isDisabledProp || isDisabled}
                        onToggleSelectAll={handleSelectAll}
                        selectAll={state.selectAll}
                    />
                );
            },
            [adminRole, isDisabledProp, handleSelectAll, state.selectAll, clientServiceData]
        );

        const numberCaptionCell = useCallback(
            (props: GridCellProps) => <ClientsAndPermissionsTableNumberCell {...props} data={clientServiceData} />,
            [clientServiceData]
        );

        const handleSelect = useCallback((payload: { clientServiceIndex: number; featureName: string }) => {
            dispatch({ type: 'TOGGLE_CHECKBOX', payload });
        }, []);

        const bodyCell = useCallback(
            (props: CellProps) => {
                const feature = props.dataItem?.Features?.find((feature) => feature?.Name === props.field);
                const isDisabled = isFeatureDisabled(props.dataItem, feature);

                const isEmptyCell = !props.dataItem.Features.some((feature) => feature?.Name === props.field);

                if (isEmptyCell) return <td></td>;

                return (
                    <ClientsAndPermissionsTableCheckboxCell
                        key={String(feature?.IsSelected)}
                        {...props}
                        isDisabled={isDisabledProp || isDisabled}
                        isChecked={feature?.IsSelected}
                        onSelect={handleSelect}
                    />
                );
            },
            [handleSelect, isDisabledProp]
        );

        useImperativeHandle(ref, () => {
            return {
                getData() {
                    const filteredClientServices = state.ClientServices.map((clientService) => ({
                        ...clientService,
                        Features: clientService.Features.filter((feature) => feature !== null)
                    })) as ClientService[];

                    return {
                        ...state,
                        ClientServices: filteredClientServices
                    };
                }
            };
        });

        return (
            <>
                <div className='table-heading py-3 px-2'>{title}</div>
                <Grid className='clients-and-permissions-table mb-4' data={state.ClientServices}>
                    <Column field='Number' title={translate('ClientsAndPermissionsNumber_Label')} cell={numberCaptionCell} />
                    <Column field='Name' title={translate('ClientsAndPermissionsName_Label')} />
                    {columns?.map((feature) => {
                        return (
                            <Column
                                key={feature.Name}
                                field={feature.Name || undefined}
                                title={feature.Name || undefined}
                                headerCell={headerCell}
                                cell={bodyCell}
                            />
                        );
                    })}
                </Grid>
            </>
        );
    }
);

export default ClientsAndPermissionsTable;
