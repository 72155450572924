import React from 'react';
import Button from '../Button';
import ModalWrapper from '../Wrappers/ModalWrapper';

interface ConfirmationModalProps {
    title: string;
    description: string;
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    primaryButton?: React.ReactNode;
    secondaryButton?: React.ReactNode;
    onPrimary?: () => void;
    onSecondary?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    title,
    description,
    primaryButtonLabel,
    secondaryButtonLabel,
    primaryButton,
    secondaryButton,
    onPrimary,
    onSecondary
}) => {
    return (
        <ModalWrapper onEscape={onSecondary}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className='modal-body'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {title}
                        </h3>
                        <p className='note mb-0 notification-message'>{description}</p>
                    </div>
                    <div className='modal-footer'>
                        {secondaryButton || (
                            <Button variant='secondary' size='medium' onClick={onSecondary}>
                                {secondaryButtonLabel}
                            </Button>
                        )}
                        {primaryButton || (
                            <Button variant='danger' size='medium' onClick={onPrimary}>
                                {primaryButtonLabel}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ConfirmationModal;
