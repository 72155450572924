import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormEvent } from 'react';
import { useRef, useState } from 'react';
import Button from '../../../../../common/features/Button';
import Spinner from '../../../../../common/features/Spinner';
import { isAddContactFormValid } from '../../../../../common/functions/validators';
import useGTMEventTracking from '../../../../../common/hooks/useGTMEventTracking';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../../../common/models/ContactsModals';
import { Contact as ContactType } from '../../../common/models/Contact';
import { useContactsDispatch, useContactsSelector } from '../../../redux/hooks';
import { addNewContact, setFormIsSubmitted } from '../../../redux/reducers/contactsSlice';
import Contact from './Contact';

interface ContactInfoFormProps {
    modal: ContactsModals;
    selectedContact?: any;
    isSubmitting?: boolean;
    onSubmit: (e: FormEvent) => void;
}

const NEW_CONTACTS_LIMIT = 10;

export default function ContactInfoForm({ modal, isSubmitting, selectedContact, onSubmit }: ContactInfoFormProps) {
    const { newContacts, editContact } = useContactsSelector((state) => state.contacts);
    const { user } = useContactsSelector((state) => state.hydration);
    const dispatch = useContactsDispatch();

    const translate = useTranslation();

    const [displayCarrierLabel, setDisplayCarrierLabel] = useState(false);
    const addContactsConfirmRef = useRef(null);
    useGTMEventTracking('Confirm_Contact_Team_CPEvt', { location: 'Profile' }, addContactsConfirmRef);
    const hasCarrierRole = newContacts.some((contact: ContactType) => contact.RoleLabel.includes('Carrier'));

    const hasReachedContactLimit = newContacts.length >= NEW_CONTACTS_LIMIT;

    const handleAddNewContact = () => {
        dispatch(setFormIsSubmitted({ value: true }));

        if (newContacts.length! < NEW_CONTACTS_LIMIT) {
            dispatch(addNewContact());
            dispatch(setFormIsSubmitted({ value: false }));
        }
    };

    return (
        <>
            <form className='modal-body' onSubmit={onSubmit}>
                <div className='d-flex flex-column align-items-end overflow-auto'>
                    <div className='table-responsive' id='scrollTable'>
                        <table className='table w-100 add-subscriber input-row-overflow'>
                            <thead>
                                <tr>
                                    {user?.InternalUser && <th scope='col'>{translate('AdminCompany_Label')}</th>}
                                    <th scope='col'>{translate('Name_Label')}</th>
                                    <th scope='col'>{translate('Email_Label')}</th>
                                    <th scope='col'>{translate('MobileNumber_Label')}</th>
                                    <th scope='col'>{translate('Role_Label')}</th>
                                    {displayCarrierLabel || hasCarrierRole ? <th scope='col'>{translate('CarrierCode_Label')}</th> : null}
                                    <th scope='col'>{translate('Language_Label')}</th>
                                    <th scope='col' className='row-action bg-white'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {newContacts.map((contact) => (
                                    <Contact
                                        key={contact.RowId}
                                        id={contact.RowId}
                                        fullName={contact.FullName}
                                        email={contact.Email}
                                        phone={contact.MobileNumber ?? ''}
                                        role={contact.RoleLabel}
                                        language={contact.Language}
                                        modal={modal}
                                        selectedContact={selectedContact}
                                        setDisplayCarrierLabel={setDisplayCarrierLabel}
                                        hasCarrierRole={hasCarrierRole}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {!modal.edit &&
                        (hasReachedContactLimit ? (
                            <div className='text-center error-message'>{translate('MaxRowLimit_Label')}</div>
                        ) : (
                            <div className='p-1 w-100'>
                                <Button
                                    variant='tertiary-blue'
                                    className='btn-block'
                                    disabled={!isAddContactFormValid(newContacts, user?.InternalUser)}
                                    onClick={handleAddNewContact}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='mr-1' />
                                    {translate('AddAnotherContact_Label')}
                                </Button>
                            </div>
                        ))}
                </div>
                <div className='modal-footer justify-content-end'>
                    <Button
                        type='submit'
                        variant='primary'
                        size='large'
                        ref={!modal.edit ? addContactsConfirmRef : null}
                        className='d-flex align-items-center'
                        disabled={
                            modal.edit
                                ? !isAddContactFormValid([{ ...editContact, IsRoleLabelValid: editContact.IsEditedRoleEmpty }])
                                : !isAddContactFormValid(newContacts, user?.InternalUser) || isSubmitting
                        }
                    >
                        {isSubmitting && <Spinner size='small' color='inherit' className='mr-2' />}
                        <span>{modal.edit ? translate('Save_Label') : translate('AddContact_Label')}</span>
                    </Button>
                </div>
            </form>
        </>
    );
}
