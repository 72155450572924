import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import type { FormEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../common/constants/constants-portal';
import Checkbox from '../../../../../common/features/Checkbox';
import FormInputField from '../../../../../common/features/FormInputField';
import NotificationsCheckboxes from '../../../../../common/features/NotificationsCheckboxes';
import Spinner from '../../../../../common/features/Spinner';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import { inputHasValueValidator } from '../../../../../common/functions/validators';
import useMaskedInputValidation from '../../../../../common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../../common/hooks/useTranslation';
import type { Notifications } from '../../../../../common/models/ResponseData';
import { addToast } from '../../../../../redux/reducers/toastSlice';
import type { GridSubscriptions } from '../../../common/constants/constants-profile';
import { useUpdateSubscriptionsByUserAndClientMutation } from '../../../redux/api/profileApi';
import { useProfileDispatch } from '../../../redux/hooks';

interface NotificationsUS {
    HasNotificationsForClearanceOnHold: boolean;
    HasNotificationsForCustomsCertified: boolean;
    HasNotificationsForCustomsReleased: boolean;
    HasNotificationsForDocumentsReceived: boolean;
    HasNotificationsForEntrySummaryAccepted: boolean;
    HasNotificationsForOneUsgReceived: boolean;
}

interface NotificationsCA {
    HasNotificationsForClearanceOnHold_CA: boolean;
    HasNotificationsForDocumentsReceived_CA: boolean;
    HasNotificationsForReleaseSubmitted_CA: boolean;
    HasNotificationsForCustomsAccepted_CA: boolean;
    HasNotificationsForCustomsReleased_CA: boolean;
    HasNotificationsForEntryAccepted_CA: boolean;
}

interface MultiSelectedModalProps {
    email: string | null | undefined;
    selectedClientsNumber: number | null | undefined;
    notificationsCA: NotificationsCA;
    notificationsUS: NotificationsUS;
    selectedSubscriptions: GridSubscriptions[];
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setNotificationsUS: React.Dispatch<React.SetStateAction<NotificationsUS>>;
    setNotificationsCA: React.Dispatch<React.SetStateAction<NotificationsCA>>;
}

export const MultiSelectModal: React.FC<MultiSelectedModalProps> = ({
    email,
    selectedClientsNumber,
    notificationsCA,
    notificationsUS,
    selectedSubscriptions,
    setIsVisible,
    setNotificationsUS,
    setNotificationsCA
}) => {
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        required: false,
        initial: '',
        validators: [inputHasValueValidator, (value: string) => isValidPhoneNumber(value, 'US')],
        inputMask: '(###) ###-####'
    });

    const dispatch = useProfileDispatch();
    const translate = useTranslation();

    const CustomerNumbersArray = useMemo(
        () => selectedSubscriptions.map((customerNumber) => customerNumber.CustomerNumber!),
        [selectedSubscriptions]
    );

    useEffect(() => {
        setNotificationsCA(MILESTONES_CA);
        setNotificationsUS(MILESTONES_US);
    }, [setNotificationsCA, setNotificationsUS]);

    const getReqNotifications = <T extends NotificationsCA | NotificationsUS>(notifications: T): Notifications[] => {
        return Object.keys(notifications).map((key) => ({
            Name: key,
            Value: (notifications as unknown as Record<string, boolean>)[key]
        }));
    };
    const checkNotifications = [...getReqNotifications(notificationsCA), ...getReqNotifications(notificationsUS)].every(
        (item) => !item.Value
    );

    const hasCountry = useCallback(
        (c: 'ca' | 'us') => {
            if (!selectedSubscriptions) return false;

            return (
                CustomerNumbersArray.filter((number) => {
                    const country = getCountryFromClientNumber(number as string);
                    return country === c;
                }).length > 0
            );
        },
        [selectedSubscriptions, CustomerNumbersArray]
    );

    const [updateSubscriptionsByUserAndClient, { isLoading: updateSubscriptionsByUserAndClientIsLoading }] =
        useUpdateSubscriptionsByUserAndClientMutation();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            const data = await updateSubscriptionsByUserAndClient({
                SubscriptionIdCustomerNumberDyads: selectedSubscriptions.map((item) => ({
                    CustomerNumber: item.CustomerNumber!,
                    SubscriptionId: item.SubscriptionId || null,
                    DocID: item.DocID || null
                })),
                Language: selectedSubscriptions[0].Language || null,
                UserId: selectedSubscriptions[0].UserId || null,
                FullName: null,
                Role: null,
                Email: isEmailChecked ? email : null,
                Phone: mobileNumber ? mobileNumber : null,
                MilestoneNotifications_CA: getReqNotifications(notificationsCA),
                MilestoneNotifications_US: getReqNotifications(notificationsUS)
            }).unwrap();

            const alertMessage =
                data.ResponseCode === 200 &&
                data.ResponseMessage === 'PASS' &&
                (data.SubscriptionStatusCode === 'SUBSCRIBED' || data.SubscriptionStatusCode === 'SUBSCRIBED-UPDATED')
                    ? 'ClearanceNotificationsUpdated_Label'
                    : null;

            if (alertMessage) {
                dispatch(addToast({ message: translate(alertMessage), type: 'success' }));
            }
        } catch (error) {
            console.error('Failed to update subscriptions:', error);
        }

        setIsVisible(false);
    };

    const updateNotificationsUS = (key: string, value: boolean) => {
        setNotificationsUS({ ...notificationsUS, [key]: value });
    };
    const updateNotificationsCA = (key: string, value: boolean) => {
        setNotificationsCA({ ...notificationsCA, [key]: value });
    };

    const handleCheckbox = (key: string, value: boolean) => {
        setIsEmailChecked(value);
    };

    return (
        <>
            <div className='modal-dialog  modal-dialog-centered modal-dialog-scrollable manage-notifications'>
                <form onSubmit={handleSubmit} className='modal-content'>
                    <div className='modal-header'>
                        <h2 className='h3 modal-title font-weight-bold'>
                            {translate('ManageNotificationsSelectedClients_Label', selectedClientsNumber)}
                        </h2>
                        <button type='button' className='modal-close btn btn-tertiary-blue btn-lg' onClick={() => setIsVisible(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <>
                        <div className='modal-body d-flex flex-column align-items-start'>
                            <div id='scrollTable' className='w-100'>
                                <div className='text-secondary mb-4 font-weight-bold'>{translate('SelectMilestonesNotifiedOn_Label')}</div>

                                <div className='d-flex justify-content-between w-100'>
                                    {hasCountry('us') && (
                                        <div className='d-flex flex-column justify-content-center'>
                                            <NotificationsCheckboxes
                                                handleChange={updateNotificationsUS}
                                                setNotificationSettings={setNotificationsUS}
                                                notificationSettings={notificationsUS}
                                                country={[{ id: 'US' }]}
                                                addSubscriberModal={false}
                                                onHoldTitle={translate('ClearancesOnHoldTitle_Label')}
                                                onHoldLabel={translate('OnHold_Label')}
                                            />
                                        </div>
                                    )}
                                    {hasCountry('ca') && (
                                        <div className='d-flex flex-column justify-content-center'>
                                            <NotificationsCheckboxes
                                                handleChange={updateNotificationsCA}
                                                setNotificationSettings={setNotificationsCA}
                                                notificationSettings={notificationsCA}
                                                country={[{ id: 'CA' }]}
                                                addSubscriberModal={false}
                                                onHoldTitle={translate('ClearancesOnHoldTitle_Label')}
                                                onHoldLabel={translate('OnHold_Label')}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='mr-4'>
                                    <p className='font-weight-bold'>{translate('HowReceiveNotifications_Label')}</p>
                                    <div className='checkboxes'>
                                        <div className='custom-control custom-checkbox mb-1'>
                                            <Checkbox
                                                id='email'
                                                name='Email'
                                                inputStyle={{ width: '1.25rem', height: '1.32813rem' }}
                                                divStyle={{ paddingBottom: '1vh' }}
                                                onChange={handleCheckbox}
                                                isChecked={isEmailChecked}
                                            >
                                                {email}
                                            </Checkbox>
                                        </div>
                                        <FormInputField
                                            value={mobileNumber}
                                            onChange={mobileNumberChangeHandler}
                                            onBlur={mobileNumberBlurHandler}
                                            isValid={mobileNumberIsValid}
                                            hasError={mobileNumberHasError}
                                            placeholder={translate('EnterMobileNumber_Label')}
                                            label=''
                                            showLabel={false}
                                        />
                                        <div className='small'>{translate('StandardMessageAndDataRates_Label')}</div>
                                        {mobileNumberHasError && (
                                            <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-ghost btn-lg' onClick={() => setIsVisible(false)}>
                                {translate('Cancel_Label')}
                            </button>
                            <button
                                type='submit'
                                className='btn btn-primary btn-lg d-flex align-items-center'
                                disabled={
                                    !(mobileNumberIsValid || isEmailChecked) ||
                                    checkNotifications ||
                                    updateSubscriptionsByUserAndClientIsLoading
                                }
                            >
                                {updateSubscriptionsByUserAndClientIsLoading && <Spinner size='small' color='inherit' className='mr-2' />}
                                {translate('SaveSettings_Label')}
                            </button>
                        </div>
                    </>
                </form>
            </div>
        </>
    );
};
