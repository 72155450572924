import { API_CLEARANCES_PATH, API_DASHBOARD_PATH } from '../../common/constants/constants-portal';
import type { ClientSelectionData, ClientService } from '../../common/models/ResponseData';
import { setClientSelectionSearch, setClientSelectionSettings, setIsApplyClicked } from '../reducers/clientSelectionReducer';
import { apiSlice } from './apiSlice';

interface ClientSelectionSearchParams {
    query: string;
    countryCode: 'ca' | 'us';
}

export const clientSelectionApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getClientSelectionSettings: build.query<ClientSelectionData, void>({
            query: () => `${API_DASHBOARD_PATH}/GetClientServiceSelection`,
            async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
                try {
                    const [{ data: clientServiceSelection }, { data: clientCaptionNumbers }] = await Promise.all([
                        queryFulfilled,
                        dispatch(clientSelectionApi.endpoints.getClientCaptionNumbers.initiate(undefined, { forceRefetch: true }))
                    ]);

                    const updatedClientCaptionNumbers = [...(clientCaptionNumbers || [])];
                    const userCountry = clientServiceSelection.ClientServiceSelection.Countries[0].IsSelected ? 'us' : 'ca';

                    clientServiceSelection.ClientServiceSelection.ClientServices.forEach((service) => {
                        if (service.CountryCode === userCountry && !updatedClientCaptionNumbers.includes(service.Number)) {
                            updatedClientCaptionNumbers.push(service.Number);
                        }
                    });

                    const newClientNumbersArray: ClientService[] = [];
                    const { ClientServices } = clientServiceSelection.ClientServiceSelection;

                    updatedClientCaptionNumbers.forEach((clientNumber) => {
                        const clientService = ClientServices.find(
                            (service) =>
                                service.Number + '-' + service.Caption === clientNumber ||
                                service.Number === clientNumber ||
                                service.Number.includes(clientNumber)
                        );

                        if (clientService) {
                            newClientNumbersArray.push({
                                ...clientService,
                                Number: clientNumber,
                                FullNumber: clientService.Number.includes(clientNumber) ? clientNumber : clientService.FullNumber,
                                Caption: clientService.Number.includes(clientNumber) ? '' : clientService.Caption
                            });
                        }
                    });

                    dispatch(
                        setClientSelectionSettings({
                            ...clientServiceSelection.ClientServiceSelection,
                            ClientTableData: newClientNumbersArray
                        })
                    );
                } catch (error) {
                    console.error('Error fetching client selection settings:', error);
                }
            }
        }),
        getClientCaptionNumbers: build.query<string[], void>({
            query: () => `${API_CLEARANCES_PATH}/clearance/get-user-client-caption-numbers`
        }),
        getClientSelectionSearch: build.query<ClientService[], ClientSelectionSearchParams>({
            query: ({ query, countryCode }) => ({
                url: '/Dashboard/SearchEDMClients',
                method: 'POST',
                params: {
                    numberOrName: query,
                    countryCode: countryCode
                }
            }),
            async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(setClientSelectionSearch(data));
                } catch (error) {
                    console.error('Error fetching client selection search:', error);
                }
            },
            transformResponse: (response: ClientService[], _meta, arg) => {
                return response.filter(
                    (c) =>
                        c?.Name?.toLowerCase()?.includes(arg.query.toLowerCase()) ||
                        c?.Number?.toLowerCase()?.includes(arg.query.toLowerCase())
                );
            }
        }),
        updateClientSelectionSettings: build.mutation<ClientSelectionData, ClientSelectionData['ClientServiceSelection']>({
            query: (body) => ({
                url: '/Dashboard/SaveClientServiceSelection',
                method: 'POST',
                body
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;

                    dispatch(setIsApplyClicked(false));
                    dispatch(setClientSelectionSettings(arg));
                } catch (error) {
                    console.error('Error updating client selection settings:', error);
                }
            }
        })
    })
});

export const {
    useGetClientSelectionSettingsQuery,
    useLazyGetClientSelectionSearchQuery,
    useUpdateClientSelectionSettingsMutation,
    useGetClientCaptionNumbersQuery
} = clientSelectionApi;
