import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_CLEARANCES_PATH } from '../../../../common/constants/constants-portal';
import { addToDownloadLobby } from '../../../../redux/reducers/alertsSlice';
import { addToast } from '../../../../redux/reducers/toastSlice';
import { ClearancesDownloadRequestModel } from '../../common/models/ShipmentList';
import { DOWNLOAD_CLEARANCES } from './actionTypes';

export const DownloadClearancesData = createAsyncThunk(
    DOWNLOAD_CLEARANCES,
    async (payload: ClearancesDownloadRequestModel, { dispatch }) => {
        try {
            const response = await axios.post(`${API_CLEARANCES_PATH}/downloadClearancesData`, payload);

            const parsed = JSON.parse(response.data);

            // Check if response has download lobby
            if (parsed?.ImageUrl.includes('DownloadLobby')) {
                const match = parsed.ImageUrl.match(/packageId=(\d+)/);

                if (match) {
                    const packageId = match[1];
                    dispatch(addToDownloadLobby({ packageId: packageId, packageSource: 'SearchShipmentClearances' }));
                }
            }

            return response.data;
        } catch (error: any) {
            if (error.response.status === 500) {
                dispatch(addToast({ message: 'DownloadLobbyErrorTitle_Label', isLabel: true, type: 'error' }));
            }

            return error.response.status;
        }
    }
);
