import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { webAPIbaseURL } from '../../../constants/mlp-constants';

const apiBaseUrl =
    process.env.REACT_APP_MSW_ENABLED === 'TRUE'
        ? ''
        : process.env.REACT_APP_CENTRAL_DEV === 'TRUE'
        ? 'https://mlpv2-api-dev.mlpdev.liiaws.net/'
        : webAPIbaseURL;

const baseQuery = fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
});
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        const tokenExpired =
            process.env.REACT_APP_CENTRAL_DEV !== 'TRUE' &&
            (result.error.status === 401 || (result.error.status === 'PARSING_ERROR' && result.error.originalStatus === 401));

        if (tokenExpired) {
            const redirectUrl = result.error.data as string;

            window.location.replace(redirectUrl);

            return { data: null };
        }
    }

    return result;
};

const TEAM_TAGS = ['UserTeam', 'NewTeamMember', 'TeamMemberDetails', 'ClonedTeamMemberDetails', 'EdmClientServices'] as const;
const PROFILE_TAGS = ['UserTickets', 'SubscriptionsWithClientNumbers'] as const;
const CONTACTS_TAGS = ['Contacts'] as const;

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: [...TEAM_TAGS, ...PROFILE_TAGS, ...CONTACTS_TAGS],
    endpoints: () => ({})
});
