import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { usePortalDispatch, usePortalSelector } from '../../redux/hooks';
import { removeOldestToast } from '../../redux/reducers/toastSlice';
import { useDebounce } from '../hooks/useDebounce';
import Toast from './Toast';

const TOAST_TIMEOUT = 3000;

export default function Toaster() {
    const { user } = usePortalSelector((state) => state.hydration);
    const { toasts } = usePortalSelector((state) => state.toast);
    const dispatch = usePortalDispatch();

    const timeout = user?.PortalTimeoutShort || TOAST_TIMEOUT;
    const debounce = useDebounce(timeout + 200);

    useEffect(() => {
        if (toasts.length > 0) {
            debounce(() => dispatch(removeOldestToast()));
        }
    }, [dispatch, debounce, toasts]);

    return (
        <>
            {ReactDOM.createPortal(
                <>
                    {toasts.map(({ id, ...toast }) => (
                        <Toast key={id} {...toast} customTimeout={timeout} />
                    ))}
                </>,
                document.querySelector('#portalToasts .toasts-wrapper') || document.body
            )}
        </>
    );
}
