import { clsx } from 'clsx';
import React from 'react';

const VARIANTS = {
    standard: 'badge-pill',
    circular: 'badge-circular',
    alert: 'badge-alert-badge'
} as const;

const TYPES = {
    info: 'badge-info',
    danger: 'badge-danger',
    warning: 'badge-warning',
    success: 'badge-success',
    neutral: 'badge-neutral'
} as const;

const SIZES = {
    'extra-small': 'badge-circular-xs',
    small: 'badge-circular-sm',
    medium: '',
    large: 'badge-circular-lg',
    'extra-large': 'badge-circular-xl'
} as const;

export interface BadgeProps {
    variant?: keyof typeof VARIANTS;
    type?: keyof typeof TYPES;
    size?: keyof typeof SIZES;
    className?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
}

const Badge: React.FC<BadgeProps> = ({
    variant = 'standard',
    type = 'info',
    size = 'medium',
    className = '',
    children,
    style,
    onClick
}) => {
    if (VARIANTS[variant] !== 'badge-alert-badge') {
        return (
            <div
                className={clsx(
                    'badge text-wrap',
                    VARIANTS[variant],
                    TYPES[type],
                    VARIANTS[variant] === 'badge-circular' && SIZES[size],
                    className
                )}
                style={style}
                onClick={onClick}
            >
                {children}
            </div>
        );
    }

    return (
        <div className='alert-badge text-wrap' style={style} onClick={onClick}>
            {children}
        </div>
    );
};

export default Badge;
