import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../common/constants/constants-portal';
import Button from '../../../../../common/features/Button';
import LinearProgressBar from '../../../../../common/features/LinearProgressBar';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Notifications } from '../../../../../common/models/ResponseData';
import { SubscribersModals } from '../../../../../common/models/SubscribersModals';
import { addToast } from '../../../../../redux/reducers/toastSlice';
import { SubscribeUsers } from '../../../redux/actions/subscribersManagement';
import { useSubscribersDispatch, useSubscribersSelector } from '../../../redux/hooks';
import { resetClientsState, resetNewSubscribers, resetSubscribe } from '../../../redux/reducers/subscribersSlice';
import SubscriberInfoForm from './NewSubscriberForm/SubscriberInfoForm';
import SubscriberNotifications from './Notifications/SubscriberNotifications';
import SelectClientNumbers from './SelectClientNumbers';

interface AddSubscriberModalProps {
    toggleModal: (prop: keyof SubscribersModals, next?: boolean) => void;
    modal: SubscribersModals;
}

const AddSubscriberModal: React.FC<AddSubscriberModalProps> = ({ toggleModal, modal }) => {
    const { newSubscribers, selectedClients, subscribe } = useSubscribersSelector((state) => state.subscribers);
    const { initialClientSelection } = useSubscribersSelector((state) => state.clientSelection);
    const [notificationsUS, setNotificationsUS] = useState<any>(MILESTONES_US);
    const [notificationsCA, setNotificationsCA] = useState<any>(MILESTONES_CA);
    const [step, setStep] = useState(1);
    const translate = useTranslation();
    const dispatch = useSubscribersDispatch();

    // On initial load refresh "subscribe", "newSubscribers"
    useEffect(() => {
        dispatch(resetSubscribe());
        dispatch(resetNewSubscribers());
        dispatch(resetClientsState());
    }, [dispatch]);

    const handleSubmit = async () => {
        const getReqNotifications = (notifications: any[], countryTo: string): Notifications[] => {
            const country = initialClientSelection?.Countries.find((c) => c.IsSelected);
            return Object.keys(notifications).map((key: any, index) => ({
                Name: key,
                Value: index === 0 && country && countryTo !== country.Code ? false : notifications[key]
            }));
        };

        toggleModal('add', false);

        await dispatch(
            SubscribeUsers({
                subscribers: newSubscribers.map((sub) => ({
                    Language: sub.language,
                    FullName: sub.fullName,
                    RoleName: sub.role,
                    Email: sub.emailIsValid ? sub.email : '',
                    Phone: sub.phoneIsValid ? sub.phone : '',
                    IsExternal: sub.userId?.includes('EXT'),
                    UserID: sub.userId
                })),
                customerNumbers: selectedClients ? Object.keys(selectedClients).map((no) => `${no}`) : [],
                notificationsCA: getReqNotifications(notificationsCA, 'ca'),
                notificationsUS: getReqNotifications(notificationsUS, 'us')
            })
        );

        // Success toast
        if (!subscribe.error) {
            dispatch(
                addToast({
                    message: translate('SubscriptionsCreated_Label'),
                    type: 'success'
                })
            );
        }
    };

    return (
        <>
            {modal.add && (
                <ModalWrapper id='SteppedProgressModal' onEscape={() => toggleModal('cancelAdd', true)}>
                    <div className='modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable'>
                        <div className='modal-content'>
                            {/* MODAL HEADER */}
                            <div className='modal-header'>
                                <div className='mr-4'>
                                    <h3 className='mb-0'>{translate('AddSubscribersHeadline_Label')}</h3>
                                    <h4 className='font-weight-medium mb-0'>
                                        <span className='text-primary'>{translate('StepOfSteps_Label', step, 3)} </span>
                                        {step === 1
                                            ? translate('AddSubscribersStep1_Label')
                                            : step === 2
                                            ? translate('SelectClientNumbers_Label')
                                            : translate('SelectMilestones_Label')}
                                    </h4>
                                </div>
                                <Button variant='tertiary' className='btn-lg' onClick={() => toggleModal('cancelAdd', true)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>

                            {/* PROGRESS BAR */}
                            <LinearProgressBar progressPercent={Math.round((100 / 3) * step)} heightPixels={6} />

                            {step === 1 && <SubscriberInfoForm handleStep={setStep} />}
                            {step === 2 && <SelectClientNumbers handleStep={setStep} />}
                            {step === 3 && (
                                <SubscriberNotifications
                                    notificationsCA={notificationsCA}
                                    notificationsUS={notificationsUS}
                                    setNotificationsCA={setNotificationsCA}
                                    setNotificationsUS={setNotificationsUS}
                                    step={step}
                                    setStep={setStep}
                                    handleSubmit={handleSubmit}
                                />
                            )}
                        </div>
                    </div>
                </ModalWrapper>
            )}
        </>
    );
};

export default AddSubscriberModal;
