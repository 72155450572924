import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import React from 'react';
import { REACT_APP_ASSETS_URL } from '../constants/constants-portal';

interface CountryFlagProps {
    countryCode: string;
    width?: string;
    height?: string;
    className?: string;
}

const COUNTRY_FLAGS: Record<string, string> = {
    CA: 'canadian-flag',
    US: 'usa-flag'
};

const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode, width, height, className }) => {
    if (countryCode.toUpperCase() === 'ALL') {
        return <FontAwesomeIcon icon={faGlobe} className={clsx('globe', className)} />;
    }

    const flag = COUNTRY_FLAGS[countryCode.toUpperCase()];

    if (!flag) {
        return null;
    }

    return (
        <img
            className={className}
            src={`${REACT_APP_ASSETS_URL}/assets/images/icons/flags/${flag}.svg`}
            alt={`${flag} flag`}
            width={width}
            height={height}
        />
    );
};

export default CountryFlag;
