import BasicSearchBox from '../../../../common/features/SearchBox/BasicSearchBox';
import useTranslation from '../../../../common/hooks/useTranslation';
import { setSelectedStatus } from '../../../subscribers/redux/reducers/subscribersStatusFilterSlice';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { setPageSelected } from '../../redux/reducers/teamGridSettingsSlice';
import { clearSearchFilter, setSearchFilter } from '../../redux/reducers/teamSearchFilterSlice';

export default function TeamSearchBox() {
    const { searchTerm } = useTeamSelector((state) => state.teamSearchFilter);

    const translate = useTranslation();
    const dispatch = useTeamDispatch();

    const handleReset = () => {
        dispatch(clearSearchFilter());
    };

    const handleSubmit = (value: string) => {
        dispatch(setSearchFilter(value.trim()));
        dispatch(setSelectedStatus(0));
        dispatch(setPageSelected(1));
    };

    return (
        <BasicSearchBox
            initialValue={searchTerm}
            placeholderLabel={translate('SearchPlaceholder_Label')}
            submitButtonLabel={translate('Search_Label')}
            onReset={handleReset}
            onSubmit={handleSubmit}
        />
    );
}
