import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { ComboBoxTabOptions, NewSubscriberLanguages, NewSubscriberRoles } from '../../../../../../common/constants/constants-portal';
import Button from '../../../../../../common/features/Button';
import Checkbox from '../../../../../../common/features/Checkbox';
import ComboBox from '../../../../../../common/features/ComboBox';
import { inputHasValueValidator } from '../../../../../../common/functions/validators';
import useDropdownToggle from '../../../../../../common/hooks/useDropdownToggle';
import useInputValidation from '../../../../../../common/hooks/useInputValidation';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { ComboBoxSubscriber, ComboBoxTab } from '../../../../../../common/models/ComboBoxSubscribers';
import { useClearancesDispatch, useClearancesSelector } from '../../../../redux/hooks';
import {
    removeNewSubscriber,
    resetNewSubscriberFields,
    setNewSubscriberEmail,
    setNewSubscriberFullName,
    setNewSubscriberLanguage,
    setNewSubscriberPhone,
    setNewSubscriberRole,
    setNewSubscriberUserId
} from '../../../../redux/reducers/clearanceSubscribersSlice';

interface SubscriberProps {
    id: number;
    email: string;
    emailIsValid: boolean;
    phone: string;
    phoneIsValid: boolean;
    fullName: string;
    role: string;
    language: string;
}

const Subscriber: React.FC<SubscriberProps> = ({ id, email, emailIsValid, phone, phoneIsValid, fullName, role, language }) => {
    const { membersAndContacts } = useClearancesSelector((state) => state.clearanceSubscribers);
    const { user } = useClearancesSelector((state) => state.hydration);

    const [disableEmailCheckbox, setDisableEmailCheckbox] = useState(!!!email.length);
    const [disablePhoneCheckbox, setDisablePhoneCheckbox] = useState(!!!phone.length);
    const [emailCheckbox, setEmailCheckbox] = useState(!!(email && emailIsValid));
    const [phoneCheckbox, setPhoneCheckbox] = useState(!!(phone && phoneIsValid));
    const [selectedComboBoxTab, setSelectedComboBoxTab] = useState<ComboBoxTab | null>(null);
    const [comboBoxList, setComboBoxList] = useState<ComboBoxSubscriber[]>([]);
    const [carrierNumbers, setCarrierValues] = useState<string | null>('');

    const translate = useTranslation();
    const dispatch = useClearancesDispatch();

    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);

    const {
        value: name,
        handleChange: nameChangeHandler,
        handleBlur: nameBlurHandler,
        handleReset: nameResetHandler,
        handleInputChange: nameInputChangeHandler,
        isValid: nameIsValid,
        hasError: nameHasError
    } = useInputValidation({
        validators: [inputHasValueValidator],
        initial: `${fullName} ${carrierNumbers ? `(${carrierNumbers})` : ''}`
    });

    // Set initial combo box tab to 'ALL'
    useEffect(() => {
        if (membersAndContacts && membersAndContacts?.TeamExternalContacts && membersAndContacts.TeamMembers) {
            // combo box list = team members and external contacts
            let content: ComboBoxSubscriber[] = membersAndContacts.TeamExternalContacts.concat(membersAndContacts.TeamMembers!);

            setSelectedComboBoxTab({
                name: ComboBoxTabOptions[0].id,
                content: content.sort((a, b) => a.FullName!.localeCompare(b.FullName!))
            });
        }
    }, [membersAndContacts]);

    useEffect(() => {
        setComboBoxList(selectedComboBoxTab?.content!);
    }, [selectedComboBoxTab]);

    const onChangeFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = e.target.value.trim();

        // on every change of fullName reset other fields
        !isOpen && handleToggle();
        setEmailCheckbox(false);
        setPhoneCheckbox(false);
        dispatch(resetNewSubscriberFields(id));

        if (selectedComboBoxTab?.content.filter((item) => item.FullName === enteredValue).length) {
            onClickComboBoxOption(selectedComboBoxTab?.content.filter((item) => item.FullName === enteredValue)[0]);
        }

        nameChangeHandler(e);
        setComboBoxList(selectedComboBoxTab?.content.filter((item) => item.FullName!.toLowerCase().includes(enteredValue.toLowerCase()))!);
    };

    const onClickComboBoxOption = (member: ComboBoxSubscriber) => {
        setCarrierValues(member.CarrierCodes);
        nameInputChangeHandler(`${member.FullName!} ${member?.CarrierCodes ? `(${member?.CarrierCodes})` : ''}`);
        nameInputChangeHandler(member.FullName!);
        dispatch(setNewSubscriberFullName({ id, value: member.FullName! }));

        dispatch(setNewSubscriberUserId({ id, value: member.UserId }));

        if (member.Email?.length) {
            setEmailCheckbox(true);
            dispatch(setNewSubscriberEmail({ id, value: member.Email!, isValid: true }));
        } else {
            setEmailCheckbox(false);
            dispatch(setNewSubscriberEmail({ id, value: '', isValid: false }));
        }

        if (member.MobileNumber?.length) {
            setPhoneCheckbox(true);
            dispatch(setNewSubscriberPhone({ id, value: member.MobileNumber!, isValid: true }));
        } else {
            setPhoneCheckbox(false);
            dispatch(setNewSubscriberPhone({ id, value: '', isValid: false }));
        }

        dispatch(
            setNewSubscriberRole({
                id,
                value: NewSubscriberRoles.find((role) => role.value === member.RoleName)?.value ?? NewSubscriberRoles[7].value
            })
        );

        dispatch(
            setNewSubscriberLanguage({
                id,
                value:
                    NewSubscriberLanguages.find((language) => language.value === member.Language?.toUpperCase())?.value ??
                    NewSubscriberLanguages.find((language) => language.value === user?.LanguageCode?.toUpperCase())!.value
            })
        );

        handleToggle();
    };

    const handleComboBoxTab = (tab: string) => {
        switch (tab) {
            case 'Contacts':
                setSelectedComboBoxTab({ name: tab, content: membersAndContacts?.TeamExternalContacts! });
                break;
            case 'Team':
                setSelectedComboBoxTab({ name: tab, content: membersAndContacts?.TeamMembers! });
                break;
            default:
                {
                    let content: ComboBoxSubscriber[] = membersAndContacts?.TeamExternalContacts.concat(membersAndContacts.TeamMembers)!;
                    setSelectedComboBoxTab({
                        name: ComboBoxTabOptions[0].id,
                        content: content.sort((a, b) => a.FullName!.localeCompare(b.FullName!))
                    });
                }
                break;
        }
    };

    const handleCheckbox = (checkboxType: string) => {
        if (checkboxType === 'email') {
            !emailCheckbox
                ? dispatch(setNewSubscriberEmail({ id, value: email, isValid: true }))
                : dispatch(setNewSubscriberEmail({ id, value: email, isValid: false }));
            setEmailCheckbox(!emailCheckbox);
            setDisableEmailCheckbox(false); // if checkbox value changed then it cannot be disabled
        } else {
            !phoneCheckbox
                ? dispatch(setNewSubscriberPhone({ id, value: phone, isValid: true }))
                : dispatch(setNewSubscriberPhone({ id, value: phone, isValid: false }));
            setPhoneCheckbox(!phoneCheckbox);
            setDisablePhoneCheckbox(false); // if checkbox value changed then it cannot be disabled
        }
    };

    return (
        <>
            <tr id='AddContactsModal'>
                <td>
                    {/* Full name */}
                    <ComboBox
                        style={{ zIndex: 1060 }}
                        ref={dropdownMenuRef}
                        isOpen={isOpen}
                        name={name}
                        isValid={nameIsValid}
                        hasError={nameHasError!}
                        onChangeFullName={onChangeFullName}
                        onBlurFullName={(e) => !isOpen && nameBlurHandler(e)}
                        onClick={handleToggle}
                    >
                        <nav className='nav-tabs-wrapper'>
                            <div className='nav nav-tabs' id='nav-tab' role='tablist' style={{ overflow: 'hidden' }}>
                                {ComboBoxTabOptions.map((item, index) => (
                                    <div
                                        className={clsx('nav-link', item.id === selectedComboBoxTab?.name && 'active text-dark')}
                                        style={{ borderColor: '#fff', cursor: 'pointer' }}
                                        onClick={() => handleComboBoxTab(item.id)}
                                        key={index}
                                    >
                                        {translate(item.label)}
                                    </div>
                                ))}
                            </div>
                        </nav>
                        <div className='tab-content d-flex flex-column flex-fill' id='nav-tabContent'>
                            {ComboBoxTabOptions.map(
                                (tab, index) =>
                                    selectedComboBoxTab?.name === tab.id &&
                                    comboBoxList && (
                                        <div style={{ maxHeight: '30vh', overflow: 'auto' }} key={index}>
                                            {comboBoxList.map((item, contentIndex) => (
                                                <button
                                                    className='dropdown-item d-flex justify-content-between align-items-stretch'
                                                    type='button'
                                                    key={contentIndex}
                                                    onClick={() => onClickComboBoxOption(item)}
                                                >
                                                    <div className='mr-3 d-flex align-items-center'>{`${item.FullName} ${
                                                        item.CarrierCodes ? `(${item.CarrierCodes})` : ''
                                                    }`}</div>
                                                </button>
                                            ))}
                                        </div>
                                    )
                            )}
                        </div>
                    </ComboBox>
                </td>
                <td>
                    {/* Role */}
                    {role.length ? role : '-'}
                </td>
                <td>
                    {/* Language */}
                    {language.length ? language : '-'}
                </td>
                <td>
                    {/* Email */}
                    <div className='custom-control custom-checkbox mb-1'>
                        <Checkbox
                            id='email'
                            name='Email'
                            inputStyle={{ opacity: '1', cursor: 'pointer' }}
                            isChecked={emailCheckbox}
                            onChange={() => handleCheckbox('email')}
                            disabled={email.length === 0 && disableEmailCheckbox}
                        />
                        <span>{emailIsValid ? email : '-'}</span>
                    </div>
                </td>
                <td>
                    {/* Mobile number */}
                    <div className='custom-control custom-checkbox mb-1'>
                        <Checkbox
                            id='mobile'
                            name='Mobile'
                            inputStyle={{ opacity: '1', cursor: 'pointer' }}
                            isChecked={phoneCheckbox}
                            onChange={() => handleCheckbox('phone')}
                            disabled={phone.length === 0 && disablePhoneCheckbox}
                        />
                        <span style={{ whiteSpace: 'nowrap' }}>{phoneIsValid ? phone : '-'}</span>
                    </div>
                </td>
                <td className='row-action bg-white'>
                    {/* Remove button */}
                    <Button
                        variant='danger-outline'
                        onClick={() => {
                            nameResetHandler();
                            setEmailCheckbox(false);
                            setPhoneCheckbox(false);
                            dispatch(removeNewSubscriber(id));
                        }}
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                </td>
            </tr>
        </>
    );
};

export default Subscriber;
