import { clsx } from 'clsx';
import React from 'react';

interface ColProps {
    children?: React.ReactNode;
    className?: string;
}

const Col: React.FC<ColProps> = ({ children, className = '' }) => {
    return <div className={clsx('col', className)}>{children}</div>;
};

export default Col;
