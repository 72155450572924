import { getter, process } from '@progress/kendo-data-query';
import type { GridCellProps, GridDataStateChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { GridColumn as Column, Grid } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/all.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../common/constants/constants-portal';
import ErrorPage from '../../../../../common/features/ErrorPage';
import GridLoadingOverlay from '../../../../../common/features/Grid/GridLoadingOverlay';
import UnsubscribeFooter from '../../../../../common/features/Grid/UnsubscribeFooter';
import Spinner from '../../../../../common/features/Spinner';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import { isFetchBaseQueryError } from '../../../../../common/functions/utils';
import useGridLanguage from '../../../../../common/hooks/useGridLanguage';
import useGridPreferences from '../../../../../common/hooks/useGridPreferences';
import useGridSelection from '../../../../../common/hooks/useGridSelection';
import useTranslation from '../../../../../common/hooks/useTranslation';
import type { ProfileModals } from '../../../../../common/models/SubscribersModals';
import { updateColumnOrder } from '../../../../clearances/redux/reducers/clearancesGridPreferencesSlice';
import type { GridSubscriptions } from '../../../common/constants/constants-profile';
import { GetUserWidthGridSetting } from '../../../redux/actions/subscriptions';
import { useSubscriptionsWithClientNumbersQuery } from '../../../redux/api/profileApi';
import { useProfileDispatch, useProfileSelector } from '../../../redux/hooks';
import {
    resetSelectedState,
    setCurrentDataItems,
    setDataState,
    setHeaderCheckbox,
    setSelectedState,
    setTotalItems
} from '../../../redux/reducers/profileGridSettingsSlice';
import FormattedGridCell from './FormattedGridCell';
import { MultiSelectModal } from './MultiSelectModal';
import UnsubscribeMultipleModal from './UnsubscribeMultipleModal';

const initialModals: ProfileModals = {
    remove: false
};

const SELECTED_FIELD = 'selected';
const DATA_ITEM_KEY = 'RowId';
const idGetter = getter(DATA_ITEM_KEY);

export default function ProfileSubscriptionTable() {
    const { filteredSubscriptions, allColumns, visibleColumns } = useProfileSelector((state) => state.subscriptions);
    const { dataState, currentDataItems, selectedState, headerCheckbox, totalItems } = useProfileSelector(
        (state) => state.profileGridSettings
    );
    const [notificationsUS, setNotificationsUS] = useState(MILESTONES_US);
    const [notificationsCA, setNotificationsCA] = useState(MILESTONES_CA);
    const { user } = useProfileSelector((state) => state.hydration);
    const { languageSelected: selectedLanguage } = useProfileSelector((state) => state.language);

    const [gridSubscriptions, setGridSubscriptions] = useState<GridSubscriptions[]>([]);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState<GridSubscriptions[]>([]); // selected subscribers for bulk delete
    const [multiSelectModalIsVisible, setMultiSelectModalIsVisible] = useState(false);
    const [unsubscribeModal, setUnsubscribeModal] = useState<ProfileModals>(initialModals);

    const gridRef = useRef<any>();
    const dispatch = useProfileDispatch();
    const translate = useTranslation();
    const gridLanguage = useGridLanguage(selectedLanguage);
    const { handleHeaderSelectionChange, handleSelectionChange } = useGridSelection({
        DATA_ITEM_KEY,
        SELECTED_FIELD,
        idGetter,
        selectedState,
        currentDataItems,
        onSelectionChange: (value) => dispatch(setSelectedState(value)),
        onHeaderSelectionChange: useCallback((value) => dispatch(setHeaderCheckbox(value)), [dispatch]),
        onReset: useCallback(() => dispatch(resetSelectedState()), [dispatch])
    });

    const { columns } = useGridPreferences({
        allColumns,
        visibleColumns,
        onColumnReorder: (value) => dispatch(updateColumnOrder(value)),
        //@ts-ignore
        saveColumnReorder: (value) => dispatch(SaveUserClearancesGridSetting({ GridColumns: value }))
    });

    const {
        data: subscriptionsData,
        isLoading,
        isFetching,
        error
    } = useSubscriptionsWithClientNumbersQuery({ ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber }, { skip: !user });
    const errorStatus = (isFetchBaseQueryError(error) && error.status) || undefined;

    // Set data state for the grid
    useEffect(() => {
        if (subscriptionsData) {
            setGridSubscriptions(
                subscriptionsData.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: false
                }))
            );
        }
    }, [subscriptionsData, selectedState]);

    useEffect(() => {
        dispatch(GetUserWidthGridSetting());
    }, [dispatch]);

    // Update the total items that are responsible to show/hide pagination
    useEffect(() => {
        if (!gridRef.current) return;
        dispatch(setTotalItems(gridRef.current.props.data.total));
        dispatch(setCurrentDataItems(gridRef.current.props.data.data));
    }, [dataState, gridSubscriptions, filteredSubscriptions, dispatch]);

    // Reset the selection on every grid reload
    useEffect(() => {
        if (!isFetching && !error) {
            dispatch(resetSelectedState());
        }
    }, [isFetching, error, dispatch]);

    // Array of selected SubscriptionIds
    useEffect(() => {
        setSelectedSubscriptions(
            Object.keys(selectedState).reduce<GridSubscriptions[]>((acc, id) => {
                if (selectedState[id]) {
                    const foundSub = gridSubscriptions.find((sub) => sub[DATA_ITEM_KEY] === parseInt(id));

                    if (foundSub) {
                        acc.push({ ...foundSub });
                    }
                }
                return acc;
            }, [])
        );
    }, [gridSubscriptions, selectedState]);

    useSubscriptionsWithClientNumbersQuery({ ProfileEmail: user?.Email, ProfileMobileNumber: user?.MobileNumber }, { skip: !user });

    const GridCell = useCallback((props: GridCellProps) => {
        return <FormattedGridCell {...props} isLocked={props.dataItem[SELECTED_FIELD] || false} />;
    }, []);

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            return (
                <div className='text-center'>
                    <Checkbox value={headerCheckbox} onChange={props.selectionChange} />
                </div>
            );
        },
        [headerCheckbox]
    );

    const toggleModal = useCallback(
        (prop: keyof ProfileModals | Array<keyof ProfileModals>, next?: boolean) => {
            if (typeof prop === 'string') {
                setUnsubscribeModal({ ...unsubscribeModal, [prop]: next !== undefined ? next : !unsubscribeModal[prop] });
            } else {
                setUnsubscribeModal(Object.assign({}, unsubscribeModal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [unsubscribeModal]
    );

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        dispatch(setDataState(e.dataState));
    };

    return (
        <>
            {/* Status: pending (only showing on initial load) */}
            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {subscriptionsData && !error && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <div className='d-flex position-relative flex-fill h-100'>
                            <Grid
                                className='profile-subscription-tables flex-fill h-100'
                                data={process(
                                    (filteredSubscriptions ? filteredSubscriptions : gridSubscriptions).map((item) => ({
                                        ...item,
                                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                                    })),
                                    dataState
                                )}
                                ref={gridRef}
                                total={subscriptionsData.length}
                                pageable={
                                    totalItems < 10
                                        ? false
                                        : {
                                              buttonCount: 4,
                                              info: true,
                                              type: 'numeric',
                                              pageSizes: [5, 10, 20],
                                              previousNext: true
                                          }
                                }
                                style={{ zIndex: 0 }}
                                selectedField={SELECTED_FIELD}
                                onHeaderSelectionChange={handleHeaderSelectionChange}
                                onSelectionChange={handleSelectionChange}
                                onDataStateChange={dataStateChange}
                                selectable={{
                                    enabled: false,
                                    drag: false,
                                    cell: false,
                                    mode: 'multiple'
                                }}
                                {...dataState}
                            >
                                {columns?.map((column) => {
                                    return (
                                        <Column
                                            key={column.Code}
                                            field={column.Code}
                                            title={translate(column.HeaderLabel)}
                                            cell={GridCell}
                                            headerCell={column.Code === 'selected' ? HeaderCell : undefined}
                                            sortable={column.IsSortable}
                                            width={column.Width}
                                            orderIndex={column.Order}
                                        />
                                    );
                                })}
                            </Grid>
                            {isFetching && subscriptionsData.length > 0 && columns && <GridLoadingOverlay />}
                        </div>
                    </IntlProvider>
                </LocalizationProvider>
            )}
            {multiSelectModalIsVisible && (
                <ModalWrapper onEscape={() => setMultiSelectModalIsVisible(false)}>
                    <MultiSelectModal
                        email={user?.Email}
                        selectedClientsNumber={selectedSubscriptions?.length}
                        setIsVisible={setMultiSelectModalIsVisible}
                        notificationsCA={notificationsCA}
                        notificationsUS={notificationsUS}
                        setNotificationsCA={setNotificationsCA}
                        setNotificationsUS={setNotificationsUS}
                        selectedSubscriptions={selectedSubscriptions}
                    />
                </ModalWrapper>
            )}
            {/* Unsubscribe footer */}
            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 && !isFetching && (
                <UnsubscribeFooter
                    amountLabel={translate('NumberOfRemoveSelected_Label', selectedSubscriptions.length)}
                    primaryButtonLabel={translate('RemoveSelected_Label')}
                    onPrimary={() => toggleModal('remove')}
                    secondaryButtonLabel={translate('ManageNotifications_Label')}
                    disablePrimaryButton={selectedSubscriptions.every((item) => item.SubscriptionId === null)}
                    onSecondary={() => setMultiSelectModalIsVisible(true)}
                    selectedSubscribers={selectedSubscriptions.length}
                />
            )}
            {/* Status: rejected */}
            {error && <ErrorPage errorCode={errorStatus} withTranslations />}
            {unsubscribeModal.remove && <UnsubscribeMultipleModal subscribers={selectedSubscriptions} toggleModal={toggleModal} />}
        </>
    );
}
