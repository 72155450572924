import Dropzone from 'dropzone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Upload, webAPIbaseURL } from '../../constants/mlp-constants';
import { camelize } from '../../utils/mlp-utils';
import UploadPreviewTemplate from './UploadPreviewTemplate';

class DropZone extends Component {
    dropZoneMessageRef = React.createRef();
    dropZoneElementRef = React.createRef();
    previewTemplateRef = React.createRef();
    static propTypes = {
        id: PropTypes.string.isRequired,
        formAction: PropTypes.string.isRequired,
        initialMessage: PropTypes.string,
        options: PropTypes.object
    };

    componentDidMount = () => {
        Dropzone.autoDiscover = false;
        let options = this.props.options;

        options.previewTemplate = ReactDOM.findDOMNode(this.previewTemplateRef.current).innerHTML;

        options.autoProcessQueue = false;
        const token = localStorage.getItem('token');
        options.headers = { Authorization: `Bearer ${token}` };

        this.myDropZone = new Dropzone('#' + this.props.id, options);

        this.myDropZone.on('error', this.onError);
        this.myDropZone.on('addedfile', this.onAddedFiles);
        this.myDropZone.on('success', this.onSuccess);
        this.myDropZone.on('complete', this.onComplete);
        this.myDropZone.on('removedfile', this.onRemovedfile);

        this.props.getDropZone(this.myDropZone);

        Dropzone.options[camelize(this.props.id)] = options;
    };

    onError = (file, error, xhr) => {
        if (xhr.status === 401) {
            window.location.replace(error);
        } else {
            this.props.setMessage(error.errorTranslationCode || error);
            this.props.setStatus(true, xhr !== undefined, false);
        }
    };

    onAddedFiles = (file) => {
        // limits number of files added to 1
        // cannot use maxfilesexceeded event because it doesn't fire if added file type
        // doesn't match any of the accepted file types
        this.myDropZone.files.forEach((elem) => {
            if (elem !== file) {
                this.myDropZone.removeFile(elem);
            }
        }, this);

        this.props.setMessage('');
        this.props.setStatus(false, false, false);
        this.props.fileCountChanged(this.myDropZone.files);

        if (file.name.substring(file.name.lastIndexOf('.')).toLowerCase() !== Upload.CSV_EXTENSION.toLowerCase()) {
            const error = { errorTranslationCode: Upload.UPLOAD_ACCEPTED_FILE_TYPES_MSG };
            this.onError(file, error);
        }
    };

    onSuccess = (file, xhr) => {
        this.props.uploadSuccess(xhr.batchJobId);
    };

    onComplete = (file, error, xhr) => {
        this.props.setUploadInProgress(false);
    };

    onRemovedfile = (file) => {
        this.props.fileCountChanged(this.myDropZone.files);
        this.props.setMessage('');
        this.props.setStatus(false, false, false);
    };

    render() {
        return (
            <div>
                <form
                    ref={this.dropZoneElementRef}
                    action={webAPIbaseURL + this.props.formAction}
                    className='dropzone dz-clickable'
                    encType='multipart/form-data'
                    method='POST'
                    id={this.props.id}
                >
                    <div className='dz-message' ref={this.dropZoneMessageRef}>
                        <span>{this.props.initialMessage}</span>
                        <div className='icon-container'>
                            <i className='fa fa-plus-circle fa-5x add-icon'></i>
                        </div>
                    </div>
                </form>

                <UploadPreviewTemplate ref={this.previewTemplateRef} filename='' translations={this.props.translations} />
            </div>
        );
    }
}

export default DropZone;
