import BasicSearchBox from '../../../../common/features/SearchBox/BasicSearchBox';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useContactsDispatch } from '../../redux/hooks';
import { setPageSelected } from '../../redux/reducers/contactsGridSettingsSlice';

interface ContactsSearchBoxProps {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

export default function ContactsSearchBox({ searchTerm, setSearchTerm }: ContactsSearchBoxProps) {
    const dispatch = useContactsDispatch();

    const translate = useTranslation();

    const handleReset = () => {
        setSearchTerm('');
    };

    const handleSubmit = (value: string) => {
        setSearchTerm(value);
        dispatch(setPageSelected(1));
    };

    return (
        <div className='p-3 w-100'>
            <BasicSearchBox
                initialValue={searchTerm}
                placeholderLabel={translate('SearchPlaceholder_Label')}
                submitButtonLabel={translate('Search_Label')}
                onReset={handleReset}
                onSubmit={handleSubmit}
            />
        </div>
    );
}
