import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import Checkbox from '../../../../../../common/features/Checkbox';
import FormInputField from '../../../../../../common/features/FormInputField';
import { inputHasValueValidator } from '../../../../../../common/functions/validators';
import useMaskedInputValidation from '../../../../../../common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { ClearanceSubscribersSlice } from '../../../../common/models/ReduxSlices';

interface NotificationSectionProps {
    email: null | string;
    emailChecked: boolean;
    phone: null | string;
    disableApply: (value: boolean) => void;
    handleMilestonesChange: (
        obj: null | ClearanceSubscribersSlice['selfSubscription']['milestones'],
        notification: null | string,
        value: null | string | boolean
    ) => void;
}

const NotificationsSection: React.FC<NotificationSectionProps> = ({
    email = null,
    emailChecked,
    phone = null,
    disableApply,
    handleMilestonesChange
}) => {
    const [isEmailChecked, setIsEmailChecked] = useState(emailChecked);
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        initial: phone,
        inputMask: '(###) ###-####',
        validators: [inputHasValueValidator, (value) => isValidPhoneNumber(value, 'US')],
        required: false
    });
    const translate = useTranslation();

    useEffect(() => {
        setIsEmailChecked(emailChecked);
    }, [emailChecked]);

    // Enable/disable "Apply" button if the mobileNumber is valid or empty string ""
    useEffect(() => {
        if ((!mobileNumberHasError && isEmailChecked) || isEmailChecked || mobileNumberIsValid) {
            disableApply(false);
        } else {
            disableApply(true);
        }
    }, [mobileNumber, disableApply, mobileNumberHasError, isEmailChecked, mobileNumberIsValid]);

    const handleCheckbox = (name: string, value: boolean) => {
        const secondParam = name === 'Email' ? email : phone;
        if (name === 'Email') setIsEmailChecked(value);
        handleMilestonesChange(null, name, value && secondParam ? secondParam : '');
    };

    return (
        <>
            <section className='border-bottom py-3'>
                <h5 className='mb-2'>{translate('HowWillReceiveNotifications_Label')}</h5>
                <form>
                    <div className='custom-control custom-checkbox mb-1'>
                        <Checkbox
                            id='customCheckEmail'
                            name='Email'
                            isChecked={isEmailChecked}
                            activeLabel='active'
                            onChange={handleCheckbox}
                        >
                            {email ? email : ''}
                        </Checkbox>
                    </div>
                    <div className='form-group'>
                        <FormInputField
                            value={mobileNumber}
                            onChange={mobileNumberChangeHandler}
                            onBlur={(e) => {
                                mobileNumberBlurHandler(e);
                                const value = e.target.value.trim();
                                if (mobileNumberIsValid || !value.length) {
                                    handleMilestonesChange(null, 'Phone', value === '' ? null : value);
                                }
                            }}
                            isValid={mobileNumberIsValid}
                            hasError={mobileNumberHasError}
                            placeholder={translate('EnterMobileNumber_Label')}
                            label=''
                            showLabel={false}
                        />
                        <div className='small'>{translate('StandardMessageAndDataRates_Label')}</div>
                        {mobileNumberHasError && <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>}
                    </div>
                </form>
            </section>
        </>
    );
};

export default NotificationsSection;
