import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../common/features/Button';
import ClientChooser from '../../../common/features/ClientChooser/ClientChooser';
import ErrorPage from '../../../common/features/ErrorPage';
import FilterBar from '../../../common/features/FilterBar/FilterBar';
import HeaderBar from '../../../common/features/HeaderBar/HeaderBar';
import ConfirmationModal from '../../../common/features/Modal/ConfirmationModal';
import Spinner from '../../../common/features/Spinner';
import { isFetchBaseQueryError } from '../../../common/functions/utils';
import { useDebounce } from '../../../common/hooks/useDebounce';
import useTranslation from '../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../common/models/ContactsModals';
import { addToast } from '../../../redux/reducers/toastSlice';
import { useContactsQuery, useUpdateContactMutation } from '../redux/api/contactsApi';
import { useContactsDispatch, useContactsSelector } from '../redux/hooks';
import AddContactsModal from './components/AddContact/AddContactsModal';
import ContactsGridCaption from './components/ContactsGridCaption';
import ContactsSearchBox from './components/ContactsSearchBox';
import ContactsTable from './ContactsTable';
import EmptyStateContacts from './EmptyStateContacts';

const initialModals: ContactsModals = {
    add: false,
    cancelAdd: false,
    remove: false,
    edit: false,
    confirm: false
};

const ContactsGrid = () => {
    const { editContact } = useContactsSelector((state) => state.contacts);
    const { user } = useContactsSelector((state) => state.hydration);
    const dispatch = useContactsDispatch();

    const translate = useTranslation();

    const { data: contacts, isLoading, error, isFetching } = useContactsQuery();
    const errorStatus = (isFetchBaseQueryError(error) && error.status) || undefined;

    const [searchTerm, setSearchTerm] = useState('');
    const filteredContacts = useMemo(() => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        return (
            contacts?.filter(
                ({ FullName, Email, MobileNumber }) =>
                    FullName.toLowerCase().includes(lowerSearchTerm) ||
                    Email.toLowerCase().includes(lowerSearchTerm) ||
                    MobileNumber?.toLowerCase().includes(lowerSearchTerm)
            ) || []
        );
    }, [contacts, searchTerm]);

    const [modal, setModal] = useState<ContactsModals>(initialModals);
    const toggleModal = useCallback(
        (prop: keyof ContactsModals | Array<keyof ContactsModals>, next?: boolean) => {
            if (typeof prop === 'string') {
                setModal({ ...modal, [prop]: next !== undefined ? next : !modal[prop] });
            } else {
                setModal(Object.assign({}, modal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [modal]
    );

    const [newContactsAmount, setNewContactsAmount] = useState(0);

    const debounceEnter = useDebounce(200);
    const debounceExit = useDebounce(5000);

    useEffect(() => {
        if (!newContactsAmount || isFetching) return;

        debounceEnter(() => {
            const rows = Array.from(document.querySelectorAll('.k-master-row'));
            rows.slice(0, newContactsAmount).forEach((row) => {
                row.classList.add('new-row-highlight');
            });
            setNewContactsAmount(0);
        });

        debounceExit(() => {
            const rows = Array.from(document.querySelectorAll('.k-master-row'));
            rows.forEach((row) => {
                row.classList.remove('new-row-highlight');
            });
        });
    }, [debounceEnter, debounceExit, newContactsAmount, isFetching]);

    const [updateContact, { isLoading: updateContactIsLoading }] = useUpdateContactMutation();

    const handleEditSubmit = async () => {
        try {
            const data = await updateContact(editContact).unwrap();

            if (data === 'SuccessfullUpdated') {
                dispatch(addToast({ message: translate('ContactsEdited_Label'), type: 'success' }));
            }
        } catch (error) {
            console.error('Failed to update contact:', error);
        }
    };

    return (
        <div className='d-flex flex-column flex-fill'>
            <HeaderBar>
                <div className='d-flex align-items-center'>
                    <span className='badge badge-circular badge-info mr-3 badge-icon-secondary'>
                        <FontAwesomeIcon icon={faAddressCard} />
                    </span>
                    <h1>{translate('Contacts_Label')}</h1>
                </div>
                {user?.InternalUser && <ClientChooser />}
            </HeaderBar>
            <FilterBar>
                <ContactsSearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </FilterBar>
            {/* Status: pending (showing only on initial load) */}
            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {contacts && contacts.length > 0 && !error && !isLoading && (
                <>
                    <ContactsGridCaption searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                    <ContactsTable filteredContacts={filteredContacts} toggleModal={toggleModal} modal={modal} />
                </>
            )}
            {/* No contacts */}
            {!error && !isLoading && contacts && contacts?.length === 0 && (
                <EmptyStateContacts
                    disabled={!user?.Roles!.includes('TeamManagement_Create') && !user?.Roles!.includes('ExternalContact_Create')}
                    descriptionLabel={translate('NoContacts_Label')}
                    addButtonLabel={translate('AddContact_Label')}
                    onAdd={() => toggleModal('add', true)}
                />
            )}
            {/* Status: rejected */}
            {error && <ErrorPage errorCode={errorStatus} withTranslations />}
            {/* Modals */}
            {modal.add && <AddContactsModal toggleModal={toggleModal} modal={modal} setNewContactsAmount={setNewContactsAmount} />}
            {modal.cancelAdd && (
                <ConfirmationModal
                    title={`${modal.edit ? translate('CancelUpdateContact_Label') : translate('CancelAddingContact_Label')}   `}
                    description={translate('LostProgress_Label')}
                    primaryButtonLabel={translate('YesCancel_Label')}
                    secondaryButtonLabel={translate('NotificationsGoBack_Label')}
                    onPrimary={() => toggleModal(['add', 'cancelAdd', 'edit'], false)}
                    onSecondary={() => {
                        toggleModal('cancelAdd', false);
                        const modalElement = document.querySelector('#AddContactsModal') as HTMLDivElement;
                        modalElement.focus();
                    }}
                />
            )}
            {modal.confirm && (
                <ConfirmationModal
                    title={translate('EditThisContact_Label')}
                    description={translate('EditContactMessage_Label')}
                    secondaryButton={
                        <Button
                            variant='secondary'
                            size='medium'
                            className='d-flex align-items-center'
                            disabled={updateContactIsLoading}
                            onClick={async () => {
                                await handleEditSubmit();
                                toggleModal(['edit', 'confirm'], false);
                            }}
                        >
                            {updateContactIsLoading && <Spinner size='small' color='inherit' className='mr-2' />}
                            <span>{translate('ConfirmEditContact_Label')}</span>
                        </Button>
                    }
                    primaryButtonLabel={translate('Cancel_Label')}
                    onPrimary={() => toggleModal('confirm', false)}
                />
            )}
        </div>
    );
};

export default ContactsGrid;
