import { API_TEAM_PATH } from '../../../../common/constants/constants-portal';
import { apiSlice } from '../../../../redux/api/apiSlice';
import type { Contact, TeamsAdminByClientServices } from '../../common/models/Contact';

export const contactsApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        teamAdmins: build.query<TeamsAdminByClientServices[], void>({
            query: () => ({
                url: `${API_TEAM_PATH}/GetTeamsAdminsByUsersClientServices`
            })
        }),
        contacts: build.query<Contact[], void>({
            query: () => ({
                url: `${API_TEAM_PATH}/GetExternalContacts`
            }),
            providesTags: ['Contacts']
        }),
        createContacts: build.mutation<string, Contact[]>({
            query: (body) => ({
                url: `${API_TEAM_PATH}/NewExternalContacts`,
                method: 'POST',
                body: body.map(
                    ({
                        IsFullNameValid,
                        IsEmailValid,
                        IsMobileNumberValid,
                        IsRoleLabelValid,
                        IsLanguageValid,
                        IsCarrierCodesValid,
                        ...newValue
                    }) => newValue
                )
            }),
            invalidatesTags: ['Contacts']
        }),
        deleteContacts: build.mutation<string, number[]>({
            query: (body) => ({
                url: `${API_TEAM_PATH}/RemoveExternalContacts`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Contacts']
        }),
        updateContact: build.mutation<string, Contact>({
            query: ({
                IsFullNameValid,
                IsEmailValid,
                IsMobileNumberValid,
                IsRoleLabelValid,
                IsLanguageValid,
                IsCarrierCodesValid,
                ...newValue
            }) => ({
                url: `${API_TEAM_PATH}/UpdateExternalContact`,
                method: 'POST',
                body: newValue
            }),
            invalidatesTags: ['Contacts']
        })
    })
});

export const { useTeamAdminsQuery, useContactsQuery, useCreateContactsMutation, useDeleteContactsMutation, useUpdateContactMutation } =
    contactsApi;
