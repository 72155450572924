import type { FormEvent } from 'react';
import Button from '../../../../../common/features/Button';
import Spinner from '../../../../../common/features/Spinner';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../common/hooks/useTranslation';
import type { ProfileModals } from '../../../../../common/models/SubscribersModals';
import { addToast } from '../../../../../redux/reducers/toastSlice';
import type { GridSubscriptions } from '../../../common/constants/constants-profile';
import { useUnsubscribeMultipleMutation } from '../../../redux/api/profileApi';
import { useProfileDispatch } from '../../../redux/hooks';

interface UnsubscribeMultipleModalProps {
    subscribers: GridSubscriptions[];
    toggleModal: (prop: keyof ProfileModals | Array<keyof ProfileModals>, next?: boolean) => void;
}

export default function UnsubscribeMultipleModal({ subscribers, toggleModal }: UnsubscribeMultipleModalProps) {
    const dispatch = useProfileDispatch();

    const translate = useTranslation();

    const handleCancel = () => {
        toggleModal('remove');
    };

    const [multipleUnsubscribe, { isLoading }] = useUnsubscribeMultipleMutation();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const subscriptionIds = subscribers.reduce<number[]>((acc, subscriber) => {
            if (subscriber.SubscriptionId) {
                acc.push(subscriber.SubscriptionId);
            }
            return acc;
        }, []);

        if (!subscriptionIds.length) return;

        try {
            await multipleUnsubscribe({ subscriptionIds });

            const alertMessage = translate('UnsubscribedFromClients_Label', subscriptionIds.length);
            dispatch(addToast({ message: alertMessage, type: 'success' }));
        } catch (error) {
            console.error('Failed to unsubscribe multiple:', error);
        }

        toggleModal('remove');
    };

    return (
        <ModalWrapper>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <form onSubmit={handleSubmit} className='modal-content'>
                    <div className='modal-body'>
                        <h2 className='h3 modal-title mb-4' id='staticBackdropLabel'>
                            {translate('RemoveMultipleSubscribers_Label', subscribers?.length.toString())}
                        </h2>
                        <p className='note mb-0 notification-message w-100'>
                            {translate('UnsubscribeMultipleModal_Label', subscribers?.length.toString())}
                        </p>
                    </div>
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleCancel}>
                            {translate('Cancel_Label')}
                        </Button>
                        <Button type='submit' variant='danger' size='medium' disabled={isLoading} className='d-flex align-items-center'>
                            {isLoading && <Spinner size='small' color='inherit' className='mr-2' />}
                            {translate('YesUnsubscribe_Label', subscribers?.length.toString())}
                        </Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    );
}
