import { clsx } from 'clsx';

interface ContainerProps {
    className?: string;
    children?: React.ReactNode;
    fluidNoPadding?: boolean;
    fluid?: boolean;
    container?: boolean;
    narrow?: boolean;
    whiteBackGround?: boolean;
    borderBottomFlushed?: boolean;
    borderYFlushed?: boolean;
}

const Container: React.FC<ContainerProps> = ({
    className = '',
    children,
    fluidNoPadding = false,
    fluid = false,
    container = false,
    narrow = false,
    whiteBackGround = false,
    borderBottomFlushed = false,
    borderYFlushed = false
}) => {
    let propToClassName;

    if (fluidNoPadding) {
        propToClassName = 'container-fluid-nopadding';
    } else if (fluid) {
        propToClassName = 'container-fluid';
    } else if (container) {
        propToClassName = 'container';
    } else if (narrow) {
        propToClassName = 'container-narrow';
    } else {
        propToClassName = 'container-fluid';
    }

    return (
        <>
            {borderBottomFlushed || borderYFlushed ? (
                <div className={clsx('border-bottom', borderYFlushed && 'border-top')}>
                    <div className={clsx(propToClassName, className, whiteBackGround && 'bg-white')}>{children}</div>
                </div>
            ) : (
                <div
                    className={clsx(propToClassName, className, whiteBackGround && 'bg-white')}
                    style={{ paddingRight: '2.5%', paddingLeft: '2.5%' }}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default Container;
