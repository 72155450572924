import ReactDOM from 'react-dom';
import { usePortalSelector } from '../../redux/hooks';
import Toast, { ToastProps } from './Toast';

export default function ToastWithPortal({ customTimeout, ...rest }: ToastProps) {
    const { user } = usePortalSelector((state) => state.hydration);

    const timeout = customTimeout || user?.PortalTimeoutShort || 3000;

    return (
        <>
            {ReactDOM.createPortal(
                <Toast customTimeout={timeout} {...rest} />,
                document.querySelector('#portalToasts .toasts-wrapper') || document.body
            )}
        </>
    );
}
