import { NotificationsLabels } from '../constants/constants-portal';
import useTranslation from '../hooks/useTranslation';
import Checkbox from './Checkbox';
import Tooltip from './Tooltip/Tooltip';

// Canada checkboxes
const CanadaCheckboxes = ({
    notificationSettings,
    handleChange,
    setNotificationSettings,
    addSubscriberModal,
    onHoldTitle,
    onHoldLabel
}) => {
    const translate = useTranslation();
    const notificationsSettingsArray = Object.entries(notificationSettings);
    const notificationsCheckboxes = notificationsSettingsArray.filter((item) => {
        return item[0].includes('_CA');
    });

    const isSelectedAll = notificationsCheckboxes.every((item) => item[1]);

    const toggleSelectAll = () => {
        const selected = notificationsCheckboxes.reduce((total, item) => {
            if (!isSelectedAll) {
                return { ...total, [item[0]]: true };
            } else {
                return { ...total, [item[0]]: false };
            }
        }, {});
        setNotificationSettings((not) => ({ ...not, ...selected }));
    };

    const onHoldItemCanada = NotificationsLabels.Canada[NotificationsLabels.Canada.length - 1];
    let renderCheckboxes = NotificationsLabels.Canada.map((item) => (
        <Checkbox
            divClass='custom-control custom-checkbox'
            key={`${item.key}_CA`}
            name={`${item.key}_CA`}
            id={`canada-check-${item.key}_CA`}
            onChange={handleChange}
            isChecked={notificationSettings[`${item.key}_CA`]}
        >
            <Tooltip label={translate(item.hint)}>
                <span>{translate(item.label)}</span>
            </Tooltip>
        </Checkbox>
    ));
    renderCheckboxes.pop();
    return (
        <div className='country-milestones'>
            <p className='country'>
                {translate('NotificationsCountryCanada_Label')}{' '}
                <span onClick={toggleSelectAll} className='select-all pl-1' style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {!isSelectedAll
                        ? translate('NotificationsSelectAllNotifications_Label')
                        : translate('NotificationsDeselectAllNotifications_Label')}
                </span>
            </p>
            {addSubscriberModal && (
                <div className='checkboxes'>
                    <Checkbox
                        divClass='custom-control custom-checkbox'
                        name={`${onHoldItemCanada.key}_CA`}
                        id={`canada-check-${onHoldItemCanada.key}_CA`}
                        onChange={handleChange}
                        isChecked={notificationSettings[`${onHoldItemCanada.key}_CA`]}
                    >
                        <Tooltip label={translate(onHoldItemCanada.hint)}>
                            <span>{translate(onHoldItemCanada.label)}</span>
                        </Tooltip>
                    </Checkbox>
                </div>
            )}

            <div className='checkboxes pb-4'>
                {addSubscriberModal && <p className='note pt-1'>{translate('NotificationsUncheckedTitle_Label')}</p>}

                {!addSubscriberModal && (
                    <>
                        <p className='pt-3' style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                            {onHoldTitle}
                        </p>
                        <div className='checkboxes'>
                            <Checkbox
                                divClass='custom-control custom-checkbox'
                                name={`${onHoldItemCanada.key}_CA`}
                                id={`canada-check-${onHoldItemCanada.key}_CA`}
                                onChange={handleChange}
                                isChecked={notificationSettings[`${onHoldItemCanada.key}_CA`]}
                            >
                                <Tooltip label={translate(onHoldItemCanada.hint)}>
                                    <span>{onHoldLabel}</span>
                                </Tooltip>
                            </Checkbox>
                        </div>
                        <p className='note pt-1'>{translate('NotificationsUncheckedTitle_Label')}</p>
                    </>
                )}
                <br />
                {renderCheckboxes}
            </div>
        </div>
    );
};

// USA checkboxes
const USACheckboxes = ({ notificationSettings, handleChange, setNotificationSettings, addSubscriberModal, onHoldTitle, onHoldLabel }) => {
    const translate = useTranslation();
    const notificationsSettingsArray = Object.entries(notificationSettings);
    const notificationsCheckboxes = notificationsSettingsArray.filter((item) => {
        return !item[0].includes('_CA') && item[0].includes('HasNotificationsFor');
    });

    const isSelectedAll = notificationsCheckboxes.every((item) => item[1]);

    const toggleSelectAll = () => {
        const selected = notificationsCheckboxes.reduce((total, item) => {
            if (!isSelectedAll) {
                return { ...total, [item[0]]: true };
            } else {
                return { ...total, [item[0]]: false };
            }
        }, {});
        setNotificationSettings((not) => ({ ...not, ...selected }));
    };
    const onHoldItemUSA = NotificationsLabels.USA[NotificationsLabels.USA.length - 1];
    let renderCheckboxes = NotificationsLabels.USA.map((item) => (
        <Checkbox
            divClass='custom-control custom-checkbox'
            key={item.key}
            name={item.key}
            id={`usa-check-${item.key}`}
            onChange={handleChange}
            isChecked={notificationSettings[item.key]}
        >
            <Tooltip label={translate(item.hint)}>
                <span>{translate(item.label)}</span>
            </Tooltip>
        </Checkbox>
    ));
    renderCheckboxes.pop();
    return (
        <div className='country-milestones pr-5'>
            <p className='country'>
                {translate('NotificationsCountryUsa_Label')}{' '}
                <span onClick={toggleSelectAll} className='select-all pl-1' style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {!isSelectedAll
                        ? translate('NotificationsSelectAllNotifications_Label')
                        : translate('NotificationsDeselectAllNotifications_Label')}
                </span>
            </p>
            {addSubscriberModal && (
                <div className='checkboxes'>
                    <Checkbox
                        divClass='custom-control custom-checkbox'
                        name={onHoldItemUSA.key}
                        id={`usa-check-${onHoldItemUSA.key}`}
                        onChange={handleChange}
                        isChecked={notificationSettings[onHoldItemUSA.key]}
                    >
                        <Tooltip label={translate(onHoldItemUSA.hint)}>
                            <span>{translate(onHoldItemUSA.label)}</span>
                        </Tooltip>
                    </Checkbox>
                </div>
            )}

            <div className='checkboxes pb-4'>
                {addSubscriberModal && <p className='note pt-1'>{translate('NotificationsUncheckedTitle_Label')}</p>}

                {!addSubscriberModal && (
                    <>
                        <p className='pt-3' style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                            {onHoldTitle}
                        </p>
                        <div className='checkboxes'>
                            <Checkbox
                                divClass='custom-control custom-checkbox'
                                name={onHoldItemUSA.key}
                                id={`usa-check-${onHoldItemUSA.key}`}
                                onChange={handleChange}
                                isChecked={notificationSettings[onHoldItemUSA.key]}
                            >
                                <Tooltip label={translate(onHoldItemUSA.hint)}>
                                    <span>{onHoldLabel}</span>
                                </Tooltip>
                            </Checkbox>
                        </div>
                        <p className='note pt-1'>{translate('NotificationsUncheckedTitle_Label')}</p>
                    </>
                )}
                <br />
                {renderCheckboxes}
            </div>
        </div>
    );
};

const NotificationsCheckboxes = ({
    country,
    notificationSettings,
    handleChange,
    setNotificationSettings,
    addSubscriberModal,
    onHoldTitle,
    onHoldLabel
}) => {
    if (country.length === 1 && country[0].id === 'US') {
        return (
            <USACheckboxes
                notificationSettings={notificationSettings}
                handleChange={handleChange}
                setNotificationSettings={setNotificationSettings}
                addSubscriberModal={addSubscriberModal}
                onHoldTitle={onHoldTitle}
                onHoldLabel={onHoldLabel}
            />
        );
    } else if (country.length === 1 && country[0].id === 'CA') {
        return (
            <CanadaCheckboxes
                notificationSettings={notificationSettings}
                handleChange={handleChange}
                setNotificationSettings={setNotificationSettings}
                addSubscriberModal={addSubscriberModal}
                onHoldTitle={onHoldTitle}
                onHoldLabel={onHoldLabel}
            />
        );
    } else {
        return (
            <>
                <USACheckboxes
                    notificationSettings={notificationSettings}
                    handleChange={handleChange}
                    setNotificationSettings={setNotificationSettings}
                    addSubscriberModal={addSubscriberModal}
                    onHoldTitle={onHoldTitle}
                    onHoldLabel={onHoldLabel}
                />
                <CanadaCheckboxes
                    notificationSettings={notificationSettings}
                    handleChange={handleChange}
                    setNotificationSettings={setNotificationSettings}
                    addSubscriberModal={addSubscriberModal}
                    onHoldTitle={onHoldTitle}
                    onHoldLabel={onHoldLabel}
                />
            </>
        );
    }
};

export default NotificationsCheckboxes;
