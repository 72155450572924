import { faFileAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { CSSTransition } from 'react-transition-group';
import useTranslation from '../../../../common/hooks/useTranslation';
import { FileData } from '../../common/models/ImagingModel';

interface DownloadModalPreviewProps {
    multiplePDFfilesData: { details: string[]; id: number; title: string }[];
    singlePDFfilesData: { details: string[]; id: number; title: string }[];
    selectedOption: string;
    documentS3Array: string[] | undefined;
    groupedBy?: string;
    sortedBy?: string;
    documentNameGenerated?: string;
    ascOrder?: string;
    downloadFunction: (ImagePackageId: number) => void;
    isGroupBySelected: boolean;
    funResults: { [key: string]: string[] } | string[] | undefined | FileData[];
    groupByAscOrder: string;
    objectContainingFiles?: { [key: string]: FileData[] };
    countrySelected: string | undefined;
    formatOptions: (a: string) => string;
    firstSortedBy: string;
}

const DownloadModalPreview: React.FC<DownloadModalPreviewProps> = ({
    multiplePDFfilesData,
    singlePDFfilesData,
    selectedOption,
    documentS3Array,
    groupedBy,
    sortedBy,
    documentNameGenerated,
    ascOrder,
    downloadFunction,
    isGroupBySelected,
    funResults,
    groupByAscOrder,
    objectContainingFiles,
    countrySelected,
    formatOptions,
    firstSortedBy
}) => {
    const translate = useTranslation();
    function renderGroupedResults(results: { [key: string]: string[] }, sortParam: string, groupedByDocuments: string | undefined) {
        return Object.keys(results).map((key: string) => (
            <div key={key} className='d-flex flex-column border-bottom pb-3 mb-3'>
                <div className='font-weight-medium'>
                    <FontAwesomeIcon icon={faFileAlt} className='mr-2 text-lii-text-light' />
                    {groupedBy === 'Document date' ? key.replaceAll('-', '_') : key}
                </div>
                <div className='file-breakdown'>
                    {`${results[key].length} ${translate('MergedDocuments_Label')} | ${translate('GroupedBy_Label')} ${translate(
                        formatOptions(groupedByDocuments ?? '')
                    )} | ${translate('SortedBy_Label')} ${translate(formatOptions(sortedBy ?? ''))} | ${
                        sortParam === 'ASC' ? translate('Ascending_Label') : translate('Descending_Label')
                    }`}
                </div>
            </div>
        ));
    }

    function isArrayOfStrings(value: unknown): value is string[] {
        return Array.isArray(value) && value.every((item) => typeof item === 'string');
    }

    function renderGroup() {
        if ('object' === typeof objectContainingFiles && objectContainingFiles !== null && !Array.isArray(objectContainingFiles)) {
            return Object.keys(objectContainingFiles).map((key: string) => {
                return (
                    <div key={key} className='d-flex flex-column border-bottom pb-3 mb-3'>
                        <div className='font-weight-medium'>
                            <FontAwesomeIcon icon={faFileAlt} className='mr-2 text-lii-text-light' />
                            {countrySelected === 'us'
                                ? `${objectContainingFiles[key][0]._entryNumber}-${
                                      objectContainingFiles[key][0]._metadataFormat
                                  }-${objectContainingFiles[key][0]._imageDate?.replaceAll('-', '/')}`
                                : `${objectContainingFiles[key][0]._transactionNumber}-${
                                      objectContainingFiles[key][0]._metadataFormat
                                  }-${objectContainingFiles[key][0]._imageDate?.replaceAll('-', '/')}`}
                        </div>
                    </div>
                );
            });
        }
    }

    return (
        <div className='col-12 col-lg-5 p-lg-0'>
            <div className='d-lg-flex flex-column justify-content-between h-100'>
                <div className='download-preview flex-shrink-1 p-3 p-lg-4'>
                    <div className='position-relative'>
                        <CSSTransition in={selectedOption === 'MultiplePDF'} timeout={300} classNames='fade-updown' unmountOnExit>
                            <div id='MultiplePDFPreview'>
                                <h4 className='font-weight-bold border-bottom pb-3 mb-3 '>
                                    {translate(
                                        'YourZipWillContain_Label',
                                        typeof funResults === 'object' ? Object.keys(funResults).length : documentS3Array?.length
                                    )}
                                </h4>
                                {!isGroupBySelected && !isArrayOfStrings(funResults) && renderGroup()}

                                {isGroupBySelected &&
                                    funResults &&
                                    typeof funResults === 'object' &&
                                    renderGroupedResults(funResults as { [key: string]: string[] }, groupByAscOrder, groupedBy)}
                            </div>
                        </CSSTransition>
                        <CSSTransition in={selectedOption === 'SingleMergedPDF'} timeout={300} classNames='fade-updown' unmountOnExit>
                            <div id='SinglePDFPreview'>
                                <h4 className='font-weight-bold border-bottom pb-3 mb-3 '>{translate('SingleMergedPdf_Label')}</h4>
                                <div className='d-flex flex-column border-bottom pb-3 mb-3'>
                                    <div className='font-weight-medium mb-1'>
                                        <FontAwesomeIcon icon={faFileAlt} className='mr-2 text-lii-text-light' />
                                        {isArrayOfStrings(funResults) && `${documentNameGenerated} ${funResults[0]}`}
                                    </div>
                                    <div className='file-breakdown'>
                                        {`${funResults?.length} ${translate('MergedDocuments_Label')} | ${translate(
                                            'FirstSortedBy_Label'
                                        )} ${translate(formatOptions(firstSortedBy ?? ''))} | ${
                                            ascOrder === 'ASC' ? translate('Ascending_Label') : translate('Descending_Label')
                                        } | ${translate('ThenSortedBy_Label')} ${translate(formatOptions(sortedBy ?? ''))} | 
                                        ${groupByAscOrder === 'ASC' ? translate('Ascending_Label') : translate('Descending_Label')}`}
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
                <div className={window.innerWidth <= 768 ? 'modal-footer d-lg-none' : 'd-none d-lg-block border-top p-lg-4'}>
                    <div className={clsx('position-relative', window.innerWidth <= 768 && 'w-100')}>
                        <CSSTransition in={selectedOption === 'MultiplePDF'} timeout={300} classNames='fade' unmountOnExit>
                            <button
                                onClick={() => {
                                    downloadFunction(1);
                                }}
                                type='button'
                                className={clsx('btn btn-primary btn-lg', window.innerWidth <= 768 && 'btn-block')}
                            >
                                {translate('DownloadZIPFile_Label')} <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                            </button>
                        </CSSTransition>
                        <CSSTransition in={selectedOption === 'SingleMergedPDF'} timeout={300} classNames='fade' unmountOnExit>
                            <button
                                onClick={() => {
                                    downloadFunction(2);
                                }}
                                type='button'
                                className={clsx('btn btn-primary btn-lg', window.innerWidth <= 768 && 'btn-block')}
                            >
                                {translate('DownloadMergedPDF_Label')} <FontAwesomeIcon icon={faFileDownload} className='ml-2' />
                            </button>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadModalPreview;
