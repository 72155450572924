import { useContext } from 'react';
import { ClientChooserContext } from '../ClientChooser';

export const useClientChooserContext = () => {
    const context = useContext(ClientChooserContext);
    if (!context) {
        throw new Error('useClientChooserContext must be used within a ClientChooserProvider');
    }
    return context;
};
