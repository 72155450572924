import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormEvent } from 'react';
import { useEffect } from 'react';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import { isAddContactFormValid } from '../../../../../common/functions/validators';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../../../common/models/ContactsModals';
import { addToast } from '../../../../../redux/reducers/toastSlice';
import { useCreateContactsMutation } from '../../../redux/api/contactsApi';
import { useContactsDispatch, useContactsSelector } from '../../../redux/hooks';
import { setPageSelected } from '../../../redux/reducers/contactsGridSettingsSlice';
import { resetNewContacts } from '../../../redux/reducers/contactsSlice';
import ContactInfoForm from './ContactInfoForm';

interface AddContactsModalProps {
    modal: ContactsModals;
    toggleModal: (prop: keyof ContactsModals, next?: boolean) => void;
    setNewContactsAmount: React.Dispatch<React.SetStateAction<number>>;
}

export default function AddContactsModal({ modal, toggleModal, setNewContactsAmount }: AddContactsModalProps) {
    const { newContacts } = useContactsSelector((state) => state.contacts);
    const { user } = useContactsSelector((state) => state.hydration);
    const dispatch = useContactsDispatch();

    const translate = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(resetNewContacts());
        };
    }, [dispatch]);

    const [createNewContacts, { isLoading }] = useCreateContactsMutation();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!isAddContactFormValid(newContacts, user?.InternalUser)) return;

        try {
            const data = await createNewContacts(
                newContacts.map((item) => ({
                    ...item,
                    ExternalContactID: 0,
                    CarrierCodes: item.RoleLabel.includes('Carrier') ? item.CarrierCodes : null
                }))
            ).unwrap();

            if (data === 'SuccessfullAdded') {
                dispatch(addToast({ message: translate('ContactsAdded_Label', newContacts.length), type: 'success' }));
                setNewContactsAmount(newContacts.length);
                dispatch(setPageSelected(1));
            }
        } catch (error) {
            console.error('Error while adding contact:', error);
        }

        toggleModal('add', false);
    };

    return (
        <>
            <ModalWrapper id='AddContactsModal' onEscape={() => toggleModal('cancelAdd', true)}>
                <div className='modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                        {/* MODAL HEADER */}
                        <div className='modal-header'>
                            <div className='mr-4'>
                                <h3 className='mb-0'>{translate('AddContact_Label')}</h3>
                                <h4 className='font-weight-medium mb-0'>{translate('AddContactMessage_Label')}</h4>
                            </div>
                            <Button variant='tertiary' className='btn-lg' onClick={() => toggleModal('cancelAdd', true)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </div>
                        <ContactInfoForm modal={modal} onSubmit={handleSubmit} isSubmitting={isLoading} />
                    </div>
                </div>
            </ModalWrapper>
        </>
    );
}
