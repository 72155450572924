import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { REACT_APP_ASSETS_URL_CSS } from '../../common/constants/constants-portal';
import Protected from '../../common/features/Protected';
import Toaster from '../../common/features/Toaster';
import App from './App';
import store from './redux/store';

const Portal = () => {
    return (
        <Provider store={store}>
            <Protected conditions={{ isInternalUser: true }} redirectUrl='/'>
                <HelmetProvider>
                    <Helmet>
                        <link rel='stylesheet' href={REACT_APP_ASSETS_URL_CSS} />
                    </Helmet>
                    <Toaster />
                    <App />
                </HelmetProvider>
            </Protected>
        </Provider>
    );
};

export default Portal;
