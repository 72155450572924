import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useDebounce } from '../../hooks/useDebounce';

const ENTER_TIMEOUT = 50;

interface ToastWrapperProps {
    type: 'error' | 'success' | 'warning' | 'notice-alert' | 'on-hold-alert';
    timeout: number;
    children: React.ReactNode;
    onClose?: () => void;
}

const ToastWrapper: React.FC<ToastWrapperProps> = ({ type, children, timeout, onClose }) => {
    const [showAnimation, setShowAnimation] = useState(false);
    const nodeRef = useRef(null);

    const debounceEnter = useDebounce(ENTER_TIMEOUT);
    const debounceExit = useDebounce(timeout);

    useEffect(() => {
        debounceEnter(() => {
            setShowAnimation(true);
        });

        debounceExit(() => {
            if (onClose) onClose();

            setShowAnimation(false);
        });
    }, [debounceEnter, debounceExit, timeout]);

    return (
        <>
            {ReactDOM.createPortal(
                <CSSTransition
                    in={showAnimation}
                    timeout={200}
                    classNames={{
                        appear: 'fade',
                        appearActive: 'fade showing',
                        appearDone: 'fade show',
                        enter: 'fade',
                        enterActive: 'fade showing',
                        enterDone: 'fade show',
                        exit: 'fade',
                        exitActive: 'fade',
                        exitDone: 'fade hide'
                    }}
                    unmountOnExit
                    nodeRef={nodeRef}
                >
                    <div
                        className={clsx('toast', type)}
                        ref={nodeRef}
                        role={type === 'notice-alert' || type === 'on-hold-alert' ? 'alert' : undefined}
                        aria-live={type === 'notice-alert' || type === 'on-hold-alert' ? 'assertive' : undefined}
                        aria-atomic={type === 'notice-alert' || type === 'on-hold-alert' ? 'true' : undefined}
                    >
                        {children}
                    </div>
                </CSSTransition>,
                document.querySelector(
                    `#portalToasts .toasts-wrapper${type === 'notice-alert' || type === 'on-hold-alert' ? '.alert-toasts-wrapper' : ''}`
                ) || document.body
            )}
        </>
    );
};

export default ToastWrapper;
