import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDebounce } from '../hooks/useDebounce';
import useTranslationHydration from '../hooks/useTranslationHydration';
import Badge from './Badge';

const TOAST_TYPES = {
    success: 'success',
    warning: 'warning',
    error: 'danger'
} as const;

const TOAST_ICONS = {
    success: faCheckCircle,
    warning: faExclamationCircle,
    error: faTimesCircle
};

type ToastType = keyof typeof TOAST_TYPES;

export interface ToastProps {
    message: string;
    isLabel?: boolean;
    type: ToastType;
    customTimeout?: number;
}

export default function Toast({ type, message, isLabel = false, customTimeout }: ToastProps) {
    const translate = useTranslationHydration();

    const nodeRef = useRef<HTMLDivElement>(null);

    const alertMsg = isLabel ? translate(message) : message;
    const [showAnimation, setShowAnimation] = useState(false);

    const debounceEnter = useDebounce(50);
    const debounceExit = useDebounce(customTimeout);

    useEffect(() => {
        debounceEnter(() => {
            setShowAnimation(true);
        });

        debounceExit(() => {
            setShowAnimation(false);
        });
    }, [debounceEnter, debounceExit]);

    return (
        <CSSTransition
            timeout={200}
            in={showAnimation}
            nodeRef={nodeRef}
            classNames={{
                appear: 'fade',
                appearActive: 'fade showing',
                appearDone: 'fade show',
                enter: 'fade',
                enterActive: 'fade showing',
                enterDone: 'fade show',
                exit: 'fade',
                exitActive: 'fade',
                exitDone: 'fade hide'
            }}
            unmountOnExit
        >
            <div className={clsx('toast', type)} ref={nodeRef}>
                <div className='toast-body'>
                    <div className='toast-icon'>
                        <Badge type={TOAST_TYPES[type]} variant='circular' size='small'>
                            <FontAwesomeIcon icon={TOAST_ICONS[type]} />
                        </Badge>
                    </div>
                    <div className='toast-text'>{alertMsg}</div>
                </div>
            </div>
        </CSSTransition>
    );
}
