import { Link, Redirect, useLocation } from 'react-router-dom';
import { PortalRoutes, REACT_APP_ASSETS_URL } from '../../../common/constants/constants-portal';
import Breadcrumb from '../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../common/features/Breadcrumb/BreadcrumbItem';
import useTranslation from '../../../common/hooks/useTranslation';

export default function RequestSuccessPage() {
    const translate = useTranslation();

    const location = useLocation<{ requestType?: string; caseNumber?: string }>();
    const caseNumber = location.state?.caseNumber;

    if (!caseNumber) {
        return <Redirect to='/Profile' />;
    }

    return (
        <div className='d-flex flex-column'>
            <Breadcrumb>
                <BreadcrumbItem url={PortalRoutes.profile}>{translate('MyProfile_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{translate('RequestAnUpdate_Label')}</BreadcrumbItem>
            </Breadcrumb>
            <div className='container-narrow my-4 my-md-5'>
                <div className='row'>
                    <div className='col'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='centered-header text-center'>
                                    <div className='header-image mb-3 mb-md-4'>
                                        <img
                                            src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/objects/object-notes-tick.svg`}
                                            alt='500 system failure'
                                        />
                                    </div>
                                    <h1 className='mb-3 mb-md-4'> {translate('RequestSubmitted_Label')} </h1>
                                    <div className='alert alert-info d-inline-block' role='alert'>
                                        {translate('YourCaseNumber_Label')} <strong>{caseNumber}</strong>
                                    </div>
                                    <p className='mb-3 mb-md-4'>{translate('ClientServiceRepresentative_Label')}</p>
                                    <Link to='/Profile' type='button' className='btn btn-primary btn-lg mb-md-4'>
                                        {translate('ReturnToProfile_Label')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
