import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_IMAGING_PATH, API_SEARCH_SHIPMENT_PATH } from '../../../../common/constants/constants-portal';
import { ClearanceListItem } from '../../../../common/models/ShipmentList';
import { addToDownloadLobby } from '../../../../redux/reducers/alertsSlice';
import { addToast } from '../../../../redux/reducers/toastSlice';
import { Filters } from '../../../documents/common/models/ImagingModel';
import { SEARCH_SHIPMENT_VISIBLE_COLUMNS } from '../../common/constants/constants-clearances';
import { DocumentsIndex, DownloadDocumentResponse, EntryNumbers, FilterControlModel } from '../../common/models/Documents';
import { DOWNLOAD_DOCUMENTS, EXPORT_HEADER_AND_DETAIL, GET_DOCUMENTS_IMAGES, GET_LINE_DETAILS, GET_SHIPMENT_HEADER } from './actionTypes';

export const GetClearanceDocuments = createAsyncThunk(GET_DOCUMENTS_IMAGES, async (payload: { entryNo: string }) => {
    try {
        const response = await axios.get(`${API_SEARCH_SHIPMENT_PATH}/ShipmentImagesForClearances?entryNumber=${payload.entryNo}`);

        return response.data;
    } catch (error: any) {
        return error.response.status;
    }
});

export const DownloadDocuments = createAsyncThunk(
    DOWNLOAD_DOCUMENTS,
    async (payload: { fileNames?: { [K in string]: string[] }; entryNumbers?: EntryNumbers; ImagePackageId?: number }, { dispatch }) => {
        try {
            const response = await axios.post<DownloadDocumentResponse>(`${API_IMAGING_PATH}/Download`, {
                S3ImageFileNames: payload.fileNames ?? {},
                ImagePackage: {
                    ImagingPackageTypeID: payload.ImagePackageId ?? 1
                },
                EntryNumbers: payload.entryNumbers ?? {},
                IsPortalSearch: true
            });

            if (response.data.ImagingPackageStatusTypeID !== 4 && !response.data.ImageUrl.includes('DownloadLobby')) {
                dispatch(
                    addToast({
                        message: 'DownloadDocumentsError_Label',
                        isLabel: true,
                        type: 'error'
                    })
                );
            } else if (response.data.ImagingPackageStatusTypeID === 4) {
                dispatch(
                    addToast({
                        message: 'DownloadDocumentsSuccess_Label',
                        isLabel: true,
                        type: 'success'
                    })
                );
            }

            // Check if response has download lobby
            if (response.data.ImageUrl.includes('DownloadLobby')) {
                const match = response.data.ImageUrl.match(/packageId=(\d+)/);

                if (match) {
                    const packageId = match[1];
                    dispatch(addToDownloadLobby({ packageId }));
                }
            }

            // Download the response ImageUrl if it isn't download lobby
            // ImagingPackageStatusTypeID is a constant value, and 4 means that download is successfully completed
            if (!response.data.ImageUrl.includes('DownloadLobby') && response.data.ImagingPackageStatusTypeID === 4) {
                window.open(response.data.ImageUrl);
            }

            return response.data;
        } catch (error: any) {
            // Password protected documents
            if (
                error.response.status === 400 &&
                error.response.data?.Message &&
                error.response.data?.Message.includes('password protected')
            ) {
                dispatch(
                    addToast({
                        message: 'PasswordProtectedDocument_Label',
                        isLabel: true,
                        type: 'warning'
                    })
                );
            }

            if (
                error.response.status === 404 &&
                typeof error.response.data === 'string' &&
                error.response.data === 'No documents to download.'
            ) {
                dispatch(addToast({ message: 'NoDocumentsAvailableCheckLater_Label', isLabel: true, type: 'error' }));
            }

            if (error.response.status === 500) {
                dispatch(addToast({ message: 'DownloadLobbyErrorTitle_Label', isLabel: true, type: 'error' }));
            }

            return error.response.status;
        }
    }
);

export const GetLineDetails = createAsyncThunk(GET_LINE_DETAILS, async (payload: { fileNo: string }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${API_SEARCH_SHIPMENT_PATH}/ShipmentDetails?fileNo=C${payload.fileNo}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

export const GetShipmentHeader = createAsyncThunk(GET_SHIPMENT_HEADER, async (payload: { vm: DocumentsIndex }, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${API_SEARCH_SHIPMENT_PATH}/GetShipmentHeader`, payload.vm);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

export const ExportHeaderAndDetail = createAsyncThunk(
    EXPORT_HEADER_AND_DETAIL,
    async (
        payload: {
            shipments: Partial<ClearanceListItem>[];
            imageGroupings: any[];
            filters: FilterControlModel[] | Filters[];
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await axios.post(`${API_SEARCH_SHIPMENT_PATH}/ExportHeaderAndDetail`, {
                Shipments: payload.shipments,
                ImageGroupings: payload.imageGroupings,
                Filters: payload.filters,
                SelectAll: true,
                VisibleColumns: SEARCH_SHIPMENT_VISIBLE_COLUMNS
            });

            const resData = JSON.parse(response.data) as DownloadDocumentResponse;

            if (resData.ImagingPackageStatusTypeID !== 4 && !resData.ImageUrl.includes('DownloadLobby')) {
                dispatch(
                    addToast({
                        message: 'DownloadDocumentsError_Label',
                        isLabel: true,
                        type: 'error'
                    })
                );
            }

            // Check if response has download lobby
            if (resData.ImageUrl.includes('DownloadLobby')) {
                const match = resData.ImageUrl.match(/packageId=(\d+)/);

                if (match) {
                    const packageId = match[1];
                    dispatch(addToDownloadLobby({ packageId }));
                }
            }

            // Download the response ImageUrl if it isn't download lobby
            // ImagingPackageStatusTypeID is a constant value, and 4 means that download is successfully completed
            if (!resData.ImageUrl.includes('DownloadLobby') && resData.ImagingPackageStatusTypeID === 4) {
                window.open(resData.ImageUrl);
            }

            return resData;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
