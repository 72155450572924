import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorPage from '../../../../../common/features/ErrorPage';
import Spinner from '../../../../../common/features/Spinner';
import { isFetchBaseQueryError } from '../../../../../common/functions/utils';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { useUserDetailsQuery, useUserTicketsQuery } from '../../../redux/api/profileApi';
import { useProfileSelector } from '../../../redux/hooks';
import RequestUpdateDropdown from './RequestUpdateDropdown';

export default function ProfileHeader() {
    const { user } = useProfileSelector((state) => state.hydration);

    const translate = useTranslation();

    const { data: userDetails } = useUserDetailsQuery();
    const { data: userTickets, isLoading, error } = useUserTicketsQuery(undefined, { skip: !user || user.InternalUser });
    const errorStatus = (isFetchBaseQueryError(error) && error.status) || undefined;

    if (errorStatus === 500) {
        return <ErrorPage errorCode={500} withTranslations />;
    }

    if (isLoading) {
        return (
            <div className='d-flex w-full py-3 justify-content-center align-items-center alert alert-global alert-info'>
                <Spinner color='inherit' />
            </div>
        );
    }

    return (
        <div className='container-fluid-nopadding'>
            <div className='d-flex flex-column flex-md-row justify-content-md-between alert alert-global alert-info' role='alert'>
                {userDetails?.UserRoles?.some((role) => role.Role?.Name === 'ClientAdmin' || role.Role?.Name === 'CentralAdmin') ? (
                    userTickets && userTickets.length > 0 && !userTickets.every((ticket) => ticket.Status === 'Closed') ? (
                        <>
                            <ul className='p-0 mb-0 mr-2'>
                                {userTickets.map((ticket) => {
                                    if (ticket.Status === 'Closed') return null;

                                    return (
                                        <li className='d-flex mb-2 mb-md-1' key={ticket.CaseNumber}>
                                            <span className='alert-icon'>
                                                <FontAwesomeIcon icon={faInfoCircle} className='mt-1' />
                                            </span>
                                            <span>
                                                {translate('YourCaseNumberSubmitted_Label')} <strong>#{ticket.CaseNumber}</strong>{' '}
                                                {translate('YourCaseSubmittedOn_Label', ticket.CreatedDate, ticket.Status)}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                            {!user?.InternalUser && <RequestUpdateDropdown />}
                        </>
                    ) : (
                        <>
                            <div className='d-flex mb-2 mb-md-0'>
                                <div className='alert-icon'>
                                    <FontAwesomeIcon icon={faInfoCircle} className='mt-1' />
                                </div>
                                <div>{translate('UpdateProfileAlert_Label')}</div>
                            </div>
                            {!user?.InternalUser && <RequestUpdateDropdown />}
                        </>
                    )
                ) : (
                    <div>{translate('ToMakeChangesToYourProfile_Label')}</div>
                )}
            </div>
        </div>
    );
}
