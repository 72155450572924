import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChangeEvent } from 'react';
import { useRef } from 'react';
import { useLazyGetClientSelectionSearchQuery } from '../../../../redux/api/clientSelectionApi';
import { useDebounce } from '../../../hooks/useDebounce';
import useTranslation from '../../../hooks/useTranslation';
import { useClientChooserContext } from '../hooks';

const TIMEOUT = 1000;

export default function SearchBar() {
    const translate = useTranslation();

    const { isUnrestricted, selectedCountryCode, setSearchQuery, searchQuery, searchIsLoading, setSearchIsLoading } =
        useClientChooserContext();

    const searchInputRef = useRef<HTMLInputElement>(null);

    const [getClientSelectionSearch] = useLazyGetClientSelectionSearchQuery();

    const debounce = useDebounce(TIMEOUT);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setSearchQuery(value);

        debounce(async () => {
            if (!isUnrestricted || value.trim().length < 3) return;

            setSearchIsLoading(true);

            try {
                await getClientSelectionSearch({
                    countryCode: selectedCountryCode || 'ca',
                    query: value
                });
            } catch (error) {
                console.error(error);
            } finally {
                setSearchIsLoading(false);
            }
        });
    };

    return (
        <div className='border-top border-bottom bg-gray-200 flex-grow-0 px-4 py-3'>
            <div className='search-bar-wrapper align-self-center flex-md-grow-1'>
                <div className='search-bar'>
                    <div className='input-group form-group align-items-center position-relative m-0'>
                        <label htmlFor='search-clients' className='visually-hidden'>
                            {translate('SearchClients_Label')}
                        </label>
                        <input
                            type='text'
                            id='search-clients'
                            className='main-desktop-input form-control search-input rounded-sm'
                            placeholder={translate('SearchClients_Label')}
                            ref={searchInputRef}
                            value={searchQuery}
                            onChange={handleSearch}
                            readOnly={searchIsLoading}
                        />
                        <div className='magnifying-glass-input-icon'>
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <div className='position-relative'>
                            <span className='main-desktop-form-clear form-clear d-none'>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        </div>
                    </div>
                </div>
                {isUnrestricted && <p className='search-bar-subtext small'>{translate('UnrestrictedMessage_Label')}</p>}
            </div>
        </div>
    );
}
