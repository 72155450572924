import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import type { FormEvent } from 'react';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PortalRoutes } from '../../../common/constants/constants-portal';
import Breadcrumb from '../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../common/features/Breadcrumb/BreadcrumbItem';
import Checkbox from '../../../common/features/Checkbox';
import FormInputField from '../../../common/features/FormInputField';
import Select from '../../../common/features/Select';
import Spinner from '../../../common/features/Spinner';
import { maskInput } from '../../../common/functions/maskInput';
import { inputHasValueValidator } from '../../../common/functions/validators';
import { useDebounce } from '../../../common/hooks/useDebounce';
import useMaskedInputValidation from '../../../common/hooks/useMaskedInputValidation';
import useTranslation from '../../../common/hooks/useTranslation';
import { addToast } from '../../../redux/reducers/toastSlice';
import { RequestUpdateTypes } from '../common/constants/constants-profile';
import type { RequestAnUpdateModel } from '../common/models/RequestAnUpdateModel';
import { useGetCaseNumberMutation, useUserDetailsQuery } from '../redux/api/profileApi';
import { useProfileDispatch } from '../redux/hooks';

const TIMEOUT = 4000;

export default function RequestUpdatePage() {
    const { data: userDetails, isLoading: userDetailsIsLoading } = useUserDetailsQuery();
    const [userDataForm, setUserDataForm] = useState<RequestAnUpdateModel>({
        ClientType: '',
        Description: '',
        Email: '',
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        RequestType: '',
        UserID: 0
    });

    useEffect(() => {
        if (!userDetails) return;

        setUserDataForm({
            RequestType: '',
            Description: '',
            FirstName: userDetails.FirstName || '',
            LastName: userDetails.LastName || '',
            Email: userDetails.Email || '',
            PhoneNumber: userDetails.Phone || '',
            ClientType: '',
            UserID: 0
        });
    }, [userDetails]);

    const [error, setError] = useState(false);
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const history = useHistory<{ requestType?: string; caseNumber?: string }>();
    const dispatch = useProfileDispatch();
    const translate = useTranslation();

    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex
    } = useMaskedInputValidation({
        validators: [inputHasValueValidator, (value) => isValidPhoneNumber(value, 'US')],
        validateOnSubmit: formIsSubmitted,
        inputMask: '(###) ###-#### x######',
        initial: maskInput(userDetails?.Phone || '', '(###) ###-#### x######')
    });

    useEffect(() => {
        handleChangeUserDataForm('RequestType', history.location.state?.requestType || '');
        handleChangeUserDataForm('PhoneNumber', phoneNumber);
    }, [history, phoneNumber]);

    const [createCaseNumber, { isLoading }] = useGetCaseNumberMutation();

    useEffect(() => {
        if (!formIsSubmitted || !userDataForm || userDataForm.PhoneNumber !== phoneNumber || !phoneNumberIsValid || !submitClicked) return;

        createCaseNumber(userDataForm)
            .unwrap()
            .then((response) => {
                const caseNumber = response;

                if (caseNumber) {
                    return history.push({ pathname: '/Profile/RequestSuccess', state: { caseNumber } });
                }
            })
            .catch(() => {
                dispatch(addToast({ message: translate('FormNotFilledCorrectly_Label'), type: 'error' }));
            });
    }, [formIsSubmitted, submitClicked, dispatch, history, phoneNumber, phoneNumberIsValid, userDataForm, createCaseNumber, translate]);

    const debounce = useDebounce(TIMEOUT);

    useEffect(() => {
        debounce(() => {
            setError(false);
        });
    }, [debounce, error]);

    useEffect(() => {
        if (phoneNumberIsValid) {
            handleChangeUserDataForm('PhoneNumber', phoneNumber);
        }
    }, [phoneNumberIsValid, phoneNumber]);

    const handleChangeUserDataForm = (field: string, value: string) => {
        switch (field) {
            case 'RequestType':
                setUserDataForm((prevState) => ({ ...prevState, RequestType: value }));
                break;
            case 'Description':
                setUserDataForm((prevState) => ({ ...prevState, Description: value }));
                break;
            case 'FirstName':
                setUserDataForm((prevState) => ({ ...prevState, FirstName: value }));
                break;
            case 'LastName':
                setUserDataForm((prevState) => ({ ...prevState, LastName: value }));
                break;
            case 'Email':
                setUserDataForm((prevState) => ({ ...prevState, Email: value }));
                break;
            case 'PhoneNumber':
                setUserDataForm((prevState) => ({ ...prevState, PhoneNumber: value }));
                break;
            case 'ClientType':
                setUserDataForm((prevState) => ({ ...prevState, ClientType: value }));
                break;
        }
    };

    const handleClientType = (value: string) => {
        switch (userDataForm.ClientType) {
            case '':
                handleChangeUserDataForm('ClientType', value);
                break;
            case 'US Brokerage and CA Brokerage':
                value === 'US Brokerage'
                    ? handleChangeUserDataForm('ClientType', 'CA Brokerage')
                    : handleChangeUserDataForm('ClientType', 'US Brokerage');
                break;
            case 'US Brokerage':
                value === 'US Brokerage'
                    ? handleChangeUserDataForm('ClientType', '')
                    : handleChangeUserDataForm('ClientType', 'US Brokerage and CA Brokerage');
                break;
            case 'CA Brokerage':
                value === 'CA Brokerage'
                    ? handleChangeUserDataForm('ClientType', '')
                    : handleChangeUserDataForm('ClientType', 'US Brokerage and CA Brokerage');
                break;
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (
            userDataForm.FirstName.length > 0 &&
            userDataForm.LastName.length > 0 &&
            userDataForm.Email.length > 0 &&
            userDataForm.PhoneNumber.length > 0 &&
            userDataForm.ClientType.length > 0
        ) {
            setFormIsSubmitted(true);
            phoneNumberIsValid && setSubmitClicked(true);
        } else {
            setError(true);
            setFormIsSubmitted(false);
            setSubmitClicked(false);
        }
    };

    let content = (
        <div className='container-narrow mb-5'>
            <div className='row'>
                <div className='col d-flex align-items-center my-3 mt-md-5 mb-md-4'>
                    <Link to='/Profile' className='btn btn-tertiary mr-3' aria-label='Back'>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                    <h1 className='mb-0'> {translate('RequestAnUpdate_Label')} </h1>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <form onSubmit={handleSubmit} className='card'>
                        <div className='card-body'>
                            <div className='form-group'>
                                <label htmlFor='request-type'>{translate('RequestType_Label')}</label>
                                <Select
                                    id='request-type'
                                    options={RequestUpdateTypes.map((item) => ({ ...item, label: translate(item.label) }))}
                                    defaultValue={
                                        RequestUpdateTypes.find((item) => translate(item.label) === history.location.state?.requestType)
                                            ?.value
                                    }
                                    onChange={(value) => handleChangeUserDataForm('RequestType', value)}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label htmlFor='description'>{translate('TellUsWhatInformationToUpdate_Label')}</label>
                                <textarea
                                    id='description'
                                    className='form-control'
                                    onChange={(e) => handleChangeUserDataForm('Description', e.target.value)}
                                    maxLength={2000}
                                />
                            </div>
                            <h2 className='h4 mb-3'>{translate('ToWhomShouldWeRespond_Label')}</h2>
                            <div className='container-fluid'>
                                <div className='row row-cols-1 row-cols-md-2'>
                                    <div className='col'>
                                        <div className='form-group mr-md-3 needs-validation was-validated'>
                                            <label htmlFor='first-name'>{translate('FirstName_Label')}</label>
                                            <input
                                                type='text'
                                                id='first-name'
                                                className='form-control'
                                                value={userDataForm.FirstName}
                                                onChange={(e) => handleChangeUserDataForm('FirstName', e.target.value)}
                                                required
                                            />
                                            <div className='invalid-feedback'>{translate('InvalidFirstName_Label')}</div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-group needs-validation was-validated'>
                                            <label htmlFor='last-name'>{translate('LastName_Label')}</label>
                                            <input
                                                type='text'
                                                id='last-name'
                                                className='form-control'
                                                value={userDataForm.LastName}
                                                onChange={(e) => handleChangeUserDataForm('LastName', e.target.value)}
                                                required
                                            />
                                            <div className='invalid-feedback'>{translate('InvalidLastName_Label')}</div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='mr-md-3'>
                                            <FormInputField
                                                id='phone-number'
                                                isBold
                                                label='Phone number'
                                                value={phoneNumber}
                                                onChange={phoneNumberChangeHandler}
                                                onBlur={phoneNumberBlurHandler}
                                                isValid={phoneNumberIsValid}
                                                hasError={phoneNumberHasError}
                                            />
                                            {phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                                                <div className='error-message'>{translate('PhoneNumberIsRequired_Label')}</div>
                                            )}
                                            {phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                                                <div className='error-message'>{translate('InvalidPhoneNumber_Label')}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-group needs-validation was-validated'>
                                            <label htmlFor='email'>{translate('EmailProfile_Label')}</label>
                                            <input
                                                type='email'
                                                id='email'
                                                className='form-control'
                                                value={userDataForm.Email}
                                                onChange={(e) => handleChangeUserDataForm('Email', e.target.value)}
                                                required
                                            />
                                            <div className='invalid-feedback'>{translate('InvalidEmail_Label')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='form-group mr-md-3 mt-2'>
                                            <fieldset>
                                                <legend className='mb-2'>{translate('ClientType_Label')}</legend>
                                                <div className='d-flex flex-column'>
                                                    <Checkbox
                                                        id='us-brokerage'
                                                        divClass='custom-control custom-checkbox mb-2'
                                                        name='check1'
                                                        onChange={() => {
                                                            setCheck1((prevState) => !prevState);
                                                            handleClientType('US Brokerage');
                                                        }}
                                                        isChecked={check1}
                                                    >
                                                        {translate('USBrokerage_Label')}
                                                    </Checkbox>
                                                    <Checkbox
                                                        divClass='custom-control custom-checkbox mb-2'
                                                        id='ca-brokerage'
                                                        name='check2'
                                                        onChange={() => {
                                                            setCheck2((prevState) => !prevState);
                                                            handleClientType('CA Brokerage');
                                                        }}
                                                        isChecked={check2}
                                                    >
                                                        {translate('CABrokerage_Label')}
                                                    </Checkbox>
                                                </div>
                                            </fieldset>
                                            {userDataForm.ClientType.length === 0 && (
                                                <div className='error-message'>{translate('InvalidClientType_Label')}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end'>
                            <button type='submit' className='btn btn-primary btn-lg d-flex align-items-center' disabled={isLoading}>
                                {isLoading && <Spinner size='small' color='inherit' className='mr-2' />}
                                {translate('SubmitRequest_Label')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

    if (userDetailsIsLoading) {
        content = (
            <div className='d-flex justify-content-center align-items-center flex-fill'>
                <Spinner />
            </div>
        );
    }

    return (
        <div className='d-flex flex-column flex-fill'>
            <Breadcrumb>
                <BreadcrumbItem url={PortalRoutes.profile}>{translate('MyProfile_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{translate('RequestAnUpdate_Label')}</BreadcrumbItem>
            </Breadcrumb>
            {content}
        </div>
    );
}
