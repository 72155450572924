import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DateRangeFilter as DateRange } from '../../../common/constants/constants-clearances';
import DateComponent from '../../../common/features/dateTimePicker/DateComponent';
import { useClearancesDispatch, useClearancesSelector } from '../../../redux/hooks';
import { setCustomDateRange, setDateRangeSelected } from '../../../redux/reducers/dateRangeSlice';
import { setPageSelected } from '../../../redux/reducers/shipmentGridSettingsSlice';

interface DateRangeFilterProps {
    btnClass?: string;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ btnClass = '' }) => {
    const { user } = useClearancesSelector((state) => state.hydration);
    const endDateFilter = new Date().getFullYear();
    const startDateFilter = user?.PortalClearancesCreatedOnMinDate ?? `01/01/2022`;
    const [showDateRange, setShowDateRange] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const { dateRangeSelected, customDateRange } = useClearancesSelector((state) => state.dateRange);
    const dispatch = useClearancesDispatch();
    const translate = useTranslation();
    useEffect(() => {
        if (customDateRange?.length === 1) {
            if (customDateRange[0].type === 'startdate') {
                setStartDate(customDateRange[0].date);
                setEndDate('');
            } else {
                setEndDate(customDateRange[0].date);
                setStartDate('');
            }
        } else if (customDateRange?.length === 2) {
            setStartDate(customDateRange[0].date);
            setEndDate(customDateRange[1].date);
        } else {
            setStartDate('');
            setEndDate('');
        }
    }, [customDateRange]);

    const handleSelection = (dateRangeIndex: number) => {
        dispatch(setDateRangeSelected(dateRangeIndex));
        dispatch(setPageSelected(1));
    };
    const handleCustomDateSelection = () => {
        var arr = [];
        if (startDate.length > 0) {
            arr.push({
                type: 'startdate',
                date: startDate
            });
        }
        if (endDate.length > 0) {
            arr.push({
                type: 'enddate',
                date: endDate
            });
        }
        if (arr.length) {
            const startDateFormated = startDate.replaceAll('-', '/');
            const endDateFormated = endDate.replaceAll('-', '/');

            const startDateCheck = startDate.length > 0 ? new Date(startDateFormated) : new Date(startDateFilter);

            const endDateCheck = endDate.length > 0 ? new Date(endDateFormated) : new Date(endDateFilter, 11, 31);

            const dateCheckMin = new Date(startDateFilter);
            const dateCheckMax = new Date(endDateFilter, 11, 31);

            if (startDateCheck >= dateCheckMin && endDateCheck <= dateCheckMax) {
                dispatch(setCustomDateRange(arr));
                handleSelection(6);
            }
        }
    };

    const renderDateRangeOptions = () => {
        return (
            <div>
                {/* Dropdown heading */}
                <div className='dropdown-heading dropdown-header d-flex justify-content-between pb-3'>
                    <div className='name'>
                        <p className='mb-0'>{translate('LastModified_Label')}:</p>
                    </div>
                </div>

                {/* Dropdown main-content */}
                {DateRange.map((dateRange, dateRangeIndex) => {
                    const isActive = dateRange.id === dateRangeSelected.id;
                    if (dateRangeIndex < 6) {
                        return (
                            <div
                                className={clsx('dropdown-item d-flex justify-content-between align-items-stretch', isActive && 'active')}
                                key={dateRange.id}
                                onClick={() => handleSelection(dateRangeIndex)}
                                style={isActive ? { backgroundColor: '#fff' } : undefined}
                            >
                                <div className='name d-flex align-items-center mr-3'>{translate(dateRange.label)}</div>
                            </div>
                        );
                    } else if (dateRangeIndex === 6) {
                        return (
                            <div key={dateRange.id}>
                                <div className='dropdown-divider'></div>
                                <a
                                    href='#/'
                                    className={clsx(
                                        'dropdown-item custom-date-range-toggle d-flex justify-content-between align-items-stretch',
                                        isActive && 'active'
                                    )}
                                    style={isActive ? { backgroundColor: '#fff' } : undefined}
                                    onClick={(e) => {
                                        setShowDateRange(true);
                                        $('#FilterButton').removeAttr('data-toggle');
                                    }}
                                >
                                    {translate('CustomDateRange_Label')}
                                </a>
                            </div>
                        );
                    } else {
                        return undefined;
                    }
                })}
            </div>
        );
    };

    const renderCustomDateRange = () => {
        return (
            <div className='card shadow-none border-none'>
                <div className='card-header px-4 py-3'>
                    <h5 className='m-0'>{translate('SelectCustomDateRange_Label')}</h5>
                </div>
                <div className='card-body px-4 py-3'>
                    <DateComponent
                        placeholder={translate('SelectStartDate_Label')}
                        dateLabel={translate('StartDate_Label')}
                        valMsg='Invalid date message'
                        noBorder
                        noHeader
                        value={startDate}
                        onDateChanged={setStartDate}
                        medium
                        dateCheckObj={{
                            comparationValueMin: new Date(startDateFilter),
                            comparationValueMax: new Date(endDateFilter, 11, 31)
                        }}
                    />
                    <DateComponent
                        placeholder={translate('SelectEndDate_Label')}
                        dateLabel={translate('EndDate_Label')}
                        valMsg='Invalid date message'
                        noBorder
                        noHeader
                        value={endDate}
                        onDateChanged={setEndDate}
                        medium
                        dateCheckObj={{
                            comparationValueMin: new Date(startDateFilter),
                            comparationValueMax: new Date(endDateFilter, 11, 31)
                        }}
                    />
                </div>
                <div className='card-footer d-flex px-4 py-3'>
                    <button
                        type='button'
                        className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                        onClick={(e) => {
                            setShowDateRange(false);
                            $('#FilterButton').attr('data-toggle', 'dropdown');
                            e.stopPropagation();
                        }}
                    >
                        {translate('Cancel_Label')}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                        onClick={() => {
                            $('#DateRangePicker').removeClass('show');
                            setShowDateRange(false);
                            $('#FilterButton').attr('data-toggle', 'dropdown');
                            handleCustomDateSelection();
                        }}
                    >
                        {translate('Apply_Label')}
                    </button>
                </div>
            </div>
        );
    };
    const parseDateRangePrint = (dateType: string) => {
        if (customDateRange !== undefined) {
            if (dateType === 'startDate' && customDateRange[0]?.type === 'startdate') {
                return customDateRange[0]?.date.split('-').join('/');
            } else if (dateType === 'endDate') {
                if (customDateRange[0]?.type === 'enddate') {
                    return customDateRange[0].date.split('-').join('/');
                } else if (customDateRange[1]?.type === 'enddate') {
                    return customDateRange[1].date.split('-').join('/');
                }
            }
        }
    };
    return (
        <div className='dropdown'>
            {/* Dropdown header */}
            <button
                className={clsx('btn btn-tertiary', btnClass, 'dropdown-toggle btn-sm')}
                type='button'
                id='FilterButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {dateRangeSelected.id === 'CUSTOM'
                    ? translate(dateRangeSelected.label, parseDateRangePrint('startDate'), parseDateRangePrint('endDate'))
                    : translate(dateRangeSelected.label)}
            </button>

            {/* Dropdown menu */}
            <div id='DateRangePicker' className='dropdown-menu' aria-labelledby='Filter'>
                {!showDateRange && renderDateRangeOptions()}
                {showDateRange && renderCustomDateRange()}
            </div>
        </div>
    );
};
export default DateRangeFilter;
