import { clsx } from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import classes from './Spinner.module.css';

const SIZES = {
    standard: '',
    small: 'spinner-border-sm'
} as const;

const COLORS = {
    blue: 'text-primary',
    white: 'text-light',
    inherit: ''
} as const;

interface SpinnerProps extends Pick<ComponentPropsWithoutRef<'span'>, 'style' | 'className'> {
    size?: keyof typeof SIZES;
    color?: keyof typeof COLORS;
}

export default function Spinner({ size = 'standard', color = 'blue', style, className = '' }: SpinnerProps) {
    return (
        <span
            className={clsx('spinner-border', SIZES[size], COLORS[color], className, color === 'inherit' ? classes.inherit : classes.unset)}
            role='status'
            style={style}
        >
            <span className='sr-only'>Loading...</span>
        </span>
    );
}
