import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../common/Modal/Modal';
import { dashboard } from '../../constants/mlp-constants';
import Widget from './Widget';

class DashboardArea extends Component {
    state = {
        settingsFlag: false,
        windowWidth: 0,
        height: 0
    };
    widgetAreaRef = React.createRef();

    handleSettingsOpenRequest = () => {
        this.setState({ settingsFlag: true });
    };

    handleSettingsCloseRequest = () => {
        this.setState({ settingsFlag: false });
    };

    handleResize = (e) => {
        let width = ReactDOM.findDOMNode(this.widgetAreaRef.current).offsetWidth;
        this.setState({ windowWidth: width });
    };

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
        let width = ReactDOM.findDOMNode(this.widgetAreaRef.current).offsetWidth;
        this.setState({ windowWidth: width });
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
    };

    createWidgetParams = (clientNumbers) => {
        let parameters = dashboard.WIDGET_CLIENT_NUMBER_PARAM;
        if (clientNumbers.length === 0) {
            parameters = '';
        } else {
            const clientNumberParams = clientNumbers.map((clientNumber, i) => {
                return clientNumber.FullNumber;
            });
            parameters += clientNumberParams.join(',');
        }
        return parameters;
    };

    getNumWidgetColumns = (widgetViewModels) => {
        let numWidgetColumns = 1;
        for (let i = 0; i < widgetViewModels.length; i++) {
            const columns = widgetViewModels[i].Column + widgetViewModels[i].Widget.Width;
            if (columns > numWidgetColumns) numWidgetColumns = columns;
        }
        return numWidgetColumns;
    };

    render() {
        // Calculate widget column width and row height
        const numWidgetColumns = this.getNumWidgetColumns(this.props.widgets);
        let colWidth = Number(this.state.windowWidth) / Number(numWidgetColumns);
        const spacingReduction = Number(dashboard.WIDGET_SPACING * numWidgetColumns) / Number(numWidgetColumns);
        colWidth -= spacingReduction;
        const rowHeight = colWidth * dashboard.WIDGET_HEIGHT_WIDTH_RATIO;

        // Calculate number of rows and create widget componenents
        const urlParams = this.createWidgetParams(this.props.clientNumbers);
        let widgetRows = 0;

        const widgets = this.props.widgets.map((widgetViewModel) => {
            const bottomRow = widgetViewModel.Widget.Height + widgetViewModel.Row;
            if (bottomRow > widgetRows) {
                widgetRows = bottomRow;
            }

            const dashboardWidget = (
                <Widget
                    clientNumbers={this.props.clientNumbers}
                    widgetParams={urlParams}
                    data={widgetViewModel}
                    reportHost={this.props.reportHost}
                    reportPrefix={this.props.reportPrefix}
                    colWidth={colWidth}
                    rowHeight={rowHeight}
                    user={this.props.user}
                    key={widgetViewModel.Widget.Id}
                    handleSettingsOpenRequest={this.handleSettingsOpenRequest}
                />
            );

            return dashboardWidget;
        });

        // Calculate full area height based on number of rows and row height, compensate for row spacing
        let widgetAreaHeight = (rowHeight + Number(dashboard.WIDGET_SPACING)) * widgetRows - dashboard.WIDGET_SPACING;
        if (widgetAreaHeight < 0) widgetAreaHeight = 0;
        if (this.widgetAreaRef.current) this.widgetAreaRef.current.style.height = widgetAreaHeight;

        return (
            <div className='dashboardArea col-md-12 no-gutter'>
                <Modal isOpen={this.state.settingsFlag} onRequestClose={this.handleSettingsCloseRequest} title='Settings'>
                    <p>settings content</p>
                </Modal>
                <div ref={this.widgetAreaRef} className='widget-container'>
                    <div className='widget-child-container'>
                        <ul id='sortable' className='ui-state list-unstyled'>
                            {widgets}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardArea;
