import { clsx } from 'clsx';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DateRangeDocumentsFilter } from '../../../../documents/common/constants/constants-documents';
import DateComponent from '../../../../documents/screens/Filters/DateFilter/DateComponent';
import { useCompleteEntryDataSelector } from '../../../redux/hooks';

const formatDateLabel = (date: string) => date.replaceAll('-', '/');

interface DangerFilterProps {
    setDate: (param: string) => void;
    date: string;
    dateFilterParam: string;
    setDateFilterParam: (param: string) => void;
    type: string;
    btnClass?: string;
    isOpen: boolean;
    onClick: () => void;
    handleToggle: () => void;
    startDateCa: string;
    endDateCa: string;
    setEndDateCa: (param: string) => void;
    setStartDateCa: (param: string) => void;
    pageWhereComponentIsCalled?: string;
    PropDateValue: (dateValue: string) => void;
    propDateLabel: (dateValue: string) => void;
    setappliedDate: (dateValue: string) => void;
    setappliedType: (dateValue: string) => void;
    secondDropdownFunction?: (
        handleSelection: any,
        dateRangeIndex: any,
        dateRange: any,
        searchTypeCode: any,
        attribute: any,
        handleToggle: any
    ) => void;
    applyButtonFunction: (attribute: string, selectedOption: string, selectedValue: string, endDate: string, startDate: string) => void;
    dateDropdownLabel: string;
    appliedDate: string;
    appliedType: string;
    title: string;
}

const DateRangeFilter = forwardRef<HTMLDivElement, DangerFilterProps>(function DateRangeFilter(
    {
        btnClass = '',
        onClick,
        isOpen,
        setEndDateCa,
        setStartDateCa,
        handleToggle,
        pageWhereComponentIsCalled,
        date,
        secondDropdownFunction,
        setDate,
        PropDateValue,
        type,
        applyButtonFunction,
        startDateCa,
        endDateCa,
        dateFilterParam,
        setDateFilterParam,
        dateDropdownLabel,
        propDateLabel,
        appliedDate,
        appliedType,
        title
    },
    ref
) {
    const { customDateRangeCaEndLabel, customDateRangeCaStartLabel } = useCompleteEntryDataSelector((state) => state.imaging);
    const { type: dateType } = useCompleteEntryDataSelector((state) => state.dateFilter);

    const translate = useTranslation();

    const [showDateRange, setShowDateRange] = useState(false);

    const filteredDateRangeOptions = useMemo(() => {
        if (dateType === 'ReleaseDate_Label') {
            return DateRangeDocumentsFilter.slice(1);
        }
        return DateRangeDocumentsFilter;
    }, [dateType]);

    useEffect(() => {
        const selectedValue = date ? (filteredDateRangeOptions.map((x) => x.id).includes(date) ? date : 'CUSTOM') : '';

        applyButtonFunction('Date', type.replaceAll('_Label', '').replaceAll(' ', ''), selectedValue, endDateCa, startDateCa);
    }, [type, date, endDateCa, startDateCa]);

    const findLabel = (date: string) => filteredDateRangeOptions.find((x) => x.id === date)?.label ?? '';

    let dropdownLabel = '';

    if (date) {
        dropdownLabel = date.includes('-') ? `${formatDateLabel(startDateCa)} - ${formatDateLabel(endDateCa)}` : translate(findLabel(date));
    } else if (appliedDate) {
        dropdownLabel = appliedDate.includes('-')
            ? `${formatDateLabel(startDateCa)} - ${formatDateLabel(endDateCa)}`
            : translate(findLabel(appliedDate));
    } else {
        dropdownLabel = translate('SelectDateByRange_Label');
    }

    return (
        <div className={clsx('dropdown', isOpen && 'show')} ref={!showDateRange ? ref : null}>
            <button
                className={clsx('btn btn-tertiary', btnClass, 'dropdown-toggle btn-sm')}
                type='button'
                onClick={onClick}
                disabled={!type && !appliedType}
            >
                {dropdownLabel}
            </button>
            <div id='DateRangePicker' className={clsx('dropdown-menu', isOpen && 'show')} aria-labelledby='Filter'>
                {isOpen && !showDateRange && (
                    <div>
                        {filteredDateRangeOptions.map(({ id, label }) => {
                            if (id === 'CUSTOM') {
                                return (
                                    <div
                                        className='dropdown-item d-flex justify-content-between align-items-stretch'
                                        key={id}
                                        onClick={() => {
                                            setShowDateRange(true);
                                        }}
                                    >
                                        <div className='name d-flex align-items-center mr-3'>{translate(label)}</div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        className='dropdown-item d-flex justify-content-between align-items-stretch'
                                        key={id}
                                        onClick={() => {
                                            setDateFilterParam(id);
                                            setDate(id);
                                            PropDateValue(id);
                                            propDateLabel(label);
                                            handleToggle();
                                        }}
                                    >
                                        <div className='name d-flex align-items-center mr-3'>{translate(label)}</div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}
                {showDateRange && (
                    <div className='card shadow-none border-none'>
                        <div className='card-header px-4 py-3'>
                            <h5 className='m-0'>{translate('SelectCustomDateRange_Label')}</h5>
                        </div>
                        <div className='card-body px-4 py-3'>
                            <DateComponent
                                placeholder={translate('SelectStartDate_Label')}
                                dateLabel={translate('StartDate_Label')}
                                valMsg='Invalid date message'
                                noBorder
                                noHeader
                                value={startDateCa || customDateRangeCaStartLabel}
                                onDateChanged={setStartDateCa}
                                medium
                                dateCheckObj={{
                                    comparationValueMin: new Date(2011, 1, 1),
                                    comparationValueMax: new Date(2042, 1, 1)
                                }}
                            />
                            <DateComponent
                                placeholder={translate('SelectEndDate_Label')}
                                dateLabel={translate('EndDate_Label')}
                                valMsg='Invalid date message'
                                noBorder
                                noHeader
                                value={endDateCa || customDateRangeCaEndLabel}
                                onDateChanged={setEndDateCa}
                                medium
                                dateCheckObj={{
                                    comparationValueMin: new Date(2011, 1, 1),
                                    comparationValueMax: new Date(2042, 1, 1)
                                }}
                            />
                        </div>
                        <div className='card-footer d-flex px-4 py-3'>
                            <button
                                type='button'
                                className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                                onClick={(e) => {
                                    setShowDateRange(false);
                                    setStartDateCa('');
                                    setEndDateCa('');
                                }}
                            >
                                {translate('Cancel_Label')}
                            </button>
                            <button
                                disabled={!endDateCa && !startDateCa}
                                type='button'
                                className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                                onClick={() => {
                                    setShowDateRange(false);

                                    if (startDateCa !== '' && endDateCa !== '') setDate(`${startDateCa}-${endDateCa}`);

                                    PropDateValue(`${startDateCa}-${endDateCa}`);
                                    handleToggle();
                                }}
                            >
                                {translate('Apply_Label')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default DateRangeFilter;
